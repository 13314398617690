import {Suspense, useEffect, useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SubTabLoading from "./Loading/SubTabLoading";
import ListLoading from "./Loading/ListLoading";
import {useSelector} from "react-redux";
import {getActiveTabKey} from "../../redux/tab";
// import 'swiper/swiper-bundle.css';
// import 'swiper/css';

function MobileRender({allTabs, listShowType, setSubObj, subObj, allComponent, defaultComponent, onChangeTab}) {
    const swiperRef = useRef()
    const activeTabKey = useSelector(getActiveTabKey)
    const activeTabIndex = allTabs.findIndex((ele) => ele.key === activeTabKey)
    const scrollPosition = useRef(new Map())

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(allTabs.findIndex((ele) => ele.key === activeTabKey), 100)

            const position = scrollPosition.current.get(activeTabKey)
            if (position) {
                setTimeout(() => {
                    window.scrollTo({top: position, behavior: "instant"});
                }, 1)
            } else {
                if (swiperRef.current.getBoundingClientRect().top < 55) {
                    setTimeout(() => {
                        // swiperRef.current.scrollTo(100, 0)
                        window.scrollTo({top: 0, behavior: "smooth"});
                    }, 1)
                }
            }
        }
    }, [activeTabKey])

    // 更新滑动位置的函数
    const handleScroll = () => scrollPosition.current.set(activeTabKey, window.scrollY);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeTabKey]);

    return (
        <Swiper ref={swiperRef}
                noSwipingClass="swiper-no-swiping"
                lazy={{enabled: true, loadPrevNext: true, loadPrevNextAmount: 2}}
                onSlideChange={(swiper) => {
                    if (swiper.swipeDirection) { // 说明是滑动的。不是被动进入的
                        onChangeTab(allTabs[swiper.activeIndex].key)
                    }
                }}
            // touchRatio={0.8}
            // threshold={10}
                touchAngle={30}
        >
            {allTabs.map((item, index) => {
                const Component = allComponent[item.key] ? allComponent[item.key] : defaultComponent
                const realSubObj = subObj?.tabKey === item.key ? subObj?.obj : {}
                const loadEnable = Math.abs(index - activeTabIndex) <= 1

                function realSetSubObj(obj) {
                    if (activeTabKey === item.key) {
                        setSubObj({tabKey: item.key, obj})
                    }
                }

                if (!loadEnable) {
                    scrollPosition.current.delete(item.key)
                    return (
                        <SwiperSlide key={index}>
                            <div className="w-full h-screen"></div>
                        </SwiperSlide>
                    )
                }

                return (
                    <SwiperSlide key={index}>
                        <div className={`min-h-screen ${item.key !== activeTabKey ? "max-h-screen" : ""}`}>
                            <Suspense fallback={<><SubTabLoading/><ListLoading/></>}>
                                <Component allTabs={allTabs} listShowType={listShowType} setSubObj={realSetSubObj}
                                           subObj={realSubObj}/>
                            </Suspense>
                        </div>
                    </SwiperSlide>
                )

            })}
        </Swiper>
    )

}

export default MobileRender;
