import React, {useEffect, useState} from "react";
import {Avatar, Badge, Button, Divider, Drawer, Dropdown, Menu, Modal, Switch} from "@arco-design/web-react";
import {
    IconExport,
    IconMenu,
    IconNotification,
    IconRight,
    IconSettings, IconSubscribe, IconThunderbolt,
} from "@arco-design/web-react/icon";
import {checkIsDark} from "../../utils/setting";
import {useDispatch, useSelector} from "react-redux";
import {THEME_ENUM} from "../../constants/system";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {getMobileHeader, getNotifyInfo, getTheme, setReadNotifyId, setTheme} from "../../redux/system";
import {MENU_FOLLOWING_NAME, MENU_ICON, MENU_KEY} from "../../constants/menu";
import {getEmailAvatarName} from "../../utils";
import {getUserHasOnceVip, getUserIsVip, getUserVipEndAtString} from "../../redux/user";
import {ALL_POSTS_IDS} from "../../pages/Article/posts";
import parse from "html-react-parser";

function MobileRender({onChangeMenuKey, menuKey, allMenuTabs, isLoggedIn, userAccount, onLogout}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useSelector(getTheme)
    const [dark, setDark] = useState(checkIsDark(theme)) // 是否深色模式
    const [drawerVisible, setDrawerVisible] = useState(false); // 抽屉可见性
    const headerTitle = useSelector(getMobileHeader)
    const hasOnceVip = useSelector(getUserHasOnceVip)
    const isVip = useSelector(getUserIsVip)
    const vipEndAtString = useSelector(getUserVipEndAtString)
    const notifyInfo = useSelector(getNotifyInfo)

    function showModal() {
        const iconColors = {"info": "#165DFF", "success": "#00B42A", "warning": "#FF7D00", "error": "#F53F3F"}
        Modal.info({
            title: notifyInfo.title,
            content: parse(notifyInfo.content),
            icon: <IconNotification style={{color: iconColors[notifyInfo.level]}}/>,
            onOk: () => {
                dispatch(setReadNotifyId(notifyInfo.id))
            },
            className: "w-3/4",
            okText: "知道了",
            closable: true
        });
    }

    useEffect(() => {
        setDark(checkIsDark(theme))
    }, [theme])

    return <>
        <header className="sticky top-0 z-40 w-full bg-primary-200 dark:bg-primaryDark-200">
            <div className="h-full flex items-center mx-3 my-2.5">
                <div className="flex-1 flex">
                    <Button style={{backgroundColor: "transparent"}}
                            icon={<IconMenu className="text-xl text-text-100 dark:text-textDark-100"/>}
                            onClick={() => setDrawerVisible(true)}
                    />
                </div>
                <div
                    className="flex items-center font-bold justify-center w-1/2 text-lg select-none text-text-100 dark:text-textDark-100">
                    <span className="truncate">{headerTitle}</span>
                </div>
                {/*<strong className="w-6 rounded-2xl text-center h-8 absolute top-0 left-0 right-0 bottom-0 m-auto  text-center select-none font-serif text-text-100 dark:text-textDark-100">Rebang.Today</strong>*/}
                {/*按钮开始*/}
                <div className="flex-1 flex justify-end space-x-3">
                    {Object.keys(notifyInfo).length > 0 && <Badge count={1} dot offset={[-2, 2]}>
                        <Button className="text-base shrink-0"
                                icon={<IconNotification />}
                                shape="circle"
                                type="secondary"
                                onClick={showModal}
                        />
                    </Badge>}
                    {!isLoggedIn && <Button
                        shape='round'
                        type='primary'
                        onClick={() => navigate("/signin")}
                    >登录</Button>}
                    {isLoggedIn && <Dropdown droplist={
                        <Menu onClickMenuItem={(key) => {
                            if (key === '1') {
                                return false;
                            }
                        }}>
                            <Menu.Item key='1' className="flex space-x-2 px-4 py-1"
                                       style={{cursor: "default", height: "auto", backgroundColor: "transparent"}}>
                                <Avatar shape="square"
                                        triggerIcon={isVip ? <div><IconThunderbolt style={{fill: "white", stroke: "initial"}} /></div> : null}
                                        triggerIconStyle={{borderWidth: "2px", borderColor:"white", backgroundColor: "#FFC62E"}}
                                        size={39}>{getEmailAvatarName(userAccount.email)}</Avatar>
                                <div className="flex flex-col leading-5 justify-center text-text-100 dark:text-textDark-100">
                                    <span>{userAccount.email}</span>
                                    {hasOnceVip && (
                                        isVip ? <span className="text-xs text-text-200 dark:text-textDark-200">会员 {vipEndAtString} 到期</span> :
                                            <span className="text-xs text-text-200 dark:text-textDark-200">会员已于 {vipEndAtString} 过期</span>
                                    )}
                                </div>
                            </Menu.Item>
                            <Divider style={{margin: '4px 0'}}/>
                            {/*<Menu.Item key='2'>*/}
                            {/*    <IconUser style={{marginRight: 8, fontSize: 16, transform: 'translateY(1px)'}}/>*/}
                            {/*    账号管理*/}
                            {/*</Menu.Item>*/}
                            {!isVip && <Menu.Item key='3' onClick={() => {navigate(`/article/${ALL_POSTS_IDS.VIP}`)}}>
                                <IconThunderbolt style={{marginRight: 8, fontSize: 16, transform: 'translateY(1px)',}}/>
                                开通会员
                            </Menu.Item>}
                            {!isVip && <Divider style={{margin: '4px 0'}}/>}
                            <Menu.Item key='4' onClick={onLogout}>
                                <IconExport style={{marginRight: 8, fontSize: 16, transform: 'translateY(1px)'}}/>
                                退出登录
                            </Menu.Item>
                        </Menu>
                    } trigger='click' position='br'>
                        <Avatar shape="square"
                                size={30}
                                triggerIcon={isVip ? <div><IconThunderbolt style={{fill: "white", stroke: "initial"}} /></div> : null}
                                triggerIconStyle={{borderWidth: "2px", borderColor:"white", backgroundColor: "#FFC62E"}}
                        >{getEmailAvatarName(userAccount.email)}</Avatar>
                    </Dropdown>}
                </div>
                {/*按钮结束*/}
            </div>
            {/*抽屉开始*/}
            <Drawer
                className="bg-primary-100 dark:bg-primaryDark-100"
                width={280}
                visible={drawerVisible}
                closable={false}
                placement="left"
                footer={null}
                title={null}
                onOk={() => setDrawerVisible(false)}
                onCancel={() => setDrawerVisible(false)}
            >
                <div className="flex w-full flex-col space-y-4">
                    <div className="flex w-full flex-col">
                        <span className="mb-2 px-2 text-text-200 dark:text-textDark-200">我的</span>
                        <div
                            className="cursor-pointer select-none text-base text-text-100 dark:text-textDark-100 flex flex-col w-full">
                            <div
                                className="overflow-hidden pr-4 flex rounded-lg bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div className={`my-2 mr-3 w-1 rounded-r-lg ${menuKey === MENU_KEY.FOLLOWING ? "bg-myTheme-600 dark:bg-myThemeDark-600" : "bg-transparent"}`}></div>
                                <div
                                    className="flex-1 flex justify-between items-center h-12 border-border-100 dark:border-borderDark-100"
                                    onClick={() => {
                                        onChangeMenuKey(MENU_KEY.FOLLOWING)
                                        setDrawerVisible(false)
                                    }}>
                                    <div className="space-x-2">
                                        <span className="text-text-200 dark:text-textDark-200"><IconSubscribe/></span>
                                        <span>{MENU_FOLLOWING_NAME}</span>
                                    </div>
                                    <span className="text-text-300 dark:text-textDark-300">
                                        <IconRight/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {allMenuTabs.length > 0 && <div className="flex w-full flex-col">
                        <span className="mb-2 px-2 text-text-200 dark:text-textDark-200">分类</span>
                        <div
                            className="cursor-pointer select-none text-base text-text-100 dark:text-textDark-100 flex flex-col w-full">
                            {allMenuTabs.map((item, index) => {
                                return <div key={item.menu_key} className={classNames({
                                        "rounded-t-lg": index === 0,
                                        "rounded-b-lg": index === allMenuTabs.length - 1,
                                    },
                                    "overflow-hidden pr-4 flex bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400")}>
                                    <div className={`my-2 mr-3 w-1 rounded-r-md ${menuKey === item.menu_key ? "bg-myTheme-600 dark:bg-myThemeDark-600" : "bg-transparent"}`}></div>
                                    <div className={classNames({"border-b": index !== allMenuTabs.length - 1},
                                        "flex-1 flex justify-between items-center h-12 border-border-100 dark:border-borderDark-100"
                                    )} onClick={() => {
                                        onChangeMenuKey(item.menu_key)
                                        setDrawerVisible(false)
                                    }}>
                                        <div className="space-x-2 flex justify-center items-center">
                                            <div
                                                className="text-text-200 dark:text-textDark-200 flex items-center">{MENU_ICON[item.menu_key]}</div>
                                            <span>{item.menu_name}</span>
                                        </div>
                                        <span className="text-text-300 dark:text-textDark-300">
                                            <IconRight/>
                                        </span>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    }
                    <div className="flex w-full flex-col">
                        <span className="mb-2 px-2 text-text-200 dark:text-textDark-200">其他</span>
                        <div
                            className="cursor-pointer select-none text-base text-text-100 dark:text-textDark-100 flex flex-col w-full">
                            <div className="px-4 rounded-t-lg bg-primary-200 dark:bg-primaryDark-200">
                                <div
                                    className="border-b flex justify-between items-center h-12 border-border-100 dark:border-borderDark-100">
                                <span className="flex items-center space-x-2">
                                <div className="flex items-center text-text-200 dark:text-textDark-200">
                                    <svg width="16" height="16" stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8V16Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 4V8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16V20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z" fill="currentColor"></path></svg>
                                </div>
                                <span>深色模式</span>
                                </span>
                                    <Switch checked={!!dark}
                                            onClick={() => {
                                                dispatch(setTheme(dark ? THEME_ENUM.LIGHT : THEME_ENUM.DARK))
                                            }}
                                            className="no-reset-button"/>
                                </div>
                            </div>
                            <div
                                className="px-4 rounded-b-lg bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div
                                    className="flex justify-between items-center h-12 border-border-100 dark:border-borderDark-100"
                                    onClick={() => {
                                        navigate("/setting")
                                        setDrawerVisible(false)
                                    }}>
                                <span className="space-x-2">
                                <IconSettings className="text-text-200 dark:text-textDark-200"/>
                                <span>设置</span>
                                </span>
                                    <span className="text-text-300 dark:text-textDark-300"><IconRight/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full flex-col">
                        {/*<span className="mb-2 px-2 text-text-200 dark:text-textDark-200">其他</span>*/}
                        <div
                            className="cursor-pointer select-none text-base text-text-100 dark:text-textDark-100 flex flex-col w-full">
                            <div
                                className="rounded-t-lg px-4 bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div
                                    className="flex justify-between items-center h-12 border-b border-border-100 dark:border-borderDark-100"
                                    onClick={() => {
                                        navigate(`/article/${ALL_POSTS_IDS.DOWNLOAD_MOBILE}`)
                                        setDrawerVisible(false)
                                    }}>
                                <span className="flex items-center space-x-2">
                                    <div className="flex items-center text-text-200 dark:text-textDark-200">
                                        <svg className="fill-current" width="17" height="17" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="M228,152v56a20,20,0,0,1-20,20H48a20,20,0,0,1-20-20V152a12,12,0,0,1,24,0v52H204V152a12,12,0,0,1,24,0Zm-108.49,8.49a12,12,0,0,0,17,0l40-40a12,12,0,0,0-17-17L140,123V40a12,12,0,0,0-24,0v83L96.49,103.51a12,12,0,0,0-17,17Z"></path></svg>
                                    </div>
                                    <span>安装到桌面</span>
                                </span>
                                    <span className="text-text-300 dark:text-textDark-300"><IconRight/></span>
                                </div>
                            </div>

                            <div
                                className="px-4 bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div
                                    className="flex justify-between items-center h-12 border-b border-border-100 dark:border-borderDark-100"
                                    onClick={() => {
                                        window.open('https://support.qq.com/products/434778', '_blank');
                                        setDrawerVisible(false)
                                    }}>
                                <span className="flex items-center space-x-2">
                                <div className="flex items-center text-text-200 dark:text-textDark-200">
                                    <svg className="fill-current" width="17" height="17" viewBox="0 0 1024 1024"
                                         version="1.1"
                                         xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M341.333333 512h85.333334a42.666667 42.666667 0 0 0 0-85.333333H341.333333a42.666667 42.666667 0 0 0 0 85.333333z m384-256h-213.333333V170.666667h42.666667a42.666667 42.666667 0 0 0 0-85.333334h-85.333334a42.666667 42.666667 0 0 0-42.666666 42.666667v128H298.666667a170.666667 170.666667 0 0 0-170.666667 170.666667v256a42.666667 42.666667 0 0 0 42.666667 42.666666h256v170.666667a42.666667 42.666667 0 0 0 85.333333 0v-170.666667h341.333333a42.666667 42.666667 0 0 0 42.666667-42.666666v-256a170.666667 170.666667 0 0 0-170.666667-170.666667z m-170.666666 170.666667v213.333333H213.333333v-213.333333a85.333333 85.333333 0 0 1 85.333334-85.333334h279.893333a166.826667 166.826667 0 0 0-23.893333 85.333334z m256 213.333333h-170.666667v-213.333333a85.333333 85.333333 0 0 1 170.666667 0z"/>
                                        </svg>
                                </div>
                                <span>反馈建议</span>
                                </span>
                                    <span className="text-text-300 dark:text-textDark-300"><IconRight/></span>
                                </div>
                            </div>

                            <div
                                className="px-4 bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div
                                    className="flex justify-between items-center h-12 border-b border-border-100 dark:border-borderDark-100"
                                    onClick={() => {
                                        navigate(`/article/${ALL_POSTS_IDS.WECHAT_MP}`)
                                        setDrawerVisible(false)
                                    }}>
                                <span className="flex items-center space-x-2">
                                <div className="flex items-center text-text-200 dark:text-textDark-200">
                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M16.5 10c3.038 0 5.5 2.015 5.5 4.5c0 1.397 -.778 2.645 -2 3.47l0 2.03l-1.964 -1.178a6.649 6.649 0 0 1 -1.536 .178c-3.038 0 -5.5 -2.015 -5.5 -4.5s2.462 -4.5 5.5 -4.5z"></path><path d="M11.197 15.698c-.69 .196 -1.43 .302 -2.197 .302a8.008 8.008 0 0 1 -2.612 -.432l-2.388 1.432v-2.801c-1.237 -1.082 -2 -2.564 -2 -4.199c0 -3.314 3.134 -6 7 -6c3.782 0 6.863 2.57 7 5.785l0 .233"></path><path d="M10 8h.01"></path><path d="M7 8h.01"></path><path d="M15 14h.01"></path><path d="M18 14h.01"></path></svg>
                                </div>
                                <span>关注公众号</span>
                                </span>
                                    <span className="text-text-300 dark:text-textDark-300"><IconRight/></span>
                                </div>
                            </div>
                            <div
                                className="px-4 bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div className="flex justify-between items-center h-12 border-b border-border-100 dark:border-borderDark-100"
                                     onClick={() => {
                                         navigate(`/article/${ALL_POSTS_IDS.DONATE}`)
                                         setDrawerVisible(false)
                                     }}>
                                    <span className="flex items-center space-x-2">
                                        <div className="flex items-center text-text-200 dark:text-textDark-200">
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M7 22h10a1 1 0 0 0 .99-.858L19.867 8H21V6h-1.382l-1.724-3.447A.998.998 0 0 0 17 2H7c-.379 0-.725.214-.895.553L4.382 6H3v2h1.133L6.01 21.142A1 1 0 0 0 7 22zm10.418-11H6.582l-.429-3h11.693l-.428 3zm-9.551 9-.429-3h9.123l-.429 3H7.867zM7.618 4h8.764l1 2H6.618l1-2z"></path></svg>
                                        </div>
                                        <span>请作者喝奶茶</span>
                                    </span>
                                    <span className="text-text-300 dark:text-textDark-300"><IconRight/></span>
                                </div>
                            </div>
                            <div
                                className="px-4 bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div
                                    className="flex justify-between items-center h-12 border-b border-border-100 dark:border-borderDark-100"
                                    onClick={() => {
                                        navigate(`/article/${ALL_POSTS_IDS.VIP}`)
                                        setDrawerVisible(false)
                                    }}>
                                <span className="flex items-center space-x-2">
                                <div className="flex items-center text-text-200 dark:text-textDark-200">
                                    <IconThunderbolt />
                                </div>
                                <span>热榜会员</span>
                                </span>
                                    <span className="text-text-300 dark:text-textDark-300"><IconRight/></span>
                                </div>
                            </div>
                            <div className="px-4 rounded-b-lg bg-primary-200 dark:bg-primaryDark-200 active:bg-primary-400 active:dark:bg-primaryDark-400">
                                <div className="flex justify-between items-center h-12 border-border-100 dark:border-borderDark-100"
                                    onClick={() => {
                                        navigate(`/article/${ALL_POSTS_IDS.ADVERTISE}`)
                                        setDrawerVisible(false)
                                    }}>
                                <span className="flex items-center space-x-2">
                                <div className="flex items-center text-text-200 dark:text-textDark-200">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path fillRule="nonzero" d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zm-1 2H4v14h16V5zM9.399 8l3.199 8h-2.155l-.4-1h-3.29l-.4 1H4.199l3.2-8h2zM19 8v8h-3a3 3 0 0 1 0-6h.999L17 8h2zm-2 4h-1a1 1 0 0 0-.117 1.993L16 14h1v-2zm-8.601-1.115L7.552 13h1.692l-.845-2.115z"></path></g></svg>
                                </div>
                                <span>广告投放</span>
                                </span>
                                    <span className="text-text-300 dark:text-textDark-300"><IconRight/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center text-xs text-text-300 dark:text-textDark-300">
                        <span>© 2022-2023 今日热榜</span>
                        <span></span>
                        <span>Version {process.env.REACT_APP_VERSION}</span>
                    </div>
                </div>
            </Drawer>
            {/*抽屉结束*/}
        </header>
    </>
}

export default MobileRender