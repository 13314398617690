import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Index from "../pages/About";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Setting from "../pages/Setting";
import Home from "../pages/Home";
import Article from "../pages/Article";
import {useSelector} from "react-redux";
import {getAllMenuTab} from "../redux/tab";
import VipActive from "../pages/VipActive";
import Maintenance from "../pages/Maintenance";


function MyRoutes() {
    const allMenuTabs = useSelector(getAllMenuTab)
    return (
        <Routes>
            <Route path="/about" element={<Index/>}/>
            <Route path="/signin" element={<Login/>}/>
            {/*<Route path="/signup" element={<Register/>}/>*/}
            <Route path="/setting" element={<Setting/>}/>
            <Route path="/vip-active" element={<VipActive/>}/>
            <Route path="/article" element={<Article/>}/>
            <Route path="/article/:id" element={<Article/>}/>
            {allMenuTabs.length > 0 && allMenuTabs.map((item) => {
                return <Route key={item.menu_key} path={`/${item.menu_key}`} element={<Home/>}/>
            })}
            <Route path="/following" element={<Home/>}/>
            <Route path="/maintenance" element={<Maintenance/>}/>
            <Route path="/" element={<Home/>}/>
            {/*<Route path="/item/:item_id" element={<Home/>}/>*/}
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    )
}

export default MyRoutes