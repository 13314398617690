import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Main from "./Main";
import Aside from "./Aside";
import {useSelector} from "react-redux";
import {getActiveMenuKey} from "../../redux/tab";
import {MENU_KEY} from "../../constants/menu";
import MainUser from "./MainUser";
// import {useParams} from "react-router-dom";

function Home() {

    const menuKey = useSelector(getActiveMenuKey)
    const isFollowPage = menuKey === MENU_KEY.FOLLOWING

    // const {item_id} = useParams();

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div id="home-body" className="grow flex flex-row mx-auto">
                {isFollowPage && <MainUser/>}
                {!isFollowPage && <Main/>}
                <Aside isFollowPage={isFollowPage}/>
            </div>
            <Footer/>
            {/*{item_id && <div style={{background: "rgba(0,0,0,0.5)"}} className="fixed top-0 left-0 bottom-0 right-0 z-50">*/}
            {/*    <div className="bg-white h-screen mx-auto mt-20" style={{width: "1600px", height: "2000px"}}>*/}
            {/*        <iframe sandbox className="w-full h-full" src="https://proxy.justyy.com/-----http://www.zhihu.com/question/597701927/answer/3056146148?utm_campaign=rss&utm_medium=rss&utm_source=rss&utm_content=title" allow="*" frameborder="0">*/}
            
            {/*        </iframe>*/}
            {/*    </div>*/}
            {/*</div>}*/}
        </div>
    );
}

export default Home;
