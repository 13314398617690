import {combineReducers, configureStore} from '@reduxjs/toolkit'
import tabReducer from './tab'
import userReducer from './user'
import systemReducer from './system'

export const store = configureStore({
    reducer: combineReducers({
        tabReducer,
        userReducer,
        systemReducer,
    }),
    devTools: false,
    middleware: []
})
