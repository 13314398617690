import React, {useState} from "react";
import {Tabs, Radio, Tooltip, Alert, Link, Input, Tag, Select, Message} from "@arco-design/web-react";
import {IconDesktop, IconQuestionCircle,} from "@arco-design/web-react/icon";
import {SETTING_KEY, getRealSetting, URL_TARGET_ENUM, LIST_SHOW_ENUM, TAB_SHOW_ENUM} from "../../constants/setting";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {getFilterNumRule, setTheme} from "../../redux/system";
import {THEME_ENUM} from "../../constants/system";
import {useNavigate} from "react-router-dom";
import {MENU_FOLLOWING_NAME, MENU_KEY} from "../../constants/menu";

const TabPane = Tabs.TabPane;


function PcRender({theme, isLoggedIn, userSetting, onChangeSetting, allMenuTabs, filterNumLimit}) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [addingFilterWord, setAddingFilterWord] = useState('')
    const filterNumNormal = useSelector(getFilterNumRule(1))
    const filterNumVip = useSelector(getFilterNumRule(2))
    const filterWordArr = getRealSetting(SETTING_KEY.FILTER_WORD, userSetting[SETTING_KEY.FILTER_WORD])

    function onAddFilterWord() {
        if (addingFilterWord.trim() === '') {
            return
        }
        if (filterWordArr.includes(addingFilterWord)) {
            Message.error('屏蔽词已存在')
            return
        }
        if (addingFilterWord.length > 5) {
            Message.error('屏蔽词长度不能超过5个字符')
            return
        }
        if (filterWordArr.length + 1 > filterNumLimit) {
            Message.error(`当前会员等级最多添加${filterNumLimit}个屏蔽词`)
            return
        }
        onChangeSetting(SETTING_KEY.FILTER_WORD, filterWordArr.concat(addingFilterWord))
        setAddingFilterWord('')
    }


    return (
        <div
            className="text-text-100 dark:text-textDark-100 pb-5 bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden mb-2 md:rounded-md shadow-sm">
            {!isLoggedIn && <Alert type='warning' content={<>未登录，修改将保存在本地，<Link onClick={() => navigate("/signin")}>登录</Link> 后可保存至云端</>}/>}
            <Tabs defaultActiveTab='1' className="pt-1 px-1">
                <TabPane key='1' title={<span><IconDesktop style={{marginRight: 6}}/>显示</span>}>
                    <div className="flex flex-col space-y-8">
                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">颜色主题</div>
                            <div className="flex-1">
                                <div className="space-x-4 xl:space-x-6 flex">
                                    <div onClick={() => dispatch(setTheme(THEME_ENUM.LIGHT))}
                                         className={classNames({
                                             "border-myTheme-600 border-2": theme === THEME_ENUM.LIGHT,
                                             "border border-border-100": theme !== THEME_ENUM.LIGHT
                                         }, "bg-primary-100 cursor-pointer w-[130px] h-[100px] rounded-md flex flex-col justify-between")}>
                                        <div className="flex h-full flex-col items-center border-b border-border-100">
                                            <div
                                                className="w-full rounded-t-md h-[32px] bg-primary-200 border-border-100 border-b mb-1 shadow-sm"></div>
                                            <div
                                                className="self-end w-2/3 h-[18px] flex border mb-1 rounded-l bg-primary-200 border-border-100 shadow-sm"></div>
                                            <div
                                                className="self-end w-2/3 h-full flex flex-col border rounded-t bg-primary-200 border-border-100 shadow-sm border-b-0"></div>
                                        </div>
                                        <div
                                            className="text-text-100 h-[30px] rounded-b-md flex justify-center items-center bg-white">
                                            <span>浅色主题</span></div>
                                    </div>
                                    <div onClick={() => dispatch(setTheme(THEME_ENUM.DARK))}
                                         className={classNames({
                                             "border-myTheme-600 border-2": theme === THEME_ENUM.DARK,
                                             "border border-borderDark-100": theme !== THEME_ENUM.DARK
                                         }, "bg-primaryDark-100 cursor-pointer w-[130px] h-[100px] rounded-md flex flex-col justify-between")}>
                                        <div
                                            className="flex h-full flex-col items-center border-b border-borderDark-100">
                                            <div
                                                className="w-full rounded-t-md h-[32px] bg-primaryDark-200 border-borderDark-100 border-b mb-1 shadow-sm"></div>
                                            <div
                                                className="self-end w-2/3 h-[18px] flex border mb-1 rounded-l bg-primaryDark-200 border-borderDark-100 shadow-sm"></div>
                                            <div
                                                className="self-end w-2/3 h-full flex flex-col border rounded-t bg-primaryDark-200 border-borderDark-100 shadow-sm border-b-0"></div>
                                        </div>
                                        <div
                                            className="text-text-100 h-[30px] rounded-b-md flex justify-center items-center text-textDark-100">深色主题
                                        </div>
                                    </div>
                                    <div onClick={() => dispatch(setTheme(THEME_ENUM.AUTO))}
                                         className={classNames({
                                             "border-myTheme-600 border-2": theme === THEME_ENUM.AUTO,
                                             "border border-border-100 dark:border-borderDark-100": theme !== THEME_ENUM.AUTO
                                         }, "cursor-pointer w-[130px] h-[100px] rounded-md flex flex-col justify-between")}>
                                        <div className="flex h-full items-center">
                                            <div
                                                className="bg-primary-100 rounded-tl-md h-full flex flex-col w-1/2 border-b border-border-100">
                                                <div
                                                    className="w-full rounded-tl-md h-[32px] bg-primary-200 border-border-100 border-b mb-1 shadow-sm"></div>
                                                <div
                                                    className="self-end w-2/3 h-[18px] flex border mb-1 rounded-l bg-primary-200 border-border-100 shadow-sm"></div>
                                                <div
                                                    className="self-end w-2/3 h-full flex flex-col border rounded-tl bg-primary-200 border-border-100 shadow-sm border-b-0"></div>
                                            </div>
                                            <div
                                                className="bg-primaryDark-100 rounded-tr-md h-full flex flex-col w-1/2 border-b border-borderDark-100">
                                                <div
                                                    className="w-full rounded-tr-md h-[32px] bg-primaryDark-200 border-borderDark-100 border-b mb-1 shadow-sm"></div>
                                                <div
                                                    className="self-end w-2/3 h-[18px] flex border mb-1 rounded-l bg-primaryDark-200 border-borderDark-100 shadow-sm"></div>
                                                <div
                                                    className="self-end w-2/3 h-full flex flex-col border rounded-tl bg-primaryDark-200 border-borderDark-100 shadow-sm border-b-0"></div>
                                            </div>
                                        </div>
                                        <div
                                            className="h-[30px] rounded-b-md flex justify-center items-center text-text-100 dark:text-textDark-100">跟随系统
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">
                                <Tooltip content="选择当前标签页，使用后退返回本站；选择新标签页，关闭新标签页以返回本站">
                                    <IconQuestionCircle/>
                                </Tooltip>
                                <span className="mr-0.5">打开链接方式</span>
                            </div>
                            <div className="flex-1">
                                <Radio.Group
                                    value={getRealSetting(SETTING_KEY.URL_TARGET_PC, userSetting[SETTING_KEY.URL_TARGET_PC])}>
                                    <Radio value={URL_TARGET_ENUM.BLANK}
                                           onChange={() => onChangeSetting(SETTING_KEY.URL_TARGET_PC, URL_TARGET_ENUM.BLANK)}>新标签页</Radio>
                                    <Radio value={URL_TARGET_ENUM.SELF}
                                           onChange={() => onChangeSetting(SETTING_KEY.URL_TARGET_PC, URL_TARGET_ENUM.SELF)}>当前标签页</Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">
                                <span>列表展示</span>
                            </div>
                            <div className="flex-1">
                                <Radio.Group
                                    value={getRealSetting(SETTING_KEY.LIST_SHOW_PC, userSetting[SETTING_KEY.LIST_SHOW_PC])}>
                                    <Radio value={LIST_SHOW_ENUM.COMPLETE}
                                           onChange={() => onChangeSetting(SETTING_KEY.LIST_SHOW_PC, LIST_SHOW_ENUM.COMPLETE)}>
                                        完整
                                    </Radio>
                                    <Radio value={LIST_SHOW_ENUM.NO_PIC}
                                           onChange={() => onChangeSetting(SETTING_KEY.LIST_SHOW_PC, LIST_SHOW_ENUM.NO_PIC)}>
                                        无图
                                    </Radio>
                                    <Radio value={LIST_SHOW_ENUM.SIMPLE}
                                           onChange={() => onChangeSetting(SETTING_KEY.LIST_SHOW_PC, LIST_SHOW_ENUM.SIMPLE)}>
                                        精简
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">
                                <span>选项卡默认</span>
                            </div>
                            <div className="flex-1">
                                <Radio.Group
                                    value={getRealSetting(SETTING_KEY.TAB_SHOW_PC, userSetting[SETTING_KEY.TAB_SHOW_PC])}>
                                    <Radio value={TAB_SHOW_ENUM.FOLD}
                                           onChange={() => onChangeSetting(SETTING_KEY.TAB_SHOW_PC, TAB_SHOW_ENUM.FOLD)}>
                                        折叠
                                    </Radio>
                                    <Radio value={TAB_SHOW_ENUM.UNFOLD}
                                           onChange={() => onChangeSetting(SETTING_KEY.TAB_SHOW_PC, TAB_SHOW_ENUM.UNFOLD)}>
                                        展开
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="items-center w-[115px] xl:w-[160px] flex flex-row-reverse">
                                <div className="flex items-center space-x-0.5">
                                    <span>默认菜单</span>
                                    <Tooltip content="当网址是https://rebang.today/时，默认打开的菜单页">
                                        <IconQuestionCircle/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex-1">
                                <Select
                                    defaultValue={getRealSetting(SETTING_KEY.DEFAULT_MENU, userSetting[SETTING_KEY.DEFAULT_MENU])}
                                    placeholder='请选择'
                                    style={{ width: 120 }}
                                    onChange={(value) => {
                                        onChangeSetting(SETTING_KEY.DEFAULT_MENU, value)
                                    }}
                                >
                                    <Select.Option value={MENU_KEY.FOLLOWING}>
                                        {MENU_FOLLOWING_NAME}
                                    </Select.Option>
                                    {allMenuTabs.map((item) =>
                                        <Select.Option key={item.menu_key} value={item.menu_key}>
                                            {item.menu_name}
                                        </Select.Option>
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="items-start mt-1 w-[115px] xl:w-[160px] flex flex-row-reverse">
                                <div className="flex items-center space-x-0.5">
                                    <span>自定义屏蔽词</span>
                                    <Tooltip content={<span>当标题匹配到屏蔽词，将会被淡化展示<br/>普通用户最多添加{filterNumNormal}条<br/>会员最多添加{filterNumVip}条</span>}>
                                        <IconQuestionCircle/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex-1">
                                <Input.Search
                                    searchButton='添加'
                                    placeholder='请输入屏蔽词'
                                    style={{ width: 250 }}
                                    maxLength={{length: 5, errorOnly: true}}
                                    value={addingFilterWord}
                                    onChange={(e) => setAddingFilterWord(e)}
                                    onSearch={onAddFilterWord}
                                />
                                <div className="mt-3 flex flex-wrap gap-1.5 pr-6">
                                    {filterWordArr.map((item) => <Tag color="gray" onClose={() => {
                                        onChangeSetting(SETTING_KEY.FILTER_WORD, filterWordArr.filter(key => key !== item))
                                    }} closable key={item}>{item}</Tag>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default PcRender;
