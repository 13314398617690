import {getCDNUrl, getWebUrl} from "../../../utils";

const fileContent = `## 热榜会员有哪些内容

1. 去除任何形式的广告
2. 订阅节点数量从20提升至50
3. 订阅热榜（从您的订阅节点中排列组合的热榜）
4. 订阅飙升榜（您的订阅节点中最近1小时飙升榜，默认在电脑端订阅页面右侧展示）
5. 自定义屏蔽词数量提升至20
6. 全站热榜支持节点过滤

## 热榜会员定价

- 6 RMB/月
- ~~36RMB/半年~~ 30 RMB/半年 
- ~~72RMB/年~~ 50 RMB/年

> 注：捐款任意金额，我们都会折算成天数，给您只多不少的会员时长

## 如何获得热榜会员

1. 【付费】[捐赠](${getWebUrl("article/donate")})，激活码会发送至您备注的邮箱
2. 【免费】[关注公众号](${getWebUrl("article/wechat-mp")})，每个月会发放激活码，先到先得

## 捐赠流程
1. 扫描下方二维码捐款
2. **捐款时务必备注您的邮箱（非常重要！）**，我们会将激活码发送至您的邮箱
3. 收到邮件后，在[会员激活页面](${getWebUrl("vip-active")})，输入激活码，完成激活

> 站长会统一处理当天20:00前的捐赠，请留意邮件

> 如果长时间未收到邮件或忘记备注邮箱，或有其他任何相关问题，请联系邮箱[support@rebang.today](mailto:support@rebang.today)或在[公众号](${getWebUrl("article/wechat-mp")})中留言

## 捐款二维码

![](${getCDNUrl("forever/donate/alipay_wechatpay.png")})

## 下面是一些碎碎念
### 为什么推出热榜会员

1. 网站的运营（服务器、CDN 等...）需要钱，没钱就会关站
2. 网站的完善和优化需要动力，没钱就会失去动力
3. 在迭代的过程中，我们发现一些功能属于\`此功能并非关键，但比较消耗服务器资源\`，因此我们将其定义为会员功能。此后我们也会遵循此原则

`;

export default fileContent;