import React from "react";
import {Button, Checkbox, Form, Input, Grid, Link} from "@arco-design/web-react";
import {useNavigate} from "react-router-dom";
import Ads from "../../components/Ads";
const FormItem = Form.Item;

function RegisterRender() {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    return (
        <div className="grow shrink flex justify-center">
            <div className="w-screen md:w-[600px] xl:w-[770px]">
                <div className="text-text-100 dark:text-textDark-100 bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden mb-2 md:rounded-md shadow-sm">
                    <div className="ml-5 md:ml-14 xl:ml-20 py-10 w-[300px] md:w-[400px] xl:w-[500px]">
                        <Form>
                            <FormItem label='邮箱'>
                                <Input type="email" placeholder='请输入电子邮箱' />
                            </FormItem>
                            <FormItem label='验证码'>
                                <Grid.Row gutter={8}>
                                    <Grid.Col span={12}>
                                        <Form.Item field='name' rules={[{ required: true }]}>
                                            <Input type="email" placeholder='请输入验证码' />
                                        </Form.Item>
                                    </Grid.Col>
                                    <Grid.Col span={12}>
                                        <Form.Item field='age' rules={[{ required: true }]}>
                                            <Button type='primary'>发送验证码</Button>
                                        </Form.Item>
                                    </Grid.Col>
                                </Grid.Row>
                            </FormItem>
                            <FormItem label='密码'>
                                <Input.Password />
                            </FormItem>
                            <FormItem label='重复密码'>
                                <Input.Password />
                            </FormItem>
                            <FormItem wrapperCol={{ offset: 5 }}>
                                <Checkbox>我已阅读《用户协议》</Checkbox>
                            </FormItem>
                            <FormItem wrapperCol={{ offset: 5 }}>
                                <Button type='primary' className="mr-3">注册</Button>
                                <Link type='text' onClick={() => {navigate("/signin")}}>已有账号？去登录</Link>
                            </FormItem>
                        </Form>
                    </div>
                </div>
                {/*广告 开始*/}
                <Ads type={2}/>
                {/*广告 结束*/}
            </div>
        </div>
    );
}
export default RegisterRender;

