import {getCDNUrl} from "../../../utils";

const fileContent = `
## 将本站加入到广告屏蔽白名单

> 为了网站的可持续发展，请将本站加入到您的广告屏蔽白名单

> 如果您没有安装过广告屏蔽插件，请检查您的浏览器是否自带广告屏蔽，并在设置中为本站添加白名单

### AdGuard、AdBlock、AdblockPlus 的设置方法类似。下面以 AdGuard 为例：

![adguard](${getCDNUrl("forever/article/ads-whitelist/adguard.jpg")})
`;

export default fileContent;