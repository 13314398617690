import {MENU_KEY} from "./menu";

export const SETTING_KEY = {
    URL_TARGET_PHONE: "url_target_phone", // 手机端连接打开方式 0-未设置 1-新窗口 2-当前窗口
    URL_TARGET_PC: "url_target_pc", // 电脑端连接打开方式 0-未设置 1-新窗口 2-当前窗口
    LIST_SHOW_PHONE: "list_show_phone", // 手机端列表展示 0-未设置 1-完整 2-无图 3-精简
    LIST_SHOW_PC: "list_show_pc", // 电脑端列表展示 0-未设置 1-完整 2-无图 3-精简
    TAB_SHOW_PHONE: "tab_show_phone", // 手机端tab展示 0-未设置 1-折叠 2-展开
    TAB_SHOW_PC: "tab_show_pc", // 电脑端tab展示 0-未设置 1-折叠 2-展开
    FOLLOW_TAB: "follow_tab", // 关注配置 json
    TECH_JOURNAL_EXCLUDE: "tech_journal_exclude", // 技术期刊排除列表 逗号分隔
    DEFAULT_MENU: "default_menu", // 默认菜单
    FILTER_WORD: "filter_word", // 屏蔽词
}

export const URL_TARGET_ENUM = {
    DEFAULT: 0, // 未设置
    BLANK: 1, // 新窗口
    SELF: 2 // 当前窗口
}

export const LIST_SHOW_ENUM = {
    DEFAULT: 0, // 未设置
    COMPLETE: 1, // 完整模式
    NO_PIC: 2, // 无图模式
    SIMPLE: 3 // 精简模式
}

export const TAB_SHOW_ENUM = {
    DEFAULT: 0, // 未设置
    FOLD: 1, // 折叠
    UNFOLD: 2, // 展开
}

// SETTING_DEFAULT 默认的设置
export const SETTING_DEFAULT = {
    [SETTING_KEY.URL_TARGET_PHONE]: URL_TARGET_ENUM.DEFAULT,
    [SETTING_KEY.URL_TARGET_PC]: URL_TARGET_ENUM.DEFAULT,
    [SETTING_KEY.LIST_SHOW_PHONE]: LIST_SHOW_ENUM.DEFAULT,
    [SETTING_KEY.LIST_SHOW_PC]: LIST_SHOW_ENUM.DEFAULT,
    [SETTING_KEY.TAB_SHOW_PHONE]: TAB_SHOW_ENUM.DEFAULT,
    [SETTING_KEY.TAB_SHOW_PC]: TAB_SHOW_ENUM.DEFAULT,
    [SETTING_KEY.FOLLOW_TAB]: "[]",
    [SETTING_KEY.TECH_JOURNAL_EXCLUDE]: "",
    [SETTING_KEY.DEFAULT_MENU]: "",
    [SETTING_KEY.FILTER_WORD]: [],
}

export const getRealSetting = (settingType, settingValue) => {
    switch (settingType) {
        case SETTING_KEY.URL_TARGET_PHONE:
            if (!Object.values(URL_TARGET_ENUM).includes(settingValue) || settingValue === URL_TARGET_ENUM.DEFAULT) {
                return URL_TARGET_ENUM.BLANK
            }
            return settingValue
        case SETTING_KEY.URL_TARGET_PC:
            if (!Object.values(URL_TARGET_ENUM).includes(settingValue) || settingValue === URL_TARGET_ENUM.DEFAULT) {
                return URL_TARGET_ENUM.BLANK
            }
            return settingValue
        case SETTING_KEY.LIST_SHOW_PHONE:
        case SETTING_KEY.LIST_SHOW_PC:
            if (!Object.values(LIST_SHOW_ENUM).includes(settingValue) || settingValue === LIST_SHOW_ENUM.DEFAULT) {
                return LIST_SHOW_ENUM.COMPLETE
            }
            return settingValue
        case SETTING_KEY.TAB_SHOW_PHONE:
        case SETTING_KEY.TAB_SHOW_PC:
            if (!Object.values(TAB_SHOW_ENUM).includes(settingValue) || settingValue === TAB_SHOW_ENUM.DEFAULT) {
                return TAB_SHOW_ENUM.FOLD
            }
            return settingValue
        case SETTING_KEY.DEFAULT_MENU:
            if (settingValue === "") {
                return MENU_KEY.HOME
            }
            return settingValue
        case SETTING_KEY.FILTER_WORD:
            if (!settingValue) {
                return SETTING_DEFAULT[SETTING_KEY.FILTER_WORD]
            }
            return settingValue
        default:
            return settingValue
    }
}
