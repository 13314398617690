import {getCDNUrl, getWebUrl} from "../../../utils";

const fileContent = `## 订阅管理功能

自定义订阅节点，支持拖动排序，即便是在手机上也交互十分方便

![订阅管理页面](${getCDNUrl("forever/article/version100img1.png")})

如果是在电脑端，登录后可以右键点击节点，实现快速订阅/取消订阅

![快速订阅/取消订阅](${getCDNUrl("forever/follow-tutorial.png")})

## 分类功能

节点再也不会乱糟糟，以后添加的节点也会按照分类排列整齐

![图片](${getCDNUrl("forever/article/version100img2.jpg")})

## 云端同步

网站的设置和订阅，将会跟随账号，自动云端同步

> **需要注意的是**：手机端和电脑端订阅同步，设置不同步，毕竟电脑和手机的操作方式不一样

## 捐赠

我们开通了捐赠通道，电脑端可在网页下方找到捐赠链接，手机端可在左侧功能栏找到

点击查看[捐赠页面](${getWebUrl("article/donate")})

## 会员功能

在更新过程中，我们发现一些功能属于**并非关键，但比较消耗服务器资源**，于是我们索性推出会员功能，目前会员功能包括：

1. 去除任何形式的广告
2. 订阅节点数量从20提升至50
3. 会员专属订阅节点
   1. 订阅热榜（从您的订阅节点中排列组合的热榜）
   2. 订阅飙升榜（您的订阅节点中最近1小时飙升榜，默认在电脑端\`订阅\`页面右侧展示）
4. 更多功能规划中...


提到会员，可能会有人比较排斥，但如果网站想健康稳定运营下去，这是必不可少的东西，希望大家谅解。

我们往后也会以**此功能并非关键，但比较消耗服务器资源**为原则，**保证大多数用户的体验**为基础，来定义会员功能。

点击查看[会员功能详情](${getWebUrl("article/vip")})

`;

export default fileContent;