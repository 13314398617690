import {Alert, Button, Form, Input, Link} from "@arco-design/web-react";
import {useNavigate} from "react-router-dom";
import Ads from "../../components/Ads";

function VipActiveRender({isLoggedIn, onActiveVip}) {
    const navigate = useNavigate()
    const [form] = Form.useForm();

    return (
        <div className="grow shrink flex justify-center">
            <div className="w-screen md:w-[600px] xl:w-[770px]">
                <div
                    className="text-text-100 dark:text-textDark-100 bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden md:rounded-md shadow-sm mb-5">
                    {!isLoggedIn && <Alert type='error' content={<>请 <Link
                        onClick={() => navigate("/signin")}>登录</Link> 后再使用激活会员功能</>}/>}
                    <Form
                        form={form}
                        size="large"
                        wrapperCol={{span: 24}}
                        autoComplete='off'
                        onSubmit={(v) => {
                            onActiveVip(v.code)
                        }}
                        layout="vertical"
                        className="p-10 mx-auto xl:px-24"
                    >
                        <Form.Item
                            field='code'
                            label="激活码"
                            rules={[{
                                required: true,
                                type: 'string',
                                length: 36,
                            }]}
                        >
                            <Input
                                   placeholder='xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'/>
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>激活</Button>
                        </Form.Item>
                    </Form>
                </div>
                {/*广告 开始*/}
                <Ads type={2}/>
                {/*广告 结束*/}
            </div>
        </div>
    );
}

export default VipActiveRender;
