import React from "react";

function Loading() {
    return (
        <div
            className="flex border-b md:border mb-2 pt-2.5 md:rounded-md bg-primary-200 dark:bg-primaryDark-200 border-border-200 dark:border-borderDark-100 px-3 shadow-sm">
            <div className="animate-pulse flex flex-wrap">
                <div className="w-20 mb-2.5 h-8 mr-3 bg-loading-100 dark:bg-loadingDark-100 rounded-lg"></div>
                <div className="w-20 mb-2.5 h-8 mr-3 bg-loading-100 dark:bg-loadingDark-100 rounded-lg"></div>
                <div className="w-20 mb-2.5 h-8 mr-3 bg-loading-100 dark:bg-loadingDark-100 rounded-lg"></div>
                <div className="hidden md:block w-20 mb-2.5 h-8 mr-3 bg-loading-100 dark:bg-loadingDark-100 rounded-lg"></div>
                <div className="hidden md:block w-20 mb-2.5 h-8 mr-3 bg-loading-100 dark:bg-loadingDark-100 rounded-lg"></div>
                <div className="hidden md:block w-20 mb-2.5 h-8 mr-3 bg-loading-100 dark:bg-loadingDark-100 rounded-lg"></div>
                <div className="hidden md:block w-20 mb-2.5 h-8 mr-3 bg-loading-100 dark:bg-loadingDark-100 rounded-lg"></div>
            </div>
        </div>
    );
}

export default Loading;
