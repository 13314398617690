import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useTitle} from "../../hooks";
import Aside from "./Aside";
import {Breadcrumb, Message, Notification} from "@arco-design/web-react";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import PcRender from "./PcRender";
import MobileRender from "./MobileRender";
import {getFilterNumLimit, getTheme, setMobileHeader} from "../../redux/system";
import {useDispatch, useSelector} from "react-redux";
import {editUserSetting, getIsLoggedIn, getUserSetting} from "../../redux/user";
import {apiEditUserSetting} from "../../apis/user";
import Ads from "../../components/Ads";
import {getAllMenuTab} from "../../redux/tab";
import {SETTING_KEY} from "../../constants/setting";

function Setting() {
    useTitle("设置")
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getIsLoggedIn)
    const userSetting = useSelector(getUserSetting)
    const theme = useSelector(getTheme)
    const allMenuTabs = useSelector(getAllMenuTab) // 所有菜单
    const filterNumLimit = useSelector(getFilterNumLimit) // 屏蔽词条数限制

    useEffect(() => {
        dispatch(setMobileHeader("设置"))
    }, [])

    function onChangeSetting(key, value) {
        const oldValue = userSetting[key]
        dispatch(editUserSetting({key, value}))
        if (isLoggedIn) {
            // 请求api
            if (key === SETTING_KEY.FILTER_WORD) {
                value = JSON.stringify(value)
            }
            apiEditUserSetting({filed: key, value:value.toString()}).then((res) => {
                Message.clear()
                if (res.code === 200) {
                    Message.success({content:"同步云端成功", duration: 2000})
                } else {
                    dispatch(editUserSetting({key, value: oldValue}))
                    Message.error("修改失败，请稍后再试")
                }
            }).catch(() => {
                dispatch(editUserSetting({key, value: oldValue}))
                Message.error("修改失败，请稍后再试")
            })
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="grow mx-auto">
                <div className="py-2 px-2 md:pt-0 md:px-0">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a className="cursor-pointer" onClick={() => navigate("/")}>首页</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>设置</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="flex">
                    <div className="w-screen md:w-[600px] xl:w-[770px]">
                        <div className="hidden md:block">
                            <PcRender
                                filterNumLimit={filterNumLimit}
                                allMenuTabs={allMenuTabs}
                                onChangeSetting={onChangeSetting}
                                userSetting={userSetting}
                                isLoggedIn={isLoggedIn}
                                theme={theme}/>
                        </div>
                        <div className="md:hidden">
                            <MobileRender
                                filterNumLimit={filterNumLimit}
                                allMenuTabs={allMenuTabs}
                                onChangeSetting={onChangeSetting}
                                userSetting={userSetting}
                                isLoggedIn={isLoggedIn}
                                theme={theme}/>
                        </div>
                        <Ads type={2}/>
                    </div>
                    <div className="hidden asideSize:block w-[270px] ml-4">
                        <Aside/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Setting;
