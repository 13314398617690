import Notification from "../../components/Notification";
import MarkNav from 'markdown-navbar';
import "../../assets/css/markdown-navbar.css"
import Ads from "../../components/Ads";

function Aside({content}) {
    return (
        <aside className="ml-4 hidden asideSize:block w-[270px]">
            <div className="flex flex-col items-stretch h-full space-y-5">
                <Notification/>
                {/*markdown导航开始*/}
                <div className="px-3 py-2 border border-border-100 dark:border-borderDark-100 bg-primary-200 dark:bg-primaryDark-200 rounded-md shadow-sm text-text-200 dark:text-textDark-200">
                    <MarkNav
                        className="markdown-navbar-body"
                        source={content}
                        ordered={true}
                    />
                </div>
                {/*markdown导航结束*/}
                <Ads type={1}/>
            </div>
        </aside>
    );
}

export default Aside;
