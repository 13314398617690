import React, {useEffect} from 'react';
import Item from "../../components/Item";
import Tab from "../../components/Tab";
import {useDispatch, useSelector} from "react-redux";
import {getActiveTabKey, getNowAllTabs, setActiveTabKey} from "../../redux/tab";
import {useNavigate} from "react-router-dom";
import {getUserSetting, initUserSetting} from "../../redux/user";
import {SETTING_KEY} from "../../constants/setting";
import {apiEditUserSetting} from "../../apis/user";
import {getFollowTabLimit} from "../../redux/system";
import Ads from "../../components/Ads";


function Main() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userSetting = useSelector(getUserSetting)
    const followTabLimit = useSelector(getFollowTabLimit)

    // tab数据
    const allTabs = useSelector(getNowAllTabs)
    const activeTabKey = useSelector(getActiveTabKey)

    useEffect(() => {
        if (allTabs.length > 0) {
            if (activeTabKey === "" || !allTabs.some(obj => obj.hasOwnProperty("key") && obj["key"] === activeTabKey)) {
                dispatch(setActiveTabKey(allTabs[0].key))
            }
        } else {
            dispatch(setActiveTabKey(""))
        }
    }, [activeTabKey, allTabs])

    function onChangeTab(key) {
        dispatch(setActiveTabKey(key))
        navigate(`?tab=${key}`, {replace: true})
    }

    async function onChangeFollowTab(type, value) {
        if (type === "add" && value.length > followTabLimit) {
            throw new Error(`当前会员等级最多订阅${followTabLimit}个节点`)
        }
        await apiEditUserSetting({filed: SETTING_KEY.FOLLOW_TAB, value: JSON.stringify(value)}).then((res) => {
            if (res.code === 200) {
                dispatch(initUserSetting(res.data))
            } else {
                throw new Error("修改失败")
            }
        })
    }

    return (
        <main className="w-screen md:w-[600px] xl:w-[770px]">
            <div className="relative">
                {/*导航栏开始 #e5e7eb*/}
                <Tab activeTabKey={activeTabKey}
                     onChangeTab={onChangeTab}
                     allTabs={allTabs}
                     userSetting={userSetting}
                     isFollowPage={false}
                     onShowEditTab={null}
                     onChangeFollowTab={onChangeFollowTab}
                />
                {/*导航栏结束*/}
                {/*item 开始*/}
                <div
                    className="bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden mb-5 md:rounded-md shadow-sm">
                    <Item activeTabKey={activeTabKey} onChangeTab={onChangeTab} allTabs={allTabs}
                          userSetting={userSetting}/>
                </div>
                {/*item 结束*/}
                {/*广告 开始*/}
                <Ads type={2}/>
                {/*广告 结束*/}
            </div>
        </main>
    );
}

export default Main;
