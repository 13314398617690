// type 1-竖版 2-横版
// <script type="text/javascript" charset="UTF-8" src="https://cdn.wwads.cn/js/makemoney.js" async></script>
import React, {useEffect, useState} from "react";
import {reportCount} from "../../apis/system";
import {ALL_POSTS_IDS} from "../../pages/Article/posts";
import {Link} from "@arco-design/web-react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAdsDeletedRemind, setAdsDeletedDontRemind} from "../../redux/system";

function Wwads({type}) {
    const [adDeleted, setAdDeleted] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const showThisWeek = useSelector(getAdsDeletedRemind)

    function docReady(t) {
        (document.readyState === "complete" || document.readyState === "interactive")
            ? setTimeout(t, 1)
            : document.addEventListener("DOMContentLoaded", t);
    }

    useEffect(() => {
        docReady(function () {
            setTimeout(function () {
                if(window._AdBlockInit === undefined){
                    adDetected()
                }
            }, 3000);
        });
    }, [])

    function adDetected() {
        reportCount("ads_deleted")
        setAdDeleted(true)
    }

    function dontRemindThisWeek() {
        reportCount("ads_dont_remind_this_week")
        dispatch(setAdsDeletedDontRemind())
    }


    return (
        <>
            {adDeleted && showThisWeek && (
                type === 1
                    ? <div className="shadow-sm rounded-md bg-primary-200 dark:bg-primaryDark-200 text-text-200 dark:text-textDark-200">
                        <div className="space-y-3 p-5 rounded-md w-[270px] flex flex-col items-center border border-border-100 dark:border-borderDark-100">
                            <div className="flex flex-col">
                                <h1 className="text-lg font-black">广告无法正常显示</h1>
                            </div>
                            <div className="flex text-base flex-col self-start font-black">
                                <span>这里的广告不会铺满屏幕、没有夸张的色彩、不会追踪您的隐私</span>
                            </div>
                            <div className="flex text-sm flex-col self-start">
                                <span className="mb-1">您可以通过以下任一操作关闭此提醒</span>
                                <span>1. 将本站<Link style={{lineHeight: "20px"}} onClick={() => navigate(`/article/${ALL_POSTS_IDS.ADS_WHITELIST}`)}>加入广告屏蔽白名单</Link></span>
                                <span>2. 会员可去除广告 <Link style={{lineHeight: "20px"}} onClick={() => navigate(`/article/${ALL_POSTS_IDS.VIP}`)}>成为会员</Link></span>
                                <span>3. 拒绝以上两条 <Link style={{lineHeight: "20px"}} onClick={dontRemindThisWeek}>本周不再提示</Link></span>
                            </div>
                        </div>
                    </div>
                    : <div className="asideSize:hidden space-y-2 py-3 px-5 flex flex-col items-center shadow-sm md:rounded-md bg-primary-200 dark:bg-primaryDark-200 text-text-200 dark:text-textDark-200">
                        <div className="flex flex-col">
                            <h1 className="text-lg font-black">广告无法正常显示</h1>
                        </div>
                        <div className="flex text-base flex-col self-start font-black">
                            <span>这里的广告不会铺满屏幕、没有夸张的色彩、不会追踪您的隐私</span>
                        </div>
                        <div className="flex text-sm flex-col self-start">
                            <span className="mb-1">您可以通过以下任一操作关闭此提醒</span>
                            <span>1. 将本站<Link style={{lineHeight: "20px"}} onClick={() => navigate(`/article/${ALL_POSTS_IDS.ADS_WHITELIST}`)}>加入广告屏蔽白名单</Link></span>
                            <span>2. 会员可去除广告 <Link style={{lineHeight: "20px"}} onClick={() => navigate(`/article/${ALL_POSTS_IDS.VIP}`)}>成为会员</Link></span>
                            <span>3. 拒绝以上两条 <Link style={{lineHeight: "20px"}} onClick={dontRemindThisWeek}>本周不再提示</Link></span>
                        </div>
                    </div>
            )}
            {!adDeleted && (
                type === 1
                    ? <div onClick={() => {reportCount("ads_wwads_pc")}}
                           className="shadow-sm rounded-md bg-primary-200 dark:bg-primaryDark-200 text-text-200 dark:text-textDark-200">
                        {process.env.NODE_ENV === "development" && <div
                            className="rounded-md w-[270px] h-[242px] text-4xl flex justify-center items-center border border-border-100 dark:border-borderDark-100">万维广告</div>}
                        {process.env.NODE_ENV === "production" && <div
                            className="rounded-md wwads-cn wwads-vertical border border-border-100 dark:border-borderDark-100"
                            data-id="196"
                            style={{
                                borderRadius: "6px",
                                marginTop: 0,
                                maxWidth: "100%",
                                paddingTop: "20px",
                                backgroundColor: "inherit"
                            }}
                        />}
                    </div>
                    : <div onClick={() => {reportCount("ads_wwads_mobile")}}
                           className="asideSize:hidden shadow-sm md:rounded-md bg-primary-200 dark:bg-primaryDark-200 text-text-200 dark:text-textDark-200">
                        {process.env.NODE_ENV === "development" && <div
                            className="md:rounded-md w-full h-[120px] text-4xl flex justify-center items-center border-y md:border border-border-100 dark:border-borderDark-100">万维广告</div>}
                        {process.env.NODE_ENV === "production" && <div
                            className="wwads-cn wwads-horizontal border-y md:border border-border-100 dark:border-borderDark-100"
                            data-id="196" style={{marginTop: 0, maxWidth: "100%", backgroundColor: "inherit"}}
                        />}
                    </div>
            )}
        </>
    )
}

export default Wwads;
