import React, {useEffect, useState} from "react";
import {checkIsDark} from "../../utils/setting";
import {
    IconExport,
    IconMoonFill,
    IconSettings,
    IconSunFill,
    IconThunderbolt
} from "@arco-design/web-react/icon";
import {Avatar, Button, Divider, Dropdown, Menu} from "@arco-design/web-react";
import {useDispatch, useSelector} from "react-redux";
import {THEME_ENUM} from "../../constants/system";
import {useNavigate} from "react-router-dom";
import {getTheme, setTheme} from "../../redux/system";
import {MENU_FOLLOWING_NAME, MENU_KEY} from "../../constants/menu";
import {getEmailAvatarName} from "../../utils";
import {getUserHasOnceVip, getUserIsVip, getUserVipEndAtString} from "../../redux/user";
import {ALL_POSTS_IDS} from "../../pages/Article/posts";

function PcRender({onChangeMenuKey, menuKey, allMenuTabs, isLoggedIn, userAccount, onLogout}) {
    const MenuItem = Menu.Item;

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const theme = useSelector(getTheme)
    const isVip = useSelector(getUserIsVip)
    const hasOnceVip = useSelector(getUserHasOnceVip)
    const vipEndAtString = useSelector(getUserVipEndAtString)
    const [dark, setDark] = useState(checkIsDark(theme))
    useEffect(() => setDark(checkIsDark(theme)), [theme])

    return <div>
        <header
            className="w-full h-[59px] bg-primary-200 dark:bg-primaryDark-200 border-border-100 dark:border-borderDark-100 border-b mb-5 shadow-sm">
            {/*居中容器开始*/}
            {/*<div className="mx-auto w-screen md:w-[932px] xl:w-[1166px] h-full flex relative items-center">*/}
            <div
                className="mx-auto w-[750px] xl:w-[1056px] asideSize:w-[886px] h-full flex relative items-center justify-between">
                <div className="flex items-center grow">
                    {/*LOGO开始*/}
                    <div className="grow-0">
                        <a href={process.env.REACT_APP_WEB_URL} target="_self">
                            <strong
                                className="text-lg asideSize:text-xl font-serif text-center select-none text-text-100 dark:text-textDark-100">
                                Rebang.Today
                            </strong>
                        </a>
                    </div>
                    {/*LOGO开始*/}
                    {/*menu开始*/}
                    <div className="h-full grow -ml-5 asideSize:ml-0">
                        <Menu className="h-full w-full bg-inherit asideSize:text-base text-sm select-none"
                              mode='horizontal' selectedKeys={menuKey}>
                            <MenuItem key={MENU_KEY.FOLLOWING} onClick={() => {
                                onChangeMenuKey(MENU_KEY.FOLLOWING)
                            }}>{MENU_FOLLOWING_NAME}</MenuItem>
                            {allMenuTabs.length > 0 && allMenuTabs.map((item, index) => {
                                return <MenuItem key={item.menu_key}
                                                 onClick={() => {
                                                     onChangeMenuKey(item.menu_key)
                                                 }}>{item.menu_name}</MenuItem>
                            })}
                            {/*<MenuItem key='3'>财经</MenuItem>*/}
                            {/*<MenuItem key='5'>设计</MenuItem>*/}
                            {/*<MenuItem key='6'>政务</MenuItem>*/}
                            {/*<MenuItem key='8'>娱乐</MenuItem>*/}
                            {/*<MenuItem key='9'>购物</MenuItem>*/}
                            {/*<SubMenu key='5' title="Solution">*/}
                            {/*    <MenuItem key='5'>123</MenuItem>*/}
                            {/*    <MenuItem key='6'>2345</MenuItem>*/}
                            {/*</SubMenu>*/}
                        </Menu>
                    </div>
                    {/*menu结束*/}
                </div>
                {/*按钮开始*/}
                <div className="shrink-0 h-full flex">
                    <div className="flex items-center space-x-2 asideSize:space-x-4">
                        <Button className="text-base"
                                icon={dark ? <IconSunFill/> : <IconMoonFill/>}
                                shape="circle"
                                type="secondary"
                                onClick={() => {
                                    dispatch(setTheme(dark ? THEME_ENUM.LIGHT : THEME_ENUM.DARK))
                                }}
                        />
                        <Button className="text-base"
                                icon={<IconSettings/>}
                                shape="circle"
                                type="secondary"
                                onClick={() => navigate("/setting")}
                        />
                        {!isLoggedIn && <Button
                            shape='round'
                            type='primary'
                            onClick={() => navigate("/signin")}
                        >登录</Button>}
                        {isLoggedIn && <Dropdown droplist={
                            <Menu onClickMenuItem={(key) => {
                                if (key === '1') {
                                    return false;
                                }
                            }}>
                                <Menu.Item key='1' className="flex space-x-2 px-4 py-1"
                                           style={{cursor: "default", height: "auto", backgroundColor: "transparent"}}>
                                    <Avatar shape="square"
                                            triggerIcon={isVip ? <div><IconThunderbolt style={{fill: "white", stroke: "initial"}} /></div> : null}
                                            triggerIconStyle={{borderWidth: "2px", borderColor:"white", backgroundColor: "#FFC62E"}}
                                            size={39}>{getEmailAvatarName(userAccount.email)}</Avatar>
                                    <div className="flex flex-col leading-5 justify-center text-text-100 dark:text-textDark-100">
                                        <span>{userAccount.email}</span>
                                        {hasOnceVip && (
                                            isVip ? <span className="text-xs text-text-200 dark:text-textDark-200">会员 {vipEndAtString} 到期</span> :
                                            <span className="text-xs text-text-200 dark:text-textDark-200">会员已于 {vipEndAtString} 过期</span>
                                        )}
                                    </div>
                                </Menu.Item>
                                <Divider style={{margin: '4px 0'}}/>
                                {/*<Menu.Item key='2'>*/}
                                {/*    <IconUser style={{marginRight: 8, fontSize: 16, transform: 'translateY(1px)'}}/>*/}
                                {/*    账号管理*/}
                                {/*</Menu.Item>*/}
                                {!isVip && <Menu.Item key='3' onClick={() => {navigate(`/article/${ALL_POSTS_IDS.VIP}`)}}>
                                    <IconThunderbolt style={{marginRight: 8, fontSize: 16, transform: 'translateY(1px)',}}/>
                                    开通会员
                                </Menu.Item>}
                                {!isVip && <Divider style={{margin: '4px 0'}}/>}
                                <Menu.Item key='4' onClick={onLogout}>
                                    <IconExport style={{marginRight: 8, fontSize: 16, transform: 'translateY(1px)',}}/>
                                    退出登录
                                </Menu.Item>
                            </Menu>
                        } trigger='click' position='br'>
                            <Avatar className="cursor-pointer select-none"
                                    triggerIcon={isVip ? <div><IconThunderbolt style={{fill: "white", stroke: "initial"}} /></div> : null}
                                    triggerIconStyle={{borderWidth: "2px", borderColor:"white", backgroundColor: "#FFC62E"}}
                                    size={35}>{getEmailAvatarName(userAccount.email)}</Avatar>
                        </Dropdown>}
                    </div>
                </div>
                {/*按钮结束*/}
            </div>
            {/*居中容器结束*/}
        </header>
    </div>
}

export default PcRender