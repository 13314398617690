import {fetchGet, fetchPost, sendBeacon} from "../utils/fetch";
import {getSecondsTimestamp} from "../utils/time";
import {VERSION_LIST} from "../constants/version";
import useSWRInfinite from "swr/infinite";

// 默认点击事件
export function reportClickItem(tabKey, itemKey) {
    sendBeacon("/v1/click_item", {item_id: itemKey, tab_key: tabKey, type: 1})
}

// 排行榜的点击事件 权重会放小一点 包括top和top-user
export function reportClickItemTop(tabKey, itemKey) {
    sendBeacon("/v1/click_item", {item_id: itemKey, tab_key: tabKey, type: 2})
}

export function apiAdminDeleteItem(itemKey) {
    return fetchPost("/v1/admin/item/delete", {item_id: itemKey})
}


function itemListMiddleware(useSWRNext) {
    return (key, fetcher, config) => {
        const swr = useSWRNext(key, fetcher, config)
        if (swr.data) {
            let list = []
            let end = true
            for (const [index, item] of swr.data.entries()) {
                if (item.code === 200 && item?.data?.list?.length > 0) {
                    list = list.concat(JSON.parse(item.data.list))
                    if (index === swr.data.length - 1
                        && item.data.current_page < item.data.total_page) {
                        end = false
                    }
                } else {
                    break
                }
            }
            return {...swr, list, end}
        }
        return swr
    }
}

export function useItemList(query, version = 1) {
    const getKey = (index, previousPageData) => {
        if (previousPageData && previousPageData.data.current_page >= previousPageData.data.total_page) {
            return null
        }
        return `/v1/items?${query}&page=${index + 1}&version=${version}`
    }

    return useSWRInfinite(getKey, fetchGet, {use: [itemListMiddleware], revalidateFirstPage: false})
}

export async function getListByQuery(query, page = 1, version = 1) {
    let list = []
    let end = false
    let error = ""
    let lastListTime = 0
    let refreshTime = 0
    const nowTime = getSecondsTimestamp()
    if (page > 0) {
        query += `&page=${page}`
    }
    query += `&version=${version}`
    const cacheKey = `last_list_${query}`
    const cacheValue = JSON.parse(sessionStorage.getItem(cacheKey))
    const currentPage = page + 1

    if (cacheValue && cacheValue.refreshTime > nowTime && cacheValue.local_version === VERSION_LIST) {
        list = cacheValue.list
        end = cacheValue.currentPage === cacheValue.totalPage
        lastListTime = cacheValue.lastListTime
        refreshTime = cacheValue.refreshTime
        return {list, error, end, currentPage, lastListTime, refreshTime}
    }

    try {
        const res = await fetchGet(`/v1/items?${query}`)
        if (res.code === 200 && res?.data?.list?.length > 0) {
            list = JSON.parse(res.data.list)
            lastListTime = res.data.last_list_time
            refreshTime = res.data.next_refresh_time
            sessionStorage.setItem(cacheKey, JSON.stringify({
                list: list,
                refreshTime: res.data.next_refresh_time,
                lastListTime: res.data.last_list_time,
                currentPage: res.data.current_page,
                totalPage: res.data.total_page,
                local_version: VERSION_LIST,
            }))
            end = res.data.current_page >= res.data.total_page
        } else {
            end = true
        }
    } catch (e) {
        end = true;
        error = e
    }

    return {
        list,
        error,
        end,
        currentPage: (error ? page : currentPage),
        lastListTime: lastListTime,
        refreshTime: refreshTime
    }
}


// export async function getListByQuery(query, page) {
//     const cacheKey = `last_list_${query}`
//     let {ok, list, end, nextPage} = getLastListFromCache(cacheKey, page)
//
//     if (ok) {
//         return {list, error, end, nextPage}
//     }
//
//     if (cacheValue && cacheValue.refreshTime > nowTime && cacheValue.local_version === VERSION_LIST) {
//         list = cacheValue.list
//         end = cacheValue.currentPage === cacheValue.totalPage
//         return {list, error, end, nextPage}
//     }
//
//     const newQuery = query + `&page=${page}`
//     try {
//         const res = await fetchGet(`${ITEM_URL}?${query}`)
//         if (res.code === 200 && res?.data?.list?.length > 0) {
//             list = JSON.parse(res.data.list)
//             const dataToStore = {
//                 list: list,
//                 refreshTime: res.data.next_refresh_time,
//                 lastListTime: res.data.last_list_time,
//                 currentPage: res.data.current_page,
//                 totalPage: res.data.total_page,
//                 local_version: VERSION_LIST,
//                 api_version: res.data.version
//             }
//             sessionStorage.setItem(cacheKey, JSON.stringify(dataToStore))
//             end = res.data.current_page >= res.data.total_page
//         } else {
//             end = true
//         }
//     } catch (e) {
//         end = true;
//         error = e
//     }
//     return {list, error, end}
// }
//
// function getLastListFromCache(cacheKey, page) {
//     const nowTime = getSecondsTimestamp()
//
//     while (true) {
//
//     }
//
//     const cacheValue = JSON.parse(sessionStorage.getItem(cacheKey))
//     if (cacheValue) {
//         return cacheValue.list
//     }
//     return []
// }