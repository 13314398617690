import {getWebUrl} from "../../../utils";


const fileContent = `
## 所有文章
- [技术期刊收录的全部博主](${getWebUrl(`article/tech-blogger`)})
- [订阅教程](${getWebUrl(`article/follow-tutorial`)})（未完成）
- [服务协议](${getWebUrl(`article/service-agreement`)})
- [隐私协议](${getWebUrl(`article/privacy-agreement`)})
- [捐赠本站](${getWebUrl(`article/donate`)})
- [微信公众号](${getWebUrl(`article/wechat-mp`)})
- [会员介绍](${getWebUrl(`article/vip`)})
- [投放广告](${getWebUrl(`article/advertise`)})
- [安装到桌面（手机端）](${getWebUrl(`article/download-mobile`)})
- [安装到桌面（电脑端）](${getWebUrl(`article/download-pc`)})
- [将本站加入到广告屏蔽白名单](${getWebUrl(`article/ads-whitelist`)})

## 已归档的文章
- [热榜2.0说明](${getWebUrl(`article/version-100`)})
`;

export default fileContent;