// https://segmentfault.com/a/1190000014795596
export const timestampFormat = (timestamp) => {
    function zerofill(num) {
        return num.toString().padStart(2, "0")
    }

    let curTimestamp = getSecondsTimestamp() //当前时间戳
    let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

    let curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
    let tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

    let Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
    let H = tmDate.getHours(), i = tmDate.getMinutes();
    // let s = tmDate.getSeconds()

    if (timestampDiff < 60) { // 一分钟以内
        return "刚刚";
    } else if (timestampDiff < 3600) { // 一小时前之内
        return Math.floor(timestampDiff / 60) + "分钟前";
    } else if (curDate.getFullYear() === Y && curDate.getMonth() + 1 === m && curDate.getDate() === d) {
        return (curDate.getHours() - H) + "小时前"
        // return zerofill(H) + ':' + zerofill(i);
    } else {
        let newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
        if (newDate.getFullYear() === Y && newDate.getMonth() + 1 === m && newDate.getDate() === d) {
            return '昨天 ' + zerofill(H) + ':' + zerofill(i);
        } else if (curDate.getFullYear() === Y) {
            return Y + '-' + zerofill(m) + '-' + zerofill(d);
        } else {
            return Y + '-' + zerofill(m) + '-' + zerofill(d);
        }
    }
}

export const getSecondsTimestamp = () => {
    return Math.round(new Date().getTime() / 1000)
}

// 判断是否过期 过期-true  没过期-false
export const checkIsExpire = (time) => {
    return time < getSecondsTimestamp()
}

// 秒级时间戳 转 xx月xx日
export const timestampToDate1 = (time) => {
    let date = new Date(time * 1000)
    return `${(date.getMonth() + 1).toString().padStart(2, "0")} 月 ${date.getDate().toString().padStart(2, "0")} 日`
}

// 秒级时间戳 转 xxxx.xx.xx
export const timestampToDate2 = (time) => {
    let date = new Date(time * 1000)
    return `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getDate().toString().padStart(2, "0")}`
}

// 秒级时间戳 转 xx.xx xx:xx
export const timestampToDate3 = (time) => {
    let date = new Date(time * 1000)
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getDate().toString().padStart(2, "0")} ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`
}

export const getCurrentYear = () => {
    return new Date().getFullYear()
}