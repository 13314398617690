import React from "react";
import {Button, Result, Typography} from "@arco-design/web-react";

function Maintenance({reason}) {
    return (
        <div className="min-h-screen flex flex-col justify-between">
            <div className="md:py-10 grow flex flex-col h-full mx-auto">
                <div className="flex-grow flex items-center text-text-100 dark:text-textDark-100 bg-primary-200 dark:bg-primaryDark-200 md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden md:rounded-md shadow-sm w-screen md:w-[886px] xl:w-[1056px]">
                    <Result
                        status='403'
                        title={<span className="text-base">{reason !== "" ? `因 ${reason} 原因` : ""} 系统维护升级中</span>}
                        extra={<Button type='primary' onClick={() => {window.location.href = "/"}}>刷新</Button>}
                    >
                        <Typography
                            className='result-content'
                            style={{background: 'var(--color-fill-2)', padding: 24}}
                        >
                            <ul>
                                <li>可搜索公众号 <span className="text-myTheme-500 dark:text-myThemeDark-500">RebangToday</span> 查询升级进度</li>
                                {/*<li>管理员 <a className="underline text-blue-500" href="mailto:support@rebang.today">support@rebang.today</a>解决</li>*/}
                            </ul>
                        </Typography>
                    </Result>
                </div>
            </div>
        </div>
    );
}

export default Maintenance;
