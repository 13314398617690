import {fetchGet, fetchPost} from "../utils/fetch";

export function apiGetUserSetting() {
    return fetchGet("/v1/setting/get")
}

export function apiSendEmailCaptcha(params) {
    return fetchPost("/v1/user/register_login/email_captcha", params)
}

export function apiRegisterLoginByEmail(params) {
    return fetchPost("/v1/user/register_login/email", params)
}

export function apiUserAccountSetting(params) {
    return fetchGet("/v1/user/account_setting", params)
}

export function apiEditUserSetting(params) {
    return fetchPost("/v1/user/setting/edit", params)
}

export function apiUserLogout(params) {
    return fetchGet("/v1/user/logout", params)
}

export function apiActiveVip(params) {
    return fetchPost("/v1/vip/activate", params)
}