import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ALL_POSTS, ALL_POSTS_IDS} from "./posts";
import {useDispatch} from "react-redux";
import {useTitle} from "../../hooks";
import {setMobileHeader} from "../../redux/system";
import Header from "../../components/Header";
import {Breadcrumb} from "@arco-design/web-react";
import Footer from "../../components/Footer";
import ArticleRender from "./ArticleRender";
import Aside from "./Aside";

function Article() {
    const {id} = useParams();
    const [articleTitle, setArticleTitle] = useState("");
    const [articleContent, setArticleContent] = useState("");
    const [allArticlePage, setAllArticlePage] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useTitle(articleTitle)

    useEffect(() => {
        let articleId = id
        if (!articleId) {
            setAllArticlePage(true)
            articleId = ALL_POSTS_IDS.ALL_ARTICLES
        }
        if (ALL_POSTS.hasOwnProperty(articleId)) {
            setArticleTitle(ALL_POSTS[articleId].title);
            setArticleContent(ALL_POSTS[articleId].content);
            dispatch(setMobileHeader(ALL_POSTS[articleId].title))
        } else {
            setArticleTitle("文章不存在")
            setArticleContent("### 文章不存在")
            dispatch(setMobileHeader("文章不存在"))
        }
    }, [id])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="grow mx-auto">
                <div className="py-2 px-2 md:pt-0 md:px-0">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a className="cursor-pointer" onClick={() => navigate("/")}>首页</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {allArticlePage && <span>文章</span>}
                            {!allArticlePage && <a className="cursor-pointer" onClick={() => navigate("/article")}>文章</a>}
                        </Breadcrumb.Item>
                        {!allArticlePage && <Breadcrumb.Item>{articleTitle}</Breadcrumb.Item>}
                    </Breadcrumb>
                </div>
                <div className="flex">
                    <ArticleRender content={articleContent}/>
                    <Aside content={articleContent}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Article;