import {Button, Result} from "@arco-design/web-react";
import {IconFaceSmileFill} from "@arco-design/web-react/icon";

function UpdatePage() {
    async function handleRefresh() {
        if (window.caches) {
            const cacheNames = await window.caches.keys();
            await Promise.all(cacheNames.map((cacheName) => window.caches.delete(cacheName)));
        }
        // 强制刷新页面
        window.location.reload();
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <div className="md:py-10 grow flex flex-col h-full mx-auto">
                <div className="flex-grow flex items-center text-text-100 dark:text-textDark-100 bg-primary-200 dark:bg-primaryDark-200 md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden md:rounded-md shadow-sm w-screen md:w-[886px] xl:w-[1056px]">
                    <Result
                        status={null}
                        icon={<IconFaceSmileFill style={{ color: 'rgb(var(--arcoblue-6))' }} />}
                        title={<span className="text-base">已自动更新至最新版本</span>}
                        extra={<Button type='primary' onClick={handleRefresh}>继续</Button>}
                    >
                    </Result>
                </div>
            </div>
        </div>
    )

}

export default UpdatePage;
