import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import "../../assets/css/github-markdown.css"
import Ads from "../../components/Ads";

function LoginRender({content}) {

    return (
        <div className="grow shrink flex justify-center">
            <div className="w-screen md:w-[600px] xl:w-[770px]">
                <div
                    className="px-5 md:px-10 py-4 md:py-8 bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden mb-2 md:rounded-md shadow-sm">
                    <div className="markdown-body" id="markdown-body">
                        <ReactMarkdown linkTarget="_self" remarkPlugins={[gfm]} children={content}/>
                    </div>
                </div>
                {/*广告 开始*/}
                <Ads type={2}/>
                {/*广告 结束*/}
            </div>
        </div>
    );
}

export default LoginRender;
