import {Suspense, useState} from "react";
import SubTabLoading from "./Loading/SubTabLoading";
import ListLoading from "./Loading/ListLoading";
import 'swiper/css';

function PcRender({allTabs, activeTabKey, listShowType, setSubObj, subObj, allComponent, defaultComponent}) {
    const Component = allComponent[activeTabKey] ? allComponent[activeTabKey] : defaultComponent
    const realSubObj = subObj?.tabKey === activeTabKey ? subObj?.obj : {}

    function realSetSubObj(obj) {
        setSubObj({tabKey: activeTabKey, obj})
    }

    return <Suspense fallback={<><SubTabLoading/><ListLoading/></>}>
        <Component allTabs={allTabs} listShowType={listShowType} setSubObj={realSetSubObj} subObj={realSubObj}/>
    </Suspense>
}

export default PcRender;
