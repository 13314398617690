import {THEME_ENUM} from "../constants/system";

export const loadColorTheme = (mode) => {
    if (checkIsDark(mode)) {
        document.documentElement.classList.add("dark");
        document.body.setAttribute('arco-theme', 'dark');
        document.body.style.backgroundColor = "#0D1117"
        document.querySelector('head meta[name="theme-color"]').content = "#161B22";
    } else {
        document.documentElement.classList.remove("dark");
        document.body.removeAttribute('arco-theme');
        document.body.style.backgroundColor = "#fff"
        document.querySelector('head meta[name="theme-color"]').content = "#fff";
    }
};

export const checkIsDark = (mode) => {
    return mode === THEME_ENUM.DARK || (mode === THEME_ENUM.AUTO && window.matchMedia("(prefers-color-scheme: dark)").matches);
}