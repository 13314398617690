import React, {useEffect, useState} from 'react';
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors, MouseSensor, TouchSensor, DragOverlay, defaultDropAnimationSideEffects, MeasuringStrategy,
} from '@dnd-kit/core';
import {
    SortableContext,
    useSortable, rectSortingStrategy,
} from '@dnd-kit/sortable';
import {createPortal} from "react-dom";
import {Badge} from "@arco-design/web-react";
import {IconDragDotVertical, IconMinusCircle} from "@arco-design/web-react/icon";
import {getCDNUrl} from "../../utils";
import classNames from "classnames";
import {LIST_SHOW_ENUM} from "../../constants/setting";

function SortableGridList({items, onChangeFollowItem, listShowType}) {
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
    );
    const [activeId, setActiveId] = useState(null);
    const getIndex = (id) => items.findIndex((item) => item.id === id);
    const activeIndex = activeId ? getIndex(activeId) : -1;

    function arrayMove(array, from, to) {
        const newArray = [...array];
        const startIndex = to < 0 ? array.length + to : to;
        const item = newArray.splice(from, 1)[0];
        newArray.splice(startIndex, 0, item);
        return newArray;
    }

    const handleDragEnd = ({over}) => {
        setActiveId(null);
        if (over) {
            const overIndex = getIndex(over.id);
            if (activeIndex !== overIndex) {
                onChangeFollowItem(arrayMove(items, activeIndex, overIndex))
                // setSortedItems((items) => arrayMove(items, activeIndex, overIndex))
            }
        }
    };

    function onRemoveItem(tabInfo) {
        onChangeFollowItem(items.filter(item => item.id !== tabInfo.id))
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({active}) => {
                if (!active) {
                    return;
                }
                setActiveId(active.id);
            }}
            onDragEnd={handleDragEnd}
            measuring={{droppable: {strategy: MeasuringStrategy.Always}}}
        >
            <SortableContext
                items={items.map(({id}) => id)}
                strategy={rectSortingStrategy}
            >
                <div className="grid gap-3 grid-cols-3 md:grid-cols-4 xl:grid-cols-5">
                    {items.map((item, index) => {
                        return <SortableItem key={item.key} tabInfo={item} dragOverlay={false} onRemoveItem={onRemoveItem} listShowType={listShowType}/>
                    })}
                </div>
            </SortableContext>
            {createPortal(
                <DragOverlay
                    adjustScale={false}
                    dropAnimation={{sideEffects: defaultDropAnimationSideEffects({styles: {active: {opacity: '0.5'}}})}}
                >
                    {activeId ? <SortableItem tabInfo={items[activeIndex]} dragOverlay={true} onRemoveItem={null} listShowType={listShowType}/> : null}
                </DragOverlay>,
                document.body
            )}
        </DndContext>
    );
}

function SortableItem({tabInfo, dragOverlay, onRemoveItem, listShowType}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({id: tabInfo.id});
    const style = {
        transform: transform
            ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
            : undefined,
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className="flex"
        >
            <Badge onClick={() => {onRemoveItem && onRemoveItem(tabInfo)}} className="w-full" count={<IconMinusCircle className="text-gray-500 dark:text-gray-400"/>}>
                <div className="bg-primary-300 text-text-200 dark:text-textDark-200 dark:bg-[#343435] flex-shrink-0 font-semibold flex items-center h-8 rounded-lg select-none">
                    <div {...listeners} className={classNames("pl-1 pr-0.5 md:pr-1 flex h-full items-center", {
                        "cursor-grabbing": dragOverlay,
                        "cursor-grab": !dragOverlay
                    })}>
                        <IconDragDotVertical/>
                    </div>
                    <span className="flex items-center">
                        {listShowType === LIST_SHOW_ENUM.COMPLETE && tabInfo.avatar !== "" && <img
                            className="mr-1 w-4 h-4 rounded-sm object-contain select-none pointer-events-none dark:hidden"
                            src={getCDNUrl(tabInfo.avatar)} alt=""
                            style={{WebkitTouchCallout: 'none'}}
                        />}
                        {listShowType === LIST_SHOW_ENUM.COMPLETE && tabInfo.avatar_dark !== "" && <img
                            className="mr-1 w-4 h-4 rounded-sm object-contain select-none pointer-events-none hidden dark:block"
                            src={getCDNUrl(tabInfo.avatar_dark)} alt=""
                            style={{WebkitTouchCallout: 'none'}}
                        />}
                        <div className="flex-shrink-0 text-sm">
                            {tabInfo.name}
                        </div>
                    </span>
                </div>
            </Badge>
        </div>
    );
}

export default SortableGridList;
