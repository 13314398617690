import {IconNotification} from "@arco-design/web-react/icon";
import {Alert} from "@arco-design/web-react";
import {useDispatch, useSelector} from "react-redux";
import {getNotifyInfo, setReadNotifyId} from "../../redux/system";
import parse from 'html-react-parser';

function Notification() {
    const dispatch = useDispatch()
    const notifyInfo = useSelector(getNotifyInfo)

    return <>
        {Object.keys(notifyInfo).length > 0
            && <div
                className="border border-border-100 dark:border-borderDark-100 bg-primary-200 dark:bg-primaryDark-200 rounded-md shadow-sm">
                <Alert
                    closable={true}
                    title={notifyInfo.title}
                    type={notifyInfo.level}
                    content={parse(notifyInfo.content)}
                    className="rounded-md bg-primary-200 dark:bg-primaryDark-200"
                    icon={<IconNotification/>}
                    onClose={() => {
                        dispatch(setReadNotifyId(notifyInfo.id))
                    }}
                />
            </div>
        }
    </>
}

export default Notification;
