// import {ACCESS_TOKEN_KEY} from "../../constants/localStorage";
// import {apiUserAccountSetting} from "../../apis/user";
// import {initUserAccount, initUserSetting} from "../../redux/user";
// import {store} from '../../redux';
import {Button, Result, Typography} from "@arco-design/web-react";

function ErrorPage() {
    async function handleRefresh() {
        // 清除浏览器缓存
        if (window.caches) {
            const cacheNames = await window.caches.keys();
            await Promise.all(cacheNames.map((cacheName) => window.caches.delete(cacheName)));
        }

        // 删除所有localstorage 除了accountToken
        // const accountToken = localStorage.getItem(ACCESS_TOKEN_KEY)

        // window.localStorage.clear();
        // 删除所有sessionStorage
        window.sessionStorage.clear();

        // if (accountToken) {
        //     localStorage.setItem(ACCESS_TOKEN_KEY, accountToken)
        //     // 更新user_account 和 user_setting
        //     const res = await apiUserAccountSetting({account_update_flag: "", setting_update_flag: ""})
        //     if (res.code === 200) {
        //         if (res.data.user_account) {
        //             store.dispatch(initUserAccount(res.data.user_account))
        //         }
        //         if (res.data.user_setting) {
        //             store.dispatch(initUserSetting(res.data.user_setting))
        //         }
        //     }
        // }
        // 强制刷新页面
        window.location.reload();
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <div className="md:py-10 grow flex flex-col h-full mx-auto">
                <div className="flex-grow flex items-center text-text-100 dark:text-textDark-100 bg-primary-200 dark:bg-primaryDark-200 md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden md:rounded-md shadow-sm w-screen md:w-[886px] xl:w-[1056px]">
                    <Result
                        status='500'
                        title={<span className="text-base">抱歉，出现了一个错误</span>}
                        extra={<Button type='primary' onClick={handleRefresh}>刷新</Button>}
                    >
                        <Typography
                            className='result-content'
                            style={{background: 'var(--color-fill-2)', padding: 24}}
                        >
                            <Typography.Paragraph>解决办法:</Typography.Paragraph>
                            <ul>
                                <li>点击刷新页面，将尝试清除缓存，并重新加载页面</li>
                                <li>如果频繁出现，请检查是否有浏览器插件导致产生冲突</li>
                                <li>如果无法解决，请通过搜索公众号 <span className="text-myTheme-500 dark:text-myThemeDark-500">RebangToday</span> 反馈，帮您解决</li>
                                {/*<li>管理员 <a className="underline text-blue-500" href="mailto:support@rebang.today">support@rebang.today</a>解决</li>*/}
                            </ul>
                        </Typography>
                    </Result>
                </div>
            </div>
        </div>
    )
    // return <div className="min-h-screen flex items-center justify-center bg-white md:bg-gray-100">
    //     <div className="bg-white p-8 rounded-lg md:shadow-md">
    //         <h1 className="text-2xl font-semibold text-gray-700 mb-4">抱歉，出现了一个错误</h1>
    //         <p className="text-gray-500 mb-2">点击刷新页面，将尝试清除缓存，可能会导致设置失效</p>
    //         <p className="text-gray-500 mb-6">如果频繁出现，请联系管理员 <a className="underline text-blue-500" href="mailto:support@rebang.today">support@rebang.today</a></p>
    //         <button
    //             className="bg-blue-500 text-white font-semibold px-4 py-2 rounded"
    //             onClick={handleRefresh}
    //         >
    //             刷新页面
    //         </button>
    //     </div>
    // </div>
}

export default ErrorPage;
