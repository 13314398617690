import React, {useEffect, useState} from 'react';
import Item from "../../components/Item";
import Tab from "../../components/Tab";
import {useDispatch, useSelector} from "react-redux";
import {getActiveTabKey, setActiveTabKey} from "../../redux/tab";
import {useNavigate} from "react-router-dom";
import {getIsLoggedIn, getUserSetting, initUserSetting} from "../../redux/user";
import {Button, Result} from "@arco-design/web-react";
import TabEditor from "../../components/TabEditor";
import {apiEditUserSetting} from "../../apis/user";
import {SETTING_KEY} from "../../constants/setting";
import {useSettingListShow} from "../../hooks/setting";
import {getFollowTabLimit} from "../../redux/system";
import Ads from "../../components/Ads";
import {useViewportComplete} from "../../hooks";
import {widthGTAMd} from "../../utils";

function MainUser() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userSetting = useSelector(getUserSetting)
    const isLoggedIn = useSelector(getIsLoggedIn)
    const followTabInfo = userSetting.follow_tab_info || []
    const [showTabEditor, setShowTabEditor] = useState(false)
    const listShowType = useSettingListShow(userSetting)
    const followTabLimit = useSelector(getFollowTabLimit)
    const [emptyHeight, setEmptyHeight] = useState(0)
    const {width, height} = useViewportComplete()
    // tab数据
    const activeTabKey = useSelector(getActiveTabKey)

    useEffect(() => {
        if (followTabInfo.length > 0) {
            if (activeTabKey === "" || !followTabInfo.some(obj => obj.hasOwnProperty("key") && obj["key"] === activeTabKey)) {
                dispatch(setActiveTabKey(followTabInfo[0].key))
            }
        } else {
            dispatch(setActiveTabKey(""))
        }
    }, [activeTabKey, followTabInfo])

    useEffect(() => {
        setEmptyHeight(widthGTAMd(width) ? height - 120 : height - 230)
    }, [width, height])

    function onChangeTab(key) {
        dispatch(setActiveTabKey(key))
        navigate(`?tab=${key}`, {replace: true})
    }

    async function onChangeFollowTab(type, value) {
        if (type === "add" && value.length > followTabLimit) {
            throw new Error(`当前会员等级最多订阅${followTabLimit}个节点`)
        }
        await apiEditUserSetting({filed: SETTING_KEY.FOLLOW_TAB, value: JSON.stringify(value)}).then((res) => {
            if (res.code === 200) {
                dispatch(initUserSetting(res.data))
            } else {
                throw new Error("修改失败")
            }
        })
    }

    return (
        <>
            <main className="w-screen md:w-[600px] xl:w-[770px] relative">
                {showTabEditor &&
                    <TabEditor followTabInfo={followTabInfo} close={() => {
                        setShowTabEditor(false)
                    }} listShowType={listShowType}/>}
                {!showTabEditor && <>
                    {!isLoggedIn && <div
                        style={{height: emptyHeight}}
                        className="mt-5 md:mt-0 w-full bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-border-100 dark:border-borderDark-100 overflow-hidden mb-5 md:rounded-md shadow-sm">
                        <div className="w-full h-full space-y-3 flex flex-col justify-center items-center">
                            <Result
                                status='403'
                                subTitle='订阅你喜欢的内容，请登录后使用'
                                extra={<Button type='primary' onClick={() => {
                                    navigate("/signin")
                                }}>去登录</Button>}/>
                        </div>
                    </div>}
                    {isLoggedIn && followTabInfo.length === 0 && <div
                        style={{height: emptyHeight}}
                        className="mt-5 md:mt-0 w-full bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-border-100 dark:border-borderDark-100 overflow-hidden mb-5 md:rounded-md shadow-sm">
                        <div className="w-full h-full space-y-3 flex flex-col justify-center items-center">
                            <Result
                                status='404'
                                subTitle='订阅列表为空'
                                extra={<Button type='primary'
                                               onClick={() => setShowTabEditor(true)}>添加订阅</Button>}/>
                        </div>
                    </div>}
                    {isLoggedIn && followTabInfo.length > 0 && <>
                        {/*导航栏开始 #e5e7eb*/}
                        <Tab activeTabKey={activeTabKey}
                             onChangeTab={onChangeTab}
                             allTabs={followTabInfo}
                             userSetting={userSetting}
                             isFollowPage={true}
                             onShowEditTab={() => setShowTabEditor(true)}
                             onChangeFollowTab={onChangeFollowTab}
                        />
                        {/*导航栏结束*/}
                        {/*item 开始*/}
                        <div
                            className="bg-primary-200 dark:bg-primaryDark-200 border-t border-b-0 md:border border-border-100 dark:border-borderDark-100 overflow-hidden mb-5 md:rounded-md shadow-sm">
                            <Item activeTabKey={activeTabKey} onChangeTab={onChangeTab} allTabs={followTabInfo}
                                  userSetting={userSetting}/>
                        </div>
                        {/*item 结束*/}
                    </>}
                </>}
                {/*广告 开始*/}
                <Ads type={2}/>
                {/*广告 结束*/}
            </main>
        </>
    );
}

export default MainUser;
