const fileContent = `# 今日热榜服务协议

欢迎使用今日热榜网站（以下简称“本站”）。本站提供的服务需要遵守以下条款和条件。请您在使用本站之前仔细阅读本协议。如果您不同意本协议的任何条款，请勿使用本站。

## 1. 服务内容

1.1 本站提供的服务包括但不限于：今日热榜、热搜榜、热点新闻等信息聚合和展示服务。

1.2 本站所有信息的来源均来自公开的互联网信息，本站仅是一个信息聚合和展示平台，不对信息的真实性、准确性和完整性负责。

1.3 本站仅提供信息展示服务，不对信息的发布者和信息的使用者进行任何形式的审查和监管。任何人发布的信息和行为均与本站无关。

## 2. 使用规则

2.1 您必须遵守中国法律法规和相关规定，不得利用本站进行任何违法活动。

2.2 您不得利用本站进行任何影响本站正常运营的行为，包括但不限于：破坏、攻击、篡改本站的信息和系统，以及利用本站进行恶意营销等行为。

2.3 您应当妥善保管自己的账号和密码，并对使用该账号和密码进行的任何行为负责。

2.4 您应当遵守本站的所有其他规定和约定。

## 3. 免责声明

3.1 本站不对信息的真实性、准确性和完整性负责，不承担因信息使用或信赖而产生的任何风险和责任。

3.2 本站不对由于系统故障、意外事件或其他不可抗力因素导致的服务中断和数据丢失承担任何责任。

## 4. 知识产权

4.1 本站所有信息均来自公开的互联网信息，不涉及任何知识产权问题。

4.2 任何人不得利用本站的信息和服务侵犯他人的知识产权，如有侵权行为，责任自负。

## 5. 协议修改和终止

5.1 本站有权随时修改本协议的任何条款和条件，修改后的协议一旦公布即代替原来的协议。您可随时查阅最新版协议。

5.2 本站有权在任何时间终止本站的服务，无需事先通知用户，用户在使用本站服务时应当注意本站的公告和提示。

## 6. 法律适用和争议解决

6.1 本协议适用中华人民共和国法律法规的规定。

6.2 本协议任何条款无论因何种原因完全或部分无效或不可执行，不影响本协议其他条款的效力。

6.3 因本协议产生的任何争议应当通过友好协商解决，协商不成的，任何一方均可向本站所在地人民法院提起诉讼。

## 7. 其他

7.1 本协议构成用户和本站之间的完整协议，取代用户和本站之间有关服务的所有先前协议和合同。

7.2 本站对本协议享有最终解释权和修改权。

7.3 本协议中的标题仅为方便阅读而设定，不影响协议的含义和解释。

以上就是今日热榜网站的服务协议，感谢您的阅读。如果您有任何疑问或意见，欢迎随时联系我们。`;

export default fileContent;