import Notification from "../../components/Notification";
import Ads from "../../components/Ads";

function Aside() {
    return (
        <aside className="flex flex-col items-stretch h-full space-y-5">
            <Notification/>
            <Ads type={1}/>
        </aside>
    );
}

export default Aside;
