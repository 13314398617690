import {BLOGGERS} from "../../../constants/blogger";

let str = ""
Object.entries(BLOGGERS).forEach(([, blogger]) => {
    str += `- [${blogger.name}](${blogger.www})`
    if (blogger.slogan) {
        str +=  ` —— ${blogger.slogan}`
    }
    str += `\n`
});

const fileContent = `## 技术期刊收录的全部博主

> 排名不分先后，按照收录时间排序

${str}
`;

export default fileContent;