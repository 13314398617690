import React, {useEffect, useState} from "react";

function SubTabLoading() {
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setShowLoading(true), 300)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <div className="animate-pulse">
            {/*subTab开始*/}
            {showLoading && <div className="w-full flex h-10 border-b border-solid border-border-100 dark:border-borderDark-100 px-4 py-2 justify-between">
                <div className="h-full flex">
                    <div className="w-12 h-full bg-loading-100 dark:bg-loadingDark-100 rounded mr-2"></div>
                    <div className="w-12 h-full bg-loading-100 dark:bg-loadingDark-100 rounded mr-2"></div>
                    <div className="hidden md:block w-12 h-full bg-loading-100 dark:bg-loadingDark-100 rounded mr-2"></div>
                    <div className="hidden md:block w-12 h-full bg-loading-100 dark:bg-loadingDark-100 rounded mr-2"></div>
                    <div className="hidden md:block w-12 h-full bg-loading-100 dark:bg-loadingDark-100 rounded mr-2"></div>
                    <div className="hidden md:block w-12 h-full bg-loading-100 dark:bg-loadingDark-100 rounded mr-2"></div>
                </div>
                <div className="h-full rounded flex space-x-2">
                    <div className="bg-loading-100 dark:bg-loadingDark-100 w-20 h-full rounded"></div>
                    <div className="bg-loading-100 dark:bg-loadingDark-100 w-20 h-full rounded"></div>
                </div>
            </div>}
            {/*subTab结束*/}
        </div>
    )
}

export default SubTabLoading