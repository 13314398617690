import Notification from "../../components/Notification";
import Ads from "../../components/Ads";

function Aside() {
    return (
        <aside className="ml-4 hidden asideSize:block w-[270px]">
            <div className="flex flex-col items-stretch h-full space-y-5">
                <Notification/>
                <Ads type={1}/>
            </div>
        </aside>
    );
}

export default Aside;
