import Loading from "./Loading";
import {LIST_SHOW_ENUM, TAB_SHOW_ENUM} from "../../constants/setting";
import React, {useEffect, useRef, useState} from "react";
import {useViewport} from "../../hooks";
import {getCDNUrl, widthGTAMd} from "../../utils";
import classNames from "classnames";
import {Dropdown, Menu, Message} from "@arco-design/web-react";
import {IconMinusCircle, IconPlusCircle} from "@arco-design/web-react/icon";
import {useSettingListShow, useSettingTabShow} from "../../hooks/setting";
import {useDispatch, useSelector} from "react-redux";
import {getTabFoldedState, setTabFoldedState} from "../../redux/system";
import {getIsLoggedIn} from "../../redux/user";

function Tab({allTabs, activeTabKey, onChangeTab, userSetting, isFollowPage, onShowEditTab, onChangeFollowTab}) {
    const tabRef = useRef()
    const tabCurrentRef = useRef()
    const foldBtnRef = useRef()
    const width = useViewport()
    const widthShow = widthGTAMd(width)
    const dispatch = useDispatch()
    const tabShowType = useSettingTabShow(userSetting)
    const listShowType = useSettingListShow(userSetting)
    const foldStatus = useSelector(getTabFoldedState) // 折叠状态 true-折叠 false-展开
    const isLoggedIn = useSelector(getIsLoggedIn)
    const [showFoldBtn, setShowFoldBtn] = useState(false) // 当状态是折叠时，是否显示折叠展开按钮（当长度不需要展开按钮时，不显示）
    const followTab = JSON.parse(userSetting.follow_tab || "[]")
    const [mobileSticky, setMobileSticky] = useState(false) // 当是移动端时，是否固定

    useEffect(() => {
        if (tabRef.current && tabShowType === TAB_SHOW_ENUM.FOLD) {
            if (foldStatus) {
                setShowFoldBtn(tabRef.current.scrollWidth > tabRef.current.clientWidth + 20)
            } else {
                const children = tabRef.current.children;
                const firstChild = children[0];
                let lastChild = children[children.length - 1];
                if (foldBtnRef.current) { // 有收起按钮
                    lastChild = children[children.length - 2];
                }
                setShowFoldBtn(firstChild && lastChild && lastChild.offsetTop !== firstChild.offsetTop)
            }
        }
    }, [foldStatus, tabRef.current, allTabs, foldBtnRef.current])

    /** 这块的逻辑慎改。最好想办法重构。我都有点看不懂了 */
    useEffect(() => {
        if (tabRef.current) {
            const tabHeight = tabRef.current.clientHeight
            const newMobileSticky = tabHeight < 150
            const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop || 0;
            if (!newMobileSticky && scrollPosition < 50) {
                setMobileSticky(newMobileSticky)
            }
            if (newMobileSticky) {
                setMobileSticky(newMobileSticky)
            }
        }
    }, [foldStatus, tabRef.current, allTabs, foldBtnRef.current])
    useEffect(() => {
        if (tabRef.current) {
            const tabHeight = tabRef.current.clientHeight
            const newMobileSticky = tabHeight < 150
            setMobileSticky(newMobileSticky)
        }
    }, [activeTabKey])
    /** 这块的逻辑慎改。最好想办法重构。我都有点看不懂了 */

    // 更改tab时
    useEffect(() => {
        if (tabCurrentRef.current && tabRef.current) {
            const left = widthShow
                ? tabCurrentRef.current.offsetLeft - (tabRef.current.parentNode.clientWidth / 2) + 50
                : tabCurrentRef.current.offsetLeft - (tabRef.current.parentNode.clientWidth / 3) + 50
            setTimeout(() => {
                tabRef.current.scrollTo({left: left, behavior: 'smooth'})
            }, 1)
        }
    }, [foldStatus, activeTabKey, tabCurrentRef.current, tabRef.current])

    function onAddFollowTab(tabKey) {
        const newFollowTab = [...followTab, tabKey];
        // 请求api
        onChangeFollowTab("add", newFollowTab).then(() => {
            Message.success({content:"同步云端成功", duration: 1000})
        }).catch((e) => {
            Message.error(e.message)
        })
    }

    function onRemoveFollowTab(tabKey) {
        const newFollowTab = followTab.filter(fruit => fruit !== tabKey);
        // 请求api
        onChangeFollowTab("remove", newFollowTab).then(() => {
            Message.success({content:"同步云端成功", duration: 1000})
        }).catch((e) => {
            Message.error(e.message)
        })
    }

    const handleWheel = (event) => {
        event.preventDefault();
        event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
    };

    // 添加这个 useEffect 钩子
    useEffect(() => {
        if (tabRef.current && showFoldBtn && foldStatus) {
            tabRef.current?.addEventListener('wheel', handleWheel, { passive: false });
            return () => {
                tabRef.current?.removeEventListener('wheel', handleWheel);
            };
        } else {
            // 移除事件监听
            tabRef.current?.removeEventListener('wheel', handleWheel);
        }
    }, [showFoldBtn, foldStatus, tabRef.current]);

    return (
        <div className={`md:static top-[51px] z-40 ${mobileSticky ? "sticky" : ""}`}>
            {allTabs.length === 0 && <Loading/>}
            {allTabs.length > 0 && <div
                className="flex border-b md:border mb-2 md:pt-2 pb-2 md:rounded-md bg-primary-200 dark:bg-primaryDark-200 border-border-100 dark:border-borderDark-100 px-3 shadow-sm">
                <div ref={tabRef}
                     className={classNames({"flex-wrap": tabShowType === TAB_SHOW_ENUM.UNFOLD || (tabShowType === TAB_SHOW_ENUM.FOLD && !foldStatus)}, "gap-1 md:gap-2 flex overflow-x-auto no-scrollbar")}>
                    {allTabs.map((item, index) => {
                        return (
                            <Dropdown
                                key={item.key + index}
                                trigger='contextMenu'
                                position='bl'
                                disabled={!isLoggedIn}
                                droplist={
                                    <Menu>
                                        {!followTab.includes(item.key) && <Menu.Item
                                            onClick={() => onAddFollowTab(item.key)}
                                            key='1'
                                        >
                                            <IconPlusCircle
                                                className="translate-y-px text-base mr-1 text-green-500 dark:text-green-600"/>
                                            订阅
                                        </Menu.Item>}
                                        {followTab.includes(item.key) && <Menu.Item
                                            key='2'
                                            onClick={() => onRemoveFollowTab(item.key)}
                                        >
                                            <IconMinusCircle
                                                className="translate-y-px text-base mr-1 text-gray-500 dark:text-gray-400"/>
                                            取消订阅
                                        </Menu.Item>}
                                    </Menu>
                                }
                            >
                                <div
                                     onClick={() => {
                                         onChangeTab(item.key)
                                     }}
                                     ref={item.key === activeTabKey ? tabCurrentRef : null}
                                     className={classNames({
                                             "bg-primary-300 text-myTheme-600 dark:bg-[#343435] dark:text-white": item.key === activeTabKey,
                                             "md:hover:bg-primary-300 md:dark:hover:bg-[#343435] text-text-200 dark:text-textDark-200": item.key !== activeTabKey,
                                             "mr-2": index === allTabs.length - 1 && !isFollowPage,
                                         },
                                         "flex-shrink-0 font-semibold flex items-center tab-animation px-2 h-8 text-sm rounded-lg select-none cursor-pointer")
                                     }>
                                    <span className="flex items-center">
                                        {listShowType === LIST_SHOW_ENUM.COMPLETE && item.avatar !== "" &&
                                            <img className="mr-1 w-4 h-4 rounded-sm object-contain select-none pointer-events-none dark:hidden"
                                                 src={getCDNUrl(item.avatar)} alt=""
                                                 onTouchStart={(event) => {
                                                     event.preventDefault()
                                                 }}
                                                 style={{WebkitTouchCallout: 'none'}}
                                            />}
                                        {listShowType === LIST_SHOW_ENUM.COMPLETE && item.avatar_dark !== "" &&
                                            <img className="mr-1 w-4 h-4 rounded-sm object-contain select-none pointer-events-none hidden dark:block"
                                                 src={getCDNUrl(item.avatar_dark)} alt=""
                                                 style={{WebkitTouchCallout: 'none'}}
                                            />}
                                        <div className="flex-shrink-0">
                                            {item.name}
                                        </div>
                                    </span>
                                </div>
                            </Dropdown>
                        )
                    })}
                    {isFollowPage && onShowEditTab !== null && <div
                        onClick={onShowEditTab}
                        className="md:hover:bg-primary-300 md:dark:hover:bg-[#343435] text-text-200 dark:text-textDark-200 border border-border-100 dark:border-borderDark-100 border-dashed flex-shrink-0 font-semibold flex items-center tab-animation mr-2 px-2 h-8 text-sm rounded-lg select-none cursor-pointer">
                        <IconPlusCircle className="mr-1 w-4 h-4 object-contain"/>
                        <div className="flex-shrink-0">
                            编辑
                        </div>
                    </div>}
                    {showFoldBtn && !foldStatus && <div
                        ref={foldBtnRef}
                        onClick={() => dispatch(setTabFoldedState(true))}
                        className="min-h-[32px] pl-2 pr-0.5 ml-auto rounded-lg bg-primary-300 hover:bg-gray-200 active:bg-gray-300 dark:bg-primaryDark-400 dark:hover:bg-[#414142] dark:active:bg-[#5A5A5B] text-text-300 dark:text-textDark-300 select-none cursor-pointer flex flex-shrink-0 items-center"
                    >
                        收起
                        <svg className="rotate-180 fill-current" width="24" height="24" viewBox="0 0 24 24"
                             data-new-api="ArrowDownSmall24" data-old-api="ArrowDown" fill="currentColor">
                            <path
                                d="M12 13.248L8.22 9.223a.684.684 0 00-1.01 0 .796.796 0 000 1.075l4.15 4.42a.867.867 0 001.28 0l4.15-4.42a.796.796 0 000-1.075.684.684 0 00-1.01 0L12 13.248z"
                                fillRule="evenodd" clipRule="evenodd"></path>
                        </svg>
                    </div>}
                </div>

                {/*展开按钮*/}
                {showFoldBtn && foldStatus && <div onClick={() => dispatch(setTabFoldedState(false))}
                                                   className="rounded-lg bg-primary-300 hover:bg-gray-200 active:bg-gray-300 dark:bg-primaryDark-400 dark:hover:bg-[#414142] dark:active:bg-[#5A5A5B] select-none cursor-pointer flex flex-shrink-0 items-center text-text-300 dark:text-textDark-300 pl-2 pr-0.5">
                    展开
                    <svg className="mt-0.5 fill-current" width="24" height="24" viewBox="0 0 24 24"
                         data-new-api="ArrowDownSmall24" data-old-api="ArrowDown">
                        <path
                            d="M12 13.248L8.22 9.223a.684.684 0 00-1.01 0 .796.796 0 000 1.075l4.15 4.42a.867.867 0 001.28 0l4.15-4.42a.796.796 0 000-1.075.684.684 0 00-1.01 0L12 13.248z"
                            fillRule="evenodd" clipRule="evenodd"></path>
                    </svg>
                </div>}
            </div>}
        </div>
    )
}

export default Tab;
