import {fetchGet, sendBeacon} from "../utils/fetch";

// export async function getVersion() {
//     return fetch(`${VERSION_URL}`, {
//         method: "GET",
//     }).then((res) => res.json());
// }


export function apiGetRegLimit() {
    return fetchGet(`/v1/user/register/limit`)
}

export function apiGetSystemInfo(params) {
    return fetchGet(`/v1/system/info`,params)
}

// 上报错误事件
export function reportError(data) {
    sendBeacon("/v1/report/error/front", data)
}

// 上报统计数据
export function reportCount(eventType) {
    sendBeacon("/v1/report/count", {event_type : eventType})
}