import {getCDNUrl, getWebUrl} from "../../../utils";

const fileContent = `# 安装到桌面（电脑端）

> 本篇文章为**电脑端**的安装教程，**手机端**请[点击跳转](${getWebUrl("article/download-mobile")})查看

目前电脑端对 PWA 支持较好的浏览器有： [Google Chrome](https://www.google.cn/chrome/)、[Microsoft Edge](https://www.microsoft.com/edge) 等，其他浏览器可能并不支持（点名批评Safari）。其他国产浏览器（如360、QQ等），因品类太多需自行尝试

## 下面是具体步骤

1. 使用上述**支持PWA的浏览器**打开官方网址：[https://rebang.today](https://rebang.today)
2. 点击地址栏右侧的**安装**按钮
3. 点击确认添加后即可在桌面上找到图标了
4. 如果地址栏中未找到安装按钮，可以尝试在地址栏右侧点击**更多**按钮，然后选择**安装**即可

> 请注意：系统不同，操作方式大同小异。如果您遇到了问题，可以在[反馈页面](https://support.qq.com/products/434778)中提交反馈，我们会尽快解决

## 下面是一些示例截图

### Google Chrome
![chrome_step1](${getCDNUrl("forever/article/download/chrome_step1.jpg")})

![chrome_step2](${getCDNUrl("forever/article/download/chrome_step2.jpg")})

### Microsoft Edge
![edge_step1](${getCDNUrl("forever/article/download/edge_step1.jpg")})

![edge_step2](${getCDNUrl("forever/article/download/edge_step2.jpg")})
`;

export default fileContent;