import React, {useEffect} from 'react';
import {useViewport} from "../../hooks";
import {widthGTAMd} from "../../utils";
import PcRender from "./PcRender";
import MobileRender from "./MobileRender";
import {useDispatch, useSelector} from "react-redux";
import {getIsLoggedIn, getUserAccount, removeUserAll} from "../../redux/user";
import {useNavigate} from "react-router-dom";
import {getActiveMenuKey, getAllMenuTab, setActiveMenuKey, setActiveTabKey} from "../../redux/tab";
import {MENU_FOLLOWING_NAME, MENU_FOLLOWING_PATH, MENU_KEY} from "../../constants/menu";
import {setMobileHeader} from "../../redux/system";
import {apiUserLogout} from "../../apis/user";

function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isLoggedIn = useSelector(getIsLoggedIn)
    const menuKey = useSelector(getActiveMenuKey)
    const allMenuTabs = useSelector(getAllMenuTab) // 所有菜单
    const userAccount = useSelector(getUserAccount)

    function onChangeMenuKey(menuKey2) {
        if (menuKey2 !== menuKey) {
            // 设置tabKey
            dispatch(setActiveTabKey(""))
            // 设置menuKey
            dispatch(setActiveMenuKey(menuKey2))
        }
        if (menuKey2 === MENU_KEY.FOLLOWING) {
            navigate(MENU_FOLLOWING_PATH, {replace: true})
        } else {
            navigate(`/${menuKey2}`, {replace: true})
        }
    }

    async function onLogout() {
        // 请求接口
        await apiUserLogout()
        // 删除用户相关信息
        dispatch(removeUserAll())
        // 刷新页面
        window.location.replace(window.location.href);
    }


    useEffect(() => {
        // 设置移动端标题
        if (menuKey === MENU_KEY.FOLLOWING) {
            dispatch(setMobileHeader(MENU_FOLLOWING_NAME))
        } else {
            dispatch(setMobileHeader(allMenuTabs.find(ele => ele.menu_key === menuKey)?.menu_name))
        }
        // 设置默认菜单
        if (menuKey === "") {
            dispatch(setActiveMenuKey(isLoggedIn ? MENU_KEY.FOLLOWING : MENU_KEY.HOME))
        }
    }, [allMenuTabs, menuKey])

    return (
        widthGTAMd(useViewport())
            ? <PcRender onLogout={onLogout} onChangeMenuKey={onChangeMenuKey} menuKey={menuKey} allMenuTabs={allMenuTabs} isLoggedIn={isLoggedIn} userAccount={userAccount}/>
            : <MobileRender onLogout={onLogout} onChangeMenuKey={onChangeMenuKey} menuKey={menuKey} allMenuTabs={allMenuTabs} isLoggedIn={isLoggedIn} userAccount={userAccount}/>
    )
}

export default Header;
