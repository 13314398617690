import React from 'react';
import {getCDNUrl} from "../../utils";
import {ALL_POSTS_IDS} from "../../pages/Article/posts";
import {useNavigate} from "react-router-dom";
import {getCurrentYear} from "../../utils/time";


function Footer() {
    const navigate = useNavigate()
    return (
        <footer className="z-10 mt-5 shadow-sm border-t text-center text-xs py-5
            bg-primary-200 dark:bg-primaryDark-200 border-border-100 dark:border-borderDark-100 text-text-300 dark:text-textDark-300"
        >
            <p className="hidden md:block space-x-2 mb-2">
                <a className="hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap" target="_blank" rel="noreferrer" href="https://support.qq.com/products/434778">反馈 建议 提交站点</a>
                <span className="font-black">·</span>
                <a className="hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap cursor-pointer" onClick={() => navigate(`/article/${ALL_POSTS_IDS.WECHAT_MP}`)}>公众号 微信群</a>
                <span className="font-black">·</span>
                <a className="hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap cursor-pointer" onClick={() => navigate(`/article/${ALL_POSTS_IDS.DONATE}`)}>请作者喝奶茶</a>
                <span className="font-black">·</span>
                <a className="hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap cursor-pointer" onClick={() => navigate(`/article/${ALL_POSTS_IDS.VIP}`)}>热榜会员</a>
                <span className="font-black">·</span>
                <a className="hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap cursor-pointer" onClick={() => navigate(`/article/${ALL_POSTS_IDS.ADVERTISE}`)}>投放广告</a>
                <span className="font-black">·</span>
                <a className="hover:text-gray-600 dark:hover:text-gray-300 whitespace-nowrap cursor-pointer" onClick={() => navigate(`/article/${ALL_POSTS_IDS.DOWNLOAD_PC}`)}>安装到桌面</a>
            </p>
            <p className="space-x-2">
                <span>{`©${getCurrentYear()} 今日热榜`}</span>
                <a target="_blank" rel="noreferrer" className="hover:text-gray-600 dark:hover:text-gray-300" href="https://beian.miit.gov.cn/">
                    京ICP备17024220号
                </a>
            </p>
            <p>

                <a target="_blank" rel="noreferrer" className="hover:text-gray-600 dark:hover:text-gray-300" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502050916">
                    <img className="w-4 h-4 inline-block mr-1 -mt-0.5" src={getCDNUrl("forever/beian.png")} alt=""/>
                    京公网安备 11010502050916 号
                </a>
            </p>

        </footer>
    );
}

export default Footer;
