export const ACCESS_TOKEN_KEY = 'access_token'
export const THEME_KEY = "theme"
export const TAB_ACTIVE_KEY = "tab_active_key"
export const MENU_ACTIVE_KEY = "menu_active_key"
export const USER_SETTING_KEY = "user_setting"
export const USER_ACCOUNT_KEY = "user_account"
export const MENU_TAB_KEY = "menu_tab"
export const MENU_TAB_UPDATE_FLAG_KEY = "menu_tab_update_flag"
export const REMOTE_SYSTEM_INFO = "remote_system_info"
export const READ_NOTIFY_ID = "read_notify_id"
export const XIAOHONGSHU_SHOW_TYPE = "xiaohongshu_show_type"
export const TIP_READ_FLAG = "tip_read_flag"
export const TOP_USER_LAST_PLAN = "top_user_last_plan"
export const TOP_LAST_PLAN = "top_last_plan"
export const SUB_TAB_PERSIST = "sub_tab_persist"
export const ADS_DELETED_DONT_REMIND_TIME = "ads_deleted_dont_remind_time"

// localStorage白名单，不在这个白名单上的将被删除
export const LOCALSTORAGE_WHITE_LIST = [
    ACCESS_TOKEN_KEY,
    THEME_KEY,
    TAB_ACTIVE_KEY,
    USER_SETTING_KEY,
    USER_ACCOUNT_KEY,
    MENU_TAB_KEY,
    MENU_TAB_UPDATE_FLAG_KEY,
    MENU_ACTIVE_KEY,
    REMOTE_SYSTEM_INFO,
    READ_NOTIFY_ID,
    XIAOHONGSHU_SHOW_TYPE,
    TIP_READ_FLAG,
    TOP_USER_LAST_PLAN,
    SUB_TAB_PERSIST,
    ADS_DELETED_DONT_REMIND_TIME,
]