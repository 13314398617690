import {BREAKPOINT_WIDTH_ENUM} from "../constants";
import {IconUser} from "@arco-design/web-react/icon";
import {Message} from "@arco-design/web-react";
import {apiAdminDeleteItem} from "../apis/item";

export const getImageUrl = (imgUrl) => {
    if (imgUrl === undefined || imgUrl === null || imgUrl === "") {
        return ""
    }
    if (imgUrl.startsWith("http")) {
        return imgUrl
    }
    return `${process.env.REACT_APP_IMG_BASE_URL}/${imgUrl}`
}

export const getCDNUrl = (url) => {
    if (url === undefined || url === null || url === "") {
        return ""
    }
    if (url.startsWith("http")) {
        return url
    }
    return `${process.env.REACT_APP_CDN_BASE_URL}/${url}`
}

export const getWebUrl = (url) => {
    if (url === undefined || url === null || url === "") {
        return ""
    }

    if (url.startsWith("http")) {
        return url
    }
    return `${process.env.REACT_APP_WEB_URL}/${url}`
}

export const handleBilibiliNum = (num) => {
    if (num < 10000) {
        return num
    }
    if (num < 100000000) {
        return (num / 10000).toFixed(1) + "万"
    }
    return (num / 100000000).toFixed(1) + "亿"
}

export const handleXiaohongshuNum = (num) => {
    if (num < 10000) {
        return num
    }
    if (num < 100000000) {
        return (num / 10000).toFixed(1) + "万"
    }
    return (num / 100000000).toFixed(1) + "亿"
}

export const handleWereadNum = (num) => {
    if (num < 10000) {
        return num
    }
    if (num < 100000000) {
        return (num / 10000).toFixed(1) + "万"
    }
    return (num / 100000000).toFixed(1) + "亿"
}

export const widthGTAMd = (width) => {
    return width >= BREAKPOINT_WIDTH_ENUM.MD
}

export const widthGTMd = (width) => {
    return width > BREAKPOINT_WIDTH_ENUM.MD
}

export const widthGTAXl = (width) => {
    return width >= BREAKPOINT_WIDTH_ENUM.XL
}
export const widthGTXl = (width) => {
    return width > BREAKPOINT_WIDTH_ENUM.XL
}

export const widthGTAAsideSize = (width) => {
    return width >= BREAKPOINT_WIDTH_ENUM.ASIDE_SIZE
}

export const getEmailAvatarName = (email) => {
    if (!email) {
        return <IconUser />
    }
    const [username] = email.toLowerCase().split('@');
    const firstLetter = username.charAt(0);
    // const lastLetter = username.charAt(username.length - 1);
    return `${firstLetter}`.toUpperCase();
}

export const adminDeleteItem = (itemKey) => {
    apiAdminDeleteItem(itemKey).then((res) => {
        if (res.code === 200) {
            Message.success({content:"删除成功", duration: 2000})
        } else {
            Message.error("修改失败，Err：" + res.msg)
        }
    }).catch(() => {
        Message.error("修改失败，请稍后再试")
    })
}
