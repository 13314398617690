import React, {useEffect, useRef, useState} from "react";
import {TAB_NAME_ENUM} from "../../constants";
import {useLocation} from "react-router-dom";
import {getListByQuery} from "../../apis/item";
import ItemAsideRender from "./ItemAsideRender";
import {useSelector} from "react-redux";
import {getUserSetting} from "../../redux/user";
import {useViewport} from "../../hooks";
import {widthGTAMd} from "../../utils";
import ItemOne from "./ItemOne";
import {getRealSetting, SETTING_KEY} from "../../constants/setting";

// type 1-全站飙升榜（最近1小时）2-订阅飙升榜（最近1小时）
function ItemAside({type}) {
    const userSetting = useSelector(getUserSetting)
    const page = useRef(1) // 当前页
    const nextPage = useRef(1) // 下一页
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const location = useLocation()
    const filterWordArr = getRealSetting(SETTING_KEY.FILTER_WORD, userSetting[SETTING_KEY.FILTER_WORD])

    useEffect(() => {
        page.current = 1
        nextPage.current = 1
        loadList()
    }, [location.pathname, location.search])


    async function loadList() {
        if (loading) return
        setLoading(true)
        let query = `tab=${TAB_NAME_ENUM.TOP}&sub_tab=lasthour`
        if (type === 2) {
            query = `tab=${TAB_NAME_ENUM.TOP_USER}&sub_tab=lasthour&update_flag=${userSetting.update_flag}`
        }
        const {list: newList, error, end, currentPage} = await getListByQuery(query, nextPage.current)
        setLoading(false)
        setError(error)
        if (!error) {
            page.current = nextPage.current
            if (newList.length > 0) {
                setList(newList)
                nextPage.current = end ? 1 : currentPage
            } else if (page.current !== 1) { // 当后面没数据自动切换到第一页
                nextPage.current = 1
                await loadList()
            }
        }
    }

    let width = useViewport()

    return (
        <ItemAsideRender loading={loading} error={error} type={type} onChangePage={loadList}>
            {!loading && !error && list.map((item, k) => {
                const real_url = widthGTAMd(width) ? item.www_url : item.mobile_url
                return <ItemOne key={k} filterWordArr={filterWordArr} {...item} index={((page.current - 1) * 10) + k + 1} www_url={real_url}/>
            })}
        </ItemAsideRender>
    );
}

export default ItemAside;
