// type 1-竖版 2-横版
import React, {useEffect} from "react";

function Asense({type}) {

    useEffect(() => {
        try {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
        } catch(e) {
            console.error(e)
        }
    })

    return (
        <> {(type === 1
            ? <div className="shadow-sm rounded-md bg-primary-200 dark:bg-primaryDark-200 text-text-200 dark:text-textDark-200">
                {process.env.NODE_ENV === "development"
                    && <div className="rounded-md w-[270px] h-[242px] text-4xl flex justify-center items-center border border-border-100 dark:border-borderDark-100">Google 广告</div>}
                {process.env.NODE_ENV === "production" && <div
                    className="rounded-md wwads-cn wwads-vertical border border-border-100 dark:border-borderDark-100">
                    <ins className="adsbygoogle"
                         style={{display: "inline-block", width: "270px", height: "242px"}}
                         data-ad-client="ca-pub-3949227391810090"
                         data-ad-slot="8464361451"
                    >
                    </ins>
                </div>}
            </div>
            : <div className="asideSize:hidden shadow-sm md:rounded-md bg-primary-200 dark:bg-primaryDark-200 text-text-200 dark:text-textDark-200">
                {process.env.NODE_ENV === "development"
                    && <div className="md:rounded-md w-full h-[120px] text-4xl flex justify-center items-center border-y md:border border-border-100 dark:border-borderDark-100">Google 广告</div>}
                {process.env.NODE_ENV === "production" && <div
                    className="rounded-md wwads-cn wwads-vertical border border-border-100 dark:border-borderDark-100">
                    <ins className="adsbygoogle"
                         style={{display: "block", height: "120px", width: "100%"}}
                         data-ad-client="ca-pub-3949227391810090"
                         data-ad-slot="2082785930"
                    >
                    </ins>
                </div>}
            </div>)}
        </>
    )
}

export default Asense;
