import React, {useEffect, useState} from "react";

function Loading() {
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setShowLoading(true), 300)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <div className="animate-pulse">
            {/*list开始*/}
            {Array.from({length: 10}, (_, i) => (
                showLoading ? (
                    <div key={i.toString()} className="w-full flex p-3 border-b border-solid border-border-100 dark:border-borderDark-100">
                        <div className="w-7 h-7 bg-loading-100 dark:bg-loadingDark-100 rounded"></div>
                        <div className="flex-grow ml-2 h-7 bg-loading-100 dark:bg-loadingDark-100 rounded"></div>
                    </div>
                ) : (
                    <div key={i.toString()} className="w-full p-3 flex border-b border-transparent">
                        <div className="h-7"></div>
                    </div>
                )
            ))}
            {/*list结束*/}
        </div>
    )
}

export default Loading