import React, {useEffect, useState} from 'react'
import {IconArrowUp} from "@arco-design/web-react/icon";
import {useDispatch, useSelector} from "react-redux";
import {getRealSetting, SETTING_KEY, URL_TARGET_ENUM} from "./constants/setting";
import {loadColorTheme} from "./utils/setting";
import {
    getIsLoggedIn,
    getUserAccount,
    getUserSetting,
    initUserAccount,
    initUserSetting
} from "./redux/user";
import MyRoutes from "./routes";
import {getMaintenanceInfo, getRemoteSystemInfo, getTheme, initRemoteSystemInfo} from "./redux/system";
import {apiGetMenuTabs} from "./apis/tab";
import {getAllMenuTab, initMenuTab, setActiveMenuKey, setActiveTabKey} from "./redux/tab";
import {MENU_TAB_UPDATE_FLAG_KEY} from "./constants/localStorage";
import {apiUserAccountSetting} from "./apis/user";
import {useSettingUrlTarget} from "./hooks/setting";
import {apiGetSystemInfo} from "./apis/system";
import {MENU_FOLLOWING_PATH, MENU_KEY} from "./constants/menu";
import {useLocation, useSearchParams} from "react-router-dom";
import Maintenance from "./components/Maintenance";
import {useWindowScrollY} from "./hooks";

function App() {
    const dispatch = useDispatch();

    const scrollY = useWindowScrollY()
    const userAccount = useSelector(getUserAccount)
    const userSetting = useSelector(getUserSetting)
    const isLoggedIn = useSelector(getIsLoggedIn)
    const remoteSystemInfo = useSelector(getRemoteSystemInfo)
    const allMenuTab = useSelector(getAllMenuTab)
    const {maintenance, maintenanceReason} = useSelector(getMaintenanceInfo)

    const [search] = useSearchParams()
    const pathTabKey = search.get('tab')
    const location = useLocation()

    const [loading, setLoading] = useState(true);
    const [loadingMenu, setLoadingMenu] = useState(true);

    // 全部加载完成
    useEffect(() => {
        if (!loadingMenu) {
            setLoading(false)
        }
    }, [loadingMenu])

    // 设置全局跳转方式
    const urlTarget = useSettingUrlTarget(userSetting)
    useEffect(() => {
        if (urlTarget === URL_TARGET_ENUM.SELF) {
            document.getElementById('myBase').target = "_self"
        } else {
            document.getElementById('myBase').target = "_blank"
        }
    }, [urlTarget])

    // 载入暗黑/明亮模式
    const theme = useSelector(getTheme)
    useEffect(() => {
        loadColorTheme(theme)
    }, [theme])

    // 默认菜单
    const defaultMenuKey = getRealSetting(SETTING_KEY.DEFAULT_MENU, userSetting[SETTING_KEY.DEFAULT_MENU])

    // 加载menu_tab信息
    useEffect(() => {
        let allMenuTabNew = allMenuTab
        const update_flag = localStorage.getItem(MENU_TAB_UPDATE_FLAG_KEY) || "";
        apiGetMenuTabs({update_flag}).then((res) => {
            if (res.code === 200 && res.data.menu_tabs.length > 0) {
                dispatch(initMenuTab(res.data.menu_tabs))
                localStorage.setItem(MENU_TAB_UPDATE_FLAG_KEY, res.data.update_flag);
                allMenuTabNew = res.data.menu_tabs
            }
            // 根据url设置activeMenuKey和activeTabKey
            let activeMenuKey = defaultMenuKey
            if (location.pathname === MENU_FOLLOWING_PATH) {
                activeMenuKey = MENU_KEY.FOLLOWING
            } else if (location.pathname === "/") {
                if (defaultMenuKey !== MENU_KEY.FOLLOWING) {
                    const defaultKeyExists = allMenuTabNew.some(ele => ele.menu_key === defaultMenuKey);
                    activeMenuKey = defaultKeyExists ? defaultMenuKey : MENU_KEY.HOME;
                }
            } else {
                const one = allMenuTabNew.find(ele => `/${ele.menu_key}` === location.pathname)
                activeMenuKey = one ? one.menu_key : MENU_KEY.HOME
            }

            dispatch(setActiveMenuKey(activeMenuKey))

            if (pathTabKey !== null) {
                dispatch(setActiveTabKey(pathTabKey))
            }
        }).finally(() => {
            setLoadingMenu(false)
        })
    }, [])

    // 更新用户账号信息和用户设置
    useEffect(() => {
        if (isLoggedIn) {
            const setting_update_flag = userSetting.update_flag || "";
            const account_update_flag = userAccount.update_flag || "";
            apiUserAccountSetting({account_update_flag, setting_update_flag}).then((res) => {
                if (res.code === 200) {
                    if (res.data.user_account) {
                        dispatch(initUserAccount(res.data.user_account))
                    }
                    if (res.data.user_setting) {
                        dispatch(initUserSetting(res.data.user_setting))
                    }
                }
            })
        }
    }, [])

    // 同步系统设置信息
    useEffect(() => {
        const update_flag = remoteSystemInfo.update_flag || "";
        apiGetSystemInfo({update_flag}).then((res) => {
            if (res.code === 200) {
                if (res.data) {
                    dispatch(initRemoteSystemInfo(res.data))
                }
            }
        })
    }, [])

    if (maintenance) {
        return <Maintenance reason={maintenanceReason}/>
    }

    if (loading) {
        return <div className="bg-primary-100 dark:bg-primaryDark-100 w-full h-full"></div>
    }

    return (
        <div className="bg-primary-100 dark:bg-primaryDark-100">
            <div className={`cursor-pointer z-50 right-[20px] md:right-[60px] bottom-[60px] ${scrollY > 50 ? "fixed" : "hidden"}`} onClick={() => {window.scrollTo({top: 0, behavior: "smooth"})}}>
                <div className="flex items-center justify-center w-12 h-12 border border-border-100
                    dark:border-borderDark-100 rounded-lg shadow-md font-extrabold text-lg
                    hover:bg-primary-300 dark:hover:bg-primaryDark-300
                    bg-primary-200 dark:bg-primaryDark-200 text-text-300 dark:text-textDark-300 duration-300"
                >
                    <IconArrowUp style={{fontSize: 28}}/>
                </div>
            </div>
            <MyRoutes/>
        </div>
    )
}

export default App
