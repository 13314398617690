import {getCDNUrl} from "../../../utils";

const fileContent = `### 公众号关注方法

扫描下方二维码或微信搜索 \`热榜Today\`

### 关注公众号的好处

- 定期的热榜新闻推送
- 定期的福利活动，例如：会员激活码、热榜周边、礼品等
- 网站的更新与变化会得到通知
- 可加入今日热榜交流群
- 可联系站长

### 公众号二维码

![](${getCDNUrl("forever/weixinmp.png")})

`;

export default fileContent;