import React, {useState} from "react";
import {Tabs, Radio, Tooltip, Alert, Link, Select, Input, Tag, Message} from "@arco-design/web-react";
import {IconDesktop, IconQuestionCircle,} from "@arco-design/web-react/icon";
import {getRealSetting, LIST_SHOW_ENUM, TAB_SHOW_ENUM, URL_TARGET_ENUM} from "../../constants/setting";
import {SETTING_KEY} from "../../constants/setting";
import {THEME_ENUM} from "../../constants/system";
import {useDispatch, useSelector} from "react-redux";
import {getFilterNumRule, setTheme} from "../../redux/system";
import {useNavigate} from "react-router-dom";
import {MENU_FOLLOWING_NAME, MENU_KEY} from "../../constants/menu";

const TabPane = Tabs.TabPane;


function MobileRender({theme, isLoggedIn, userSetting, onChangeSetting, allMenuTabs, filterNumLimit, defaultMenuKey}) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [addingFilterWord, setAddingFilterWord] = useState('')
    const filterNumNormal = useSelector(getFilterNumRule(1))
    const filterNumVip = useSelector(getFilterNumRule(2))
    const filterWordArr = getRealSetting(SETTING_KEY.FILTER_WORD, userSetting[SETTING_KEY.FILTER_WORD])

    function onAddFilterWord() {
        if (addingFilterWord.trim() === '') {
            return
        }
        if (filterWordArr.includes(addingFilterWord)) {
            Message.error('屏蔽词已存在')
            return
        }
        if (addingFilterWord.length > 5) {
            Message.error('屏蔽词长度不能超过5个字符')
            return
        }
        if (filterWordArr.length + 1 > filterNumLimit) {
            Message.error(`当前会员等级最多添加${filterNumLimit}个屏蔽词`)
            return
        }
        onChangeSetting(SETTING_KEY.FILTER_WORD, filterWordArr.concat(addingFilterWord))
        setAddingFilterWord('')
    }

    return (
        <div
            className="text-text-100 dark:text-textDark-100 pb-5 bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden mb-2 md:rounded-md shadow-sm">
            {!isLoggedIn && <Alert type='warning'
                                   content={<>未登录，修改将保存在本地，<Link onClick={() => navigate("/signin")}>登录</Link> 后可多端同步</>}/>}
            <Tabs defaultActiveTab='1' className="pt-1 px-1">
                <TabPane key='1' title={<span><IconDesktop style={{marginRight: 6}}/>显示</span>}>
                    <div className="flex flex-col space-y-8">
                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">颜色主题</div>
                            <div className="flex-1">
                                <Radio.Group value={theme}>
                                    <Radio value={THEME_ENUM.LIGHT}
                                           onChange={() => dispatch(setTheme(THEME_ENUM.LIGHT))}>
                                        浅色
                                    </Radio>
                                    <Radio value={THEME_ENUM.DARK} onChange={() => dispatch(setTheme(THEME_ENUM.DARK))}>
                                        深色
                                    </Radio>
                                    <Radio value={THEME_ENUM.AUTO} onChange={() => dispatch(setTheme(THEME_ENUM.AUTO))}>
                                        跟随系统
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">
                                <Tooltip content="选择当前标签页，使用后退返回本站；选择新标签页，关闭新标签页以返回本站">
                                    <IconQuestionCircle/>
                                </Tooltip>
                                <span className="mr-0.5">打开链接方式</span>
                            </div>
                            <div className="flex-1">
                                <Radio.Group
                                    value={getRealSetting(SETTING_KEY.URL_TARGET_PHONE, userSetting[SETTING_KEY.URL_TARGET_PHONE])}>
                                    <Radio value={URL_TARGET_ENUM.BLANK}
                                           onChange={() => onChangeSetting(SETTING_KEY.URL_TARGET_PHONE, URL_TARGET_ENUM.BLANK)}>新标签页</Radio>
                                    <Radio value={URL_TARGET_ENUM.SELF}
                                           onChange={() => onChangeSetting(SETTING_KEY.URL_TARGET_PHONE, URL_TARGET_ENUM.SELF)}>当前标签页</Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">
                                <span>列表展示</span>
                            </div>
                            <div className="flex-1">
                                <Radio.Group
                                    value={getRealSetting(SETTING_KEY.LIST_SHOW_PHONE, userSetting[SETTING_KEY.LIST_SHOW_PHONE])}>
                                    <Radio value={LIST_SHOW_ENUM.COMPLETE}
                                           onChange={() => onChangeSetting(SETTING_KEY.LIST_SHOW_PHONE, LIST_SHOW_ENUM.COMPLETE)}>
                                        完整
                                    </Radio>
                                    <Radio value={LIST_SHOW_ENUM.NO_PIC}
                                           onChange={() => onChangeSetting(SETTING_KEY.LIST_SHOW_PHONE, LIST_SHOW_ENUM.NO_PIC)}>
                                        无图
                                    </Radio>
                                    <Radio value={LIST_SHOW_ENUM.SIMPLE}
                                           onChange={() => onChangeSetting(SETTING_KEY.LIST_SHOW_PHONE, LIST_SHOW_ENUM.SIMPLE)}>
                                        精简
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <div className="w-[115px] xl:w-[160px] flex flex-row-reverse items-center">
                                <span>选项卡默认</span>
                            </div>
                            <div className="flex-1">
                                <Radio.Group
                                    value={getRealSetting(SETTING_KEY.TAB_SHOW_PHONE, userSetting[SETTING_KEY.TAB_SHOW_PHONE])}>
                                    <Radio value={TAB_SHOW_ENUM.FOLD}
                                           onChange={() => onChangeSetting(SETTING_KEY.TAB_SHOW_PHONE, TAB_SHOW_ENUM.FOLD)}>
                                        折叠
                                    </Radio>
                                    <Radio value={TAB_SHOW_ENUM.UNFOLD}
                                           onChange={() => onChangeSetting(SETTING_KEY.TAB_SHOW_PHONE, TAB_SHOW_ENUM.UNFOLD)}>
                                        展开
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <div className="items-center w-[115px] xl:w-[160px] flex flex-row-reverse">
                                <div className="flex items-center space-x-0.5">
                                    <span>默认菜单</span>
                                    <Tooltip content="当网址是https://rebang.today/时，默认打开的菜单页">
                                        <IconQuestionCircle/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex-1">
                                <Select
                                    defaultValue={getRealSetting(SETTING_KEY.DEFAULT_MENU, userSetting[SETTING_KEY.DEFAULT_MENU])}
                                    placeholder='请选择'
                                    style={{ width: 120 }}
                                    onChange={(value) => {
                                        onChangeSetting(SETTING_KEY.DEFAULT_MENU, value)
                                    }}
                                >
                                    <Select.Option value={MENU_KEY.FOLLOWING}>
                                        {MENU_FOLLOWING_NAME}
                                    </Select.Option>
                                    {allMenuTabs.map((item) =>
                                        <Select.Option key={item.menu_key} value={item.menu_key}>
                                            {item.menu_name}
                                        </Select.Option>
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="items-start mt-1 w-[115px] xl:w-[160px] flex flex-row-reverse">
                                <div className="flex items-center space-x-0.5">
                                    <span>自定义屏蔽词</span>
                                    <Tooltip content={<span>当标题匹配到屏蔽词，将会被淡化展示<br/>普通用户最多添加{filterNumNormal}条<br/>会员最多添加{filterNumVip}条</span>}>
                                        <IconQuestionCircle/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex-1">
                                <Input.Search
                                    searchButton='添加'
                                    placeholder='请输入屏蔽词'
                                    style={{ width: 200 }}
                                    maxLength={{length: 5, errorOnly: true}}
                                    value={addingFilterWord}
                                    onChange={(e) => setAddingFilterWord(e)}
                                    onSearch={onAddFilterWord}
                                />
                                <div className="mt-3 flex flex-wrap gap-1.5 pr-6">
                                    {filterWordArr.map((item) => <Tag color="gray" onClose={() => {
                                        onChangeSetting(SETTING_KEY.FILTER_WORD, filterWordArr.filter(key => key !== item))
                                    }} closable key={item}>{item}</Tag>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default MobileRender;
