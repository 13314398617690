import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import {Provider} from "react-redux"
import {store} from './redux';
import MyErrorBoundary from "./components/MyErrorBoundary";
import 'core-js/features/object/from-entries';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'core-js/features/promise/finally';
import 'core-js/features/url-search-params';
import 'core-js/features/string/pad-start';
import 'core-js/features/set';
import ResizeObserver from 'resize-observer-polyfill';

if (!('IntersectionObserver' in window)) {
    require('intersection-observer');
}

if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

if (typeof Date !== 'function') {
    require('core-js/features/date');
}

// deleteExpiredKey() // 删除不需要的local storage

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <MyErrorBoundary>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ Provider>
    </MyErrorBoundary>
    // </React.StrictMode>
);
