import followTutorial from "./followTutorial";
import serviceAgreement from "./serviceAgreement";
import privacyAgreement from "./privacyAgreement";
import donate from "./donate";
import wechatMp from "./wechatMp";
import vip from "./vip";
import version100 from "./version100";
import techBlogger from "./techBlogger";
import advertise from "./advertise";
import downloadMobile from "./downloadMobile";
import downloadPC from "./downloadPC";
import adsWhitelist from "./adsWhitelist";
import allArticles from "./allArticles";

export const ALL_POSTS_IDS = {
    FOLLOW_TUTORIAL: 'follow-tutorial', // 订阅教程
    SERVICE_AGREEMENT: 'service-agreement', // 服务协议
    PRIVACY_AGREEMENT: 'privacy-agreement', // 隐私协议
    DONATE: 'donate', // 捐赠
    WECHAT_MP: 'wechat-mp', // 微信公众号
    VIP: 'vip', // vip说明
    VERSION100: 'version-100', // vip说明
    TECH_BLOGGER: 'tech-blogger', // 技术博主说明
    ADVERTISE: 'advertise', // 广告投放
    DOWNLOAD_MOBILE: 'download-mobile', // 安装到桌面（手机端）
    DOWNLOAD_PC: 'download-pc', // 安装到桌面（电脑端）
    ADS_WHITELIST: 'ads-whitelist', // 广告白名单
    ALL_ARTICLES: 'all-articles', // 所有文章
}

export const ALL_POSTS = {
    [ALL_POSTS_IDS.FOLLOW_TUTORIAL]: {
        title: '订阅教程',
        content: followTutorial
    },
    [ALL_POSTS_IDS.SERVICE_AGREEMENT]: {
        title: '服务协议',
        content: serviceAgreement
    },
    [ALL_POSTS_IDS.PRIVACY_AGREEMENT]: {
        title: '隐私协议',
        content: privacyAgreement
    },
    [ALL_POSTS_IDS.DONATE]: {
        title: '捐赠',
        content: donate
    },
    [ALL_POSTS_IDS.WECHAT_MP]: {
        title: '微信公众号',
        content: wechatMp
    },
    [ALL_POSTS_IDS.VIP]: {
        title: '会员介绍',
        content: vip
    },
    [ALL_POSTS_IDS.VERSION100]: {
        title: '热榜2.0说明',
        content: version100
    },
    [ALL_POSTS_IDS.TECH_BLOGGER]: {
        title: '技术期刊全部博主',
        content: techBlogger
    },
    [ALL_POSTS_IDS.ADVERTISE]: {
        title: '广告投放',
        content: advertise
    },
    [ALL_POSTS_IDS.DOWNLOAD_MOBILE]: {
        title: '安装到桌面（手机端）',
        content: downloadMobile
    },
    [ALL_POSTS_IDS.DOWNLOAD_PC]: {
        title: '安装到桌面（电脑端）',
        content: downloadPC
    },
    [ALL_POSTS_IDS.ADS_WHITELIST]: {
        title: '加入广告屏蔽白名单',
        content: adsWhitelist
    },
    [ALL_POSTS_IDS.ALL_ARTICLES]: {
        title: '所有文章',
        content: allArticles
    },
}

