import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useTitle} from "../../hooks";
import {Breadcrumb} from "@arco-design/web-react";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LoginRender from "./LoginRender";
import Aside from "./Aside";
import {useDispatch} from "react-redux";
import {setMobileHeader} from "../../redux/system";
import {apiGetRegLimit} from "../../apis/system";

function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useTitle("登录")

    const [regLimitTip, setRegLimitTip] = useState("") // 限制注册提示

    useEffect(() => {
        dispatch(setMobileHeader("登录"))

        // 查询注册限制
        apiGetRegLimit().then(res => {
            res.code === 200 && res.data.allow === false && res.data.tip && setRegLimitTip(res.data.tip)
        })
    }, [])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="grow mx-auto">
                <div className="py-2 px-2 md:pt-0 md:px-0">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a className="cursor-pointer" onClick={() => navigate("/")}>首页</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>登录</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="flex">
                    <LoginRender regLimitTip={regLimitTip}/>
                    <Aside/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Login;
