import {createSlice} from '@reduxjs/toolkit'
import {
    ADS_DELETED_DONT_REMIND_TIME,
    READ_NOTIFY_ID,
    REMOTE_SYSTEM_INFO,
    SUB_TAB_PERSIST,
    THEME_KEY,
    TIP_READ_FLAG
} from "../constants/localStorage";
import {DEFAULT_THEME} from "../constants/system";
import {getSecondsTimestamp} from "../utils/time";
import {getUserIsVip} from "./user";

export const systemSlice = createSlice({
    name: 'system',
    initialState: {
        theme: "",
        mobileHeader: "Rebang.Today",
        tabFoldedState: true,
        readNotifyId: localStorage.getItem(READ_NOTIFY_ID) || "", // 已读公告
        remoteSystemInfo: JSON.parse(localStorage.getItem(REMOTE_SYSTEM_INFO)) || {},
        tipReadFlag: JSON.parse(localStorage.getItem(TIP_READ_FLAG)) || {}, // 已读tip
        subTabPersist: JSON.parse(localStorage.getItem(SUB_TAB_PERSIST)) || {}, // 持久化的subTab信息
        maintenance: false, // 网站是否升级维护中
        maintenanceReason: "", // 网站升级维护中的原因
        adsDeletedDontRemind: parseInt(localStorage.getItem(ADS_DELETED_DONT_REMIND_TIME)) || 0, // 广告删除后不再提醒的时间
    },
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload
            localStorage.setItem(THEME_KEY, action.payload);
        },
        setMobileHeader: (state, action) => {
            state.mobileHeader = action.payload
        },
        setTabFoldedState: (state, action) => {
            state.tabFoldedState = action.payload
        },
        setReadNotifyId: (state, action) => {
            state.readNotifyId = action.payload
            localStorage.setItem(READ_NOTIFY_ID, action.payload)
        },
        initRemoteSystemInfo: (state, action) => {
            state.remoteSystemInfo = action.payload
            localStorage.setItem(REMOTE_SYSTEM_INFO, JSON.stringify(action.payload))
        },
        setTipReadFlag: (state, action) => {
            state.tipReadFlag[action.payload] = true
            localStorage.setItem(TIP_READ_FLAG, JSON.stringify(state.tipReadFlag))
        },
        setSubTabPersist: (state, action) => {
            state.subTabPersist[action.payload.key] = action.payload.value
            localStorage.setItem(SUB_TAB_PERSIST, JSON.stringify(state.subTabPersist))
        },
        setMaintenance: (state, action) => {
            state.maintenance = true
            state.maintenanceReason = action.payload
        },
        setAdsDeletedDontRemind: (state, action) => {
            const now = new Date().getTime()
            state.adsDeletedDontRemind = now
            localStorage.setItem(ADS_DELETED_DONT_REMIND_TIME, now)
        },
    }
})

export const {
    setTheme,
    setMobileHeader,
    setTabFoldedState,
    setReadNotifyId,
    initRemoteSystemInfo,
    setTipReadFlag,
    setSubTabPersist,
    setMaintenance,
    setAdsDeletedDontRemind,
} = systemSlice.actions

export const getTheme = (state) => {
    let ret = state.systemReducer.theme
    if (ret === "") {
        const theme = localStorage.getItem(THEME_KEY)
        ret = theme === null ? DEFAULT_THEME : theme
    }
    return ret
}

export const getMobileHeader = state => state.systemReducer.mobileHeader
export const getTabFoldedState = state => state.systemReducer.tabFoldedState
export const getRemoteSystemInfo = state => state.systemReducer.remoteSystemInfo
export const getNotifyInfo = state => {
    const notifyInfo = state.systemReducer.remoteSystemInfo.notify_info
    if (notifyInfo && notifyInfo.id !== state.systemReducer.readNotifyId && notifyInfo.end_at > getSecondsTimestamp()) {
        return notifyInfo
    }
    return {}
}

export const getFollowTabLimit = state => {
    let limit = 20
    const vipInfo = state.systemReducer.remoteSystemInfo.vip_info
    if (vipInfo && vipInfo.follow_num_limit) {
        limit = vipInfo.follow_num_limit
    }
    if (getUserIsVip(state) && vipInfo.vip_follow_num_limit) {
        limit = vipInfo.vip_follow_num_limit
    }
    return limit
}

export const getFilterNumLimit = state => {
    let limit = 5
    const vipInfo = state.systemReducer.remoteSystemInfo.vip_info
    if (vipInfo && vipInfo.filter_num_limit) {
        limit = vipInfo.filter_num_limit
    }
    if (getUserIsVip(state) && vipInfo.vip_filter_num_limit) {
        limit = vipInfo.vip_filter_num_limit
    }
    return limit
}

// type= 1:普通用户 2:vip用户
export const getFilterNumRule = (type) => {
    return (state) => {
        let limit = 3
        const vipInfo = state.systemReducer.remoteSystemInfo.vip_info
        if (type === 1 && vipInfo && vipInfo.filter_num_limit) {
            limit = vipInfo.filter_num_limit
        }
        if (type === 2 && vipInfo && vipInfo.vip_filter_num_limit) {
            limit = vipInfo.vip_filter_num_limit
        }
        return limit
    }
}

export const getTipRead = (key) => {
    return (state) => {
        return !!state.systemReducer.tipReadFlag[key];
    }
}

export const getSubTabPersist = (key) => {
    return (state) => {
        return state.systemReducer.subTabPersist[key];
    }
}

export const getMaintenanceInfo = (state) => {
    return {
        maintenance: state.systemReducer.maintenance,
        maintenanceReason: state.systemReducer.maintenanceReason
    }
}

export const getAdsDeletedRemind = (state) => {
    const d1 = new Date(state.systemReducer.adsDeletedDontRemind);
    const d2 = new Date();
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    // 计算两个日期到周一的天数
    const daysToSunday1 = (d1.getDay() + 6) % 7;
    const daysToSunday2 = (d2.getDay() + 6) % 7;
    // 将日期移至周日
    d1.setDate(d1.getDate() - daysToSunday1);
    d2.setDate(d2.getDate() - daysToSunday2);
    // 时间不相等说明需要展示广告被屏蔽时的提示
    return d1.getTime() !== d2.getTime();
}


export default systemSlice.reducer


