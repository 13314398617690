import React, {useEffect, useState} from "react";
import {Alert, Button, Checkbox, Form, Input, Link, Message, Tabs} from "@arco-design/web-react";
import {useNavigate} from "react-router-dom";
import {IconEmail, IconSafe} from "@arco-design/web-react/icon";
import {apiRegisterLoginByEmail, apiSendEmailCaptcha} from "../../apis/user";
import {useDispatch, useSelector} from "react-redux";
import {getIsLoggedIn, initUserAccount, initUserSetting, setUserAccessToken} from "../../redux/user";
import {ALL_POSTS_IDS} from "../Article/posts";
import Ads from "../../components/Ads";

function LoginRender({regLimitTip}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const [captcha, setCaptcha] = React.useState({});
    // const [captchaStatus, setCaptchaStatus] = React.useState(false); // 图形验证码是否通过
    const [form] = Form.useForm();
    let emailCaptchaInterval = null;
    const [emailCaptchaSending, setEmailCaptchaSending] = useState(false); // 邮箱验证码是否发送中
    const [emailCaptchaWaitTime, setEmailCaptchaWaitTime] = useState(0); // 邮箱验证码等待时间
    const isLoggedIn = useSelector(getIsLoggedIn)

    function sendEmailCaptcha() {
        const email = form.getFieldValue('email');
        if (!email || form.getFieldError('email') !== null) {
            Message.error('请输入正确的邮箱');
            return
        }
        // 开始发送
        setEmailCaptchaSending(true)
        apiSendEmailCaptcha({email}).then(res => {
            if (res.code === 200) {
                let waitTime = 60
                if (res.data.wait_time > 0) {
                    waitTime = res.data.wait_time
                    Message.error('发送频繁，请在' + res.data.wait_time + '秒后重试');
                } else {
                    Message.success('验证码已发送，如未收到请检查垃圾邮箱');
                }
                setEmailCaptchaWaitTime(waitTime)
                emailCaptchaInterval = setInterval(() => {
                    setEmailCaptchaWaitTime((prevTimeLeft) => prevTimeLeft - 1);
                }, 1000);
            } else {
                Message.error(res.msg);
            }
        }).finally(() => {
            setEmailCaptchaSending(false)
        })
    }

    useEffect(() => {
        emailCaptchaWaitTime === 0 && clearInterval(emailCaptchaInterval);
    }, [emailCaptchaWaitTime]);

    function login(values) {
        apiRegisterLoginByEmail({email: values.email, email_captcha: values.captcha}).then(res => {
            if (res.code === 200) {
                Message.success('登录成功');
                dispatch(setUserAccessToken(res.data.access_token))
                dispatch(initUserAccount(res.data.user_account))
                dispatch(initUserSetting(res.data.user_setting))

                // 如果登录成功，那么返回之前的页面，或者默认的页面
                // const { from } = history.location.state || { from: { pathname: '/' } };
                // navigate(from, {replace: true});

                setTimeout(() => {
                    window.location.assign("/")
                }, 100)
            } else {
                let msg = res.msg
                if (res.code === 1009) {
                    msg = "验证码错误"
                }
                if (res.code === 1013 || res.code === 1014) {
                    msg = "注册次数过多，明天再来吧"
                }
                Message.error(msg);
            }
        })
    }

    return (
        <div className="grow shrink flex justify-center">
            <div className="w-screen md:w-[600px] xl:w-[770px]">
                <div
                    className="text-text-100 dark:text-textDark-100 bg-primary-200 dark:bg-primaryDark-200 border-t md:border border-b-0 border-border-100 dark:border-borderDark-100 overflow-hidden mb-2 md:rounded-md shadow-sm">
                    {regLimitTip !== "" && <Alert type='error' content={regLimitTip}/>}
                    <Tabs defaultActiveTab='1' className="pt-1 px-1">
                        <Tabs.TabPane key='1' title="验证码登录">
                            <Form
                                form={form}
                                size="large"
                                wrapperCol={{span: 24}}
                                autoComplete='off'
                                onSubmit={(v) => {
                                    login(v)
                                }}
                                className="pt-5 pb-10 mx-auto px-10 md:px-10 xl:px-24"
                            >
                                <Form.Item
                                    disabled={!!isLoggedIn}
                                    field='email'
                                    rules={[
                                        {
                                            type: 'email',
                                            validateLevel: 'error',
                                        },
                                        {
                                            required: true,
                                            type: 'string',
                                            minLength: 6,
                                        },
                                    ]}
                                >
                                    <Input prefix={<IconEmail/>} placeholder='请输入邮箱'/>
                                </Form.Item>
                                <div className="flex space-x-3">
                                    <Form.Item
                                        field='captcha'
                                        disabled={!!isLoggedIn}
                                        rules={[
                                            {
                                                validator(value, cb) {
                                                    if (value === undefined || value.length < 6 || Number.isInteger(Number(value)) === false) {
                                                        return cb('请输入正确的验证码');
                                                    }
                                                    return cb();
                                                }
                                            }
                                        ]}
                                    >
                                        <Input disabled={!!isLoggedIn} prefix={<IconSafe/>} placeholder='请输入验证码'/>
                                    </Form.Item>
                                    <Button type='primary'
                                            loading={emailCaptchaSending}
                                            disabled={!!isLoggedIn || emailCaptchaSending || emailCaptchaWaitTime > 0}
                                            onClick={sendEmailCaptcha}>{emailCaptchaSending ? "发送中" : (emailCaptchaWaitTime > 0 ? "重试发送(" + emailCaptchaWaitTime + ")" : "发送验证码")}</Button>
                                </div>
                                <Form.Item
                                    field='readme'
                                    disabled={!!isLoggedIn}
                                    triggerPropName='checked'
                                    rules={[
                                        {
                                            validator(value, cb) {
                                                if (value !== true) {
                                                    return cb('请阅读并同意服务协议和隐私条款');
                                                }
                                                return cb();
                                            }
                                        }
                                    ]}
                                >
                                    <Checkbox>未注册邮箱登录后将自动生成账号，且代表您已阅读并同意<Link onClick={() => navigate(`/article/${ALL_POSTS_IDS.SERVICE_AGREEMENT}`)}>服务协议</Link>和<Link onClick={() => navigate(`/article/${ALL_POSTS_IDS.PRIVACY_AGREEMENT}`)}>隐私条款</Link></Checkbox>
                                </Form.Item>
                                <Form.Item>
                                    <Button type='primary' disabled={!!isLoggedIn} htmlType='submit'>{!!isLoggedIn ? "您已登录" : "登录"}</Button>
                                </Form.Item>
                            </Form>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                {/*广告 开始*/}
                <Ads type={2}/>
                {/*广告 结束*/}
            </div>
        </div>
    );
}

export default LoginRender;
