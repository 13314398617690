import React, {useEffect, useState} from "react";

function ListLoading({tabKey}) {
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setShowLoading(true), 300)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <div className="animate-pulse h-screen">
            {/*list开始*/}
            {showLoading && Array.from({length: Math.ceil(window.innerHeight / 96)}, (_, i) =>
                <div key={i.toString()}
                     className="w-full h-24 flex justify-between px-4 py-4 border-b border-solid border-border-100 dark:border-borderDark-100">
                    <div className="w-2/3">
                        <div className="w-1/3 h-7 bg-loading-100 dark:bg-loadingDark-100 rounded"></div>
                        <div className="w-2/3 h-4 mt-4 bg-loading-100 dark:bg-loadingDark-100 rounded"></div>
                    </div>
                    <div className="w-32 bg-loading-100 dark:bg-loadingDark-100 rounded"></div>
                </div>
            )}
            {/*list结束*/}
        </div>
    )
}

export default ListLoading