import {useSelector} from "react-redux";
import {getUserIsVip} from "../../redux/user";
import {getCDNUrl} from "../../utils";
import React from "react";

function AsiayunAds() {
    const isVip = useSelector(getUserIsVip)
    return (
        <>
            {!isVip && <div
                className="border border-border-100 dark:border-borderDark-100 bg-primary-200 dark:bg-primaryDark-200 rounded-md shadow-sm">
                <div className="p-[10px]">
                    <a href="https://www.asiayun.com/aff/DFCLMXGU" target="_blank">
                        <img className="rounded dark:hidden" src={getCDNUrl("forever/asiayun.jpg")} alt=""/>
                        <img className="rounded hidden dark:block" src={getCDNUrl("forever/asiayun_dark.jpg")}
                             alt=""/>
                    </a>
                </div>
                <div
                    className="py-1 text-text-200 dark:text-textDark-200 rounded-b-md text-xs text-center bg-primary-300 dark:bg-primaryDark-300">
                    <a className="hover:text-text-100 hover:dark:text-textDark-100" href="https://www.asiayun.com/aff/DFCLMXGU" target="_blank">本站服务器由亚洲云提供</a>
                </div>
            </div>}
        </>
    )
}

export default AsiayunAds;
