export const BLOGGERS = {
    1: {
        name: "阮一峰的网络日志",
        www: "https://www.ruanyifeng.com/blog/weekly/",
        avatar: "forever/journal_icon/ruanyifeng.png",
        avatar_dark: "forever/journal_icon/ruanyifeng.png",
        slogan: "记录每周值得分享的科技内容",
    },
    2: {
        name: "奇舞周刊",
        www: "https://weekly.75.team/",
        avatar: "forever/journal_icon/75weekly.ico",
        avatar_dark: "forever/journal_icon/75weekly.ico",
        slogan: "领略前端技术 阅读奇舞周刊",
    },
    3: {
        name: "HelloGitHub",
        www: "https://hellogithub.com/",
        avatar: "forever/journal_icon/hello_github.ico",
        avatar_dark: "forever/journal_icon/hello_github_dark.ico",
        slogan: "分享 GitHub 上有趣、入门级的开源项目",
    },
    4: {
        name: "好工具周刊 BestXTools",
        www: "https://bestxtools.zhubai.love/",
        avatar: "forever/journal_icon/bestxtools.png",
        avatar_dark: "forever/journal_icon/bestxtools.png",
        slogan: "发现并分享有趣，有创意，免费、好用的工具",
    },
    5: {
        name: "Easy Indie",
        www: "https://www.ezindie.com/",
        avatar: "forever/journal_icon/ezindie.ico",
        avatar_dark: "forever/journal_icon/ezindie.ico",
        slogan: "让小产品的独立变现更简单",
    },
    6: {
        name: "DecoHack周刊",
        www: "https://decohack.zhubai.love/",
        avatar: "forever/journal_icon/decohack.png",
        avatar_dark: "forever/journal_icon/decohack.png",
        slogan: "独立开发者的灵感周刊",
    },
    7: {
        name: "老胡的周刊",
        www: "https://weekly.howie6879.com/",
        avatar: "forever/journal_icon/howie6879.jpeg",
        avatar_dark: "forever/journal_icon/howie6879.jpeg",
        slogan: "奇文共欣赏，疑义相与析；编程、兴趣、生活，分享我热爱的",
    },
    8: {
        name: "酷壳 CoolShell（已故）",
        www: "https://coolshell.cn/",
        avatar: "forever/journal_icon/coolshell.png",
        avatar_dark: "forever/journal_icon/coolshell.png",
        slogan: "享受编程和技术所带来的快乐",
    },
    9: {
        name: "美团技术团队",
        www: "https://tech.meituan.com/",
        avatar: "forever/journal_icon/meituan.png",
        avatar_dark: "forever/journal_icon/meituan.png",
        slogan: "一行代码，亿万生活",
    },
    10: {
        name: "张鑫旭-鑫空间-鑫生活",
        www: "https://www.zhangxinxu.com/wordpress/",
        avatar: "forever/journal_icon/zhangxinxu.ico",
        avatar_dark: "forever/journal_icon/zhangxinxu.ico",
        slogan: "",
    },
    11: {
        name: "鸟窝",
        www: "https://colobu.com/",
        avatar: "",
        avatar_dark: "",
        slogan: "大道至简 Simplicity is the ultimate form of sophistication",
    },
    12: {
        name: "峰华前端工程师",
        www: "https://zxuqian.cn/",
        avatar: "forever/journal_icon/fenghua.ico",
        avatar_dark: "forever/journal_icon/fenghua.ico",
        slogan: "",
    },
    13: {
        name: "运维咖啡吧",
        www: "https://blog.ops-coffee.cn/",
        avatar: "forever/journal_icon/ops-coffee.ico",
        avatar_dark: "forever/journal_icon/ops-coffee.ico",
        slogan: "追求技术的道路上，我从不曾停下脚步",
    },
    14: {
        name: "李文周的博客",
        www: "https://www.liwenzhou.com/",
        avatar: "forever/journal_icon/liwenzhou.ico",
        avatar_dark: "forever/journal_icon/liwenzhou.ico",
        slogan: "总结Go语言学习之路",
    },
    15: {
        name: "无忌",
        www: "https://jincheng9.github.io/",
        avatar: "",
        avatar_dark: "",
        slogan: "",
    },
    16: {
        name: "Tony Bai",
        www: "https://tonybai.com/",
        avatar: "",
        avatar_dark: "",
        slogan: "一个程序员的心路历程",
    },
    17: {
        name: "午夜咖啡",
        www: "https://jolestar.com/",
        avatar: "forever/journal_icon/jolestar.ico",
        avatar_dark: "forever/journal_icon/jolestar.ico",
        slogan: "",
    },
    // 18: {
    //     name: "蝈蝈俊的技术心得",
    //     www: "https://www.cnblogs.com/ghj1976/",
    //     avatar: "",
    //     avatar_dark: "",
    //     slogan: "",
    // },
    19: {
        name: "深度学习前沿笔记",
        www: "https://www.zhihu.com/column/c_188941548",
        avatar: "forever/journal_icon/zhangjunlin.jpeg",
        avatar_dark: "forever/journal_icon/zhangjunlin.jpeg",
        slogan: "深度学习领域前沿进展科普",
    },
    20: {
        name: "RandomGenerator",
        www: "https://www.zhihu.com/column/zijie0",
        avatar: "forever/journal_icon/zijie.jpeg",
        avatar_dark: "forever/journal_icon/zijie.jpeg",
        slogan: "天地大观，志存高远",
    },
    21: {
        name: "iCSS前端趣闻",
        www: "https://juejin.cn/user/2330620350437678/posts?sort=newest",
        avatar: "forever/journal_icon/icss.png",
        avatar_dark: "forever/journal_icon/icss.png",
        slogan: "CSS 奇技淫巧，在这里，都有",
    },
    22: {
        name: "字节跳动技术团队",
        www: "https://juejin.cn/user/1838039172387262/posts?sort=newest",
        avatar: "forever/journal_icon/bytedancetech.jpeg",
        avatar_dark: "forever/journal_icon/bytedancetech.jpeg",
        slogan: "字节跳动的技术实践分享",
    },
    23: {
        name: "SegmentFault Weekly",
        www: "https://segmentfault.com/weekly",
        avatar: "forever/journal_icon/segmentfault.png",
        avatar_dark: "forever/journal_icon/segmentfault.png",
        slogan: "",
    },
    24: {
        name: "云风的BLOG",
        www: "https://blog.codingnow.com/",
        avatar: "forever/journal_icon/codingnow.png",
        avatar_dark: "forever/journal_icon/codingnow.png",
        slogan: "思绪来得快去得也快，偶尔会在这里停留",
    },
    25: {
        name: "Phodal Huang",
        www: "https://www.phodal.com/",
        avatar: "forever/journal_icon/phodal.png",
        avatar_dark: "forever/journal_icon/phodal.png",
        slogan: "",
    },
    26: {
        name: "Python猫",
        www: "https://pythoncat.top/",
        avatar: "forever/journal_icon/pythoncat.jpeg",
        avatar_dark: "forever/journal_icon/pythoncat.jpeg",
        slogan: "",
    },
    27: {
        name: "Next.js Blog",
        www: "https://nextjs.org/blog",
        avatar: "forever/journal_icon/nextjs.png",
        avatar_dark: "forever/journal_icon/nextjs.png",
        slogan: "Next.js 官方博客",
    },
    28: {
        name: "Sukka's Blog",
        www: "https://blog.skk.moe/",
        avatar: "forever/journal_icon/skk.png",
        avatar_dark: "forever/journal_icon/skk.png",
        slogan: "童话只美在真实却从不续写",
    },
    29: {
        name: "OpenAI Blog",
        www: "https://openai.com/blog/",
        avatar: "forever/journal_icon/openai.png",
        avatar_dark: "forever/journal_icon/openai.png",
        slogan: "OpenAI 官方博客",
    },
    30: {
        name: "Vue.js Blog",
        www: "https://blog.vuejs.org/",
        avatar: "forever/journal_icon/vuejs.png",
        avatar_dark: "forever/journal_icon/vuejs.png",
        slogan: "Vue.js 官方博客",
    },
    31: {
        name: "Go Blog",
        www: "https://go.dev/blog/",
        avatar: "forever/journal_icon/go.png",
        avatar_dark: "forever/journal_icon/go.png",
        slogan: "Go 官方博客",
    },
    32: {
        name: "安富莱嵌入式周报",
        www: "https://www.armbbs.cn/forum.php?mod=forumdisplay&fid=12&filter=author&orderby=dateline&typeid=104",
        avatar: "forever/journal_icon/armbbs.png",
        avatar_dark: "forever/journal_icon/armbbs.png",
        slogan: "",
    },
    33: {
        name: "Java News",
        www: "https://dev.java/news/",
        avatar: "forever/journal_icon/java.png",
        avatar_dark: "forever/journal_icon/java.png",
        slogan: "Java 官方博客",
    },
    34: {
        name: ".NET Blog",
        www: "https://devblogs.microsoft.com/dotnet/",
        avatar: "forever/journal_icon/dotnet.png",
        avatar_dark: "forever/journal_icon/dotnet.png",
        slogan: ".NET 官方博客",
    },
    35: {
        name: "C++ Team Blog",
        www: "https://devblogs.microsoft.com/cppblog/",
        avatar: "forever/journal_icon/cpp.png",
        avatar_dark: "forever/journal_icon/cpp.png",
        slogan: "C++ Team 官方博客",
    },
    36: {
        name: ".NET中文官方博客",
        www: "https://devblogs.microsoft.com/dotnet-ch/",
        avatar: "forever/journal_icon/dotnet.png",
        avatar_dark: "forever/journal_icon/dotnet.png",
        slogan: "",
    },
    37: {
        name: "Rust Blog",
        www: "https://blog.rust-lang.org/",
        avatar: "forever/journal_icon/rust.png",
        avatar_dark: "forever/journal_icon/rust_dark.png",
        slogan: "Rust 官方博客",
    },
    38: {
        name: "Eli Bendersky's website",
        www: "https://eli.thegreenplace.net/",
        avatar: "forever/journal_icon/thegreenplace.png",
        avatar_dark: "forever/journal_icon/thegreenplace.png",
        slogan: "",
    },
    39: {
        name: "Apple Developer News",
        www: "https://developer.apple.com/cn/news/",
        avatar: "forever/journal_icon/apple.png",
        avatar_dark: "forever/journal_icon/apple.png",
        slogan: "",
    },
    40: {
        name: "PolarDB 数据库内核月报",
        www: "http://mysql.taobao.org/monthly/",
        avatar: "",
        avatar_dark: "",
        slogan: "",
    },
    41: {
        name: "得物技术",
        www: "https://juejin.cn/user/2392954206960247/posts?sort=newest",
        avatar: "forever/journal_icon/dewutech.png",
        avatar_dark: "forever/journal_icon/dewutech.png",
        slogan: "",
    },
    42: {
        name: "网易云音乐技术团队",
        www: "https://juejin.cn/user/4265760847567016/posts?sort=newest",
        avatar: "forever/journal_icon/neteasymusic.png",
        avatar_dark: "forever/journal_icon/neteasymusic.png",
        slogan: "",
    },
}

export const DEFAULT_BLOGGER = {
    name: "默认博主",
    www: "https://rebang.today/",
    avatar: "forever/tab_icon/rebang.ico",
    avatar_dark: "forever/tab_icon/rebang.ico",
    slogan: "当你看到这个，说明网站正在更新中...",
}