import React, {useState} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from "./ErrorPage";
import {reportError} from "../../apis/system";
import UpdatePage from "./UpdatePage";

function MyErrorBoundary({ children }) {

    const [isUpdate, setIsUpdate] = useState(false)

    const handleError = (error, info) => {
        if (error?.message.startsWith("Loading chunk")) {
            setIsUpdate(true)
        }
        // 收集用户信息
        const environment = {
            screenWidth: window.screen.width, // 屏幕分辨率
            screenHeight: window.screen.height, // 屏幕分辨率
            innerWidth: window.innerWidth, // 可视区域大小
            innerHeight: window.innerHeight, // 可视区域大小
            devicePixelRatio: window.devicePixelRatio, // 设备像素比
            url: window.location.href, // 当前URL
            language: window.navigator.language, // 语言设置
            cookieEnabled: window.navigator.cookieEnabled, // 是否启用了cookies
            onLine: window.navigator.onLine, // 浏览器在线/离线状态
            userAgent: window.navigator.userAgent,
        };

        // 上传错误日志
        reportError({
            msg: error?.message || "",
            stack: error?.stack || "",
            component_stack: info.componentStack || "",
            environment: JSON.stringify(environment)
        })
    };

    return (
        <ErrorBoundary FallbackComponent={isUpdate ? UpdatePage : ErrorPage} onError={handleError}>
            {children}
        </ErrorBoundary>
    );
}



export default MyErrorBoundary;
