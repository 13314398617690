import {getCDNUrl} from "../../../utils";

const fileContent = `# 今日热榜订阅教程

### 在首页菜单中右键点击，可快速订阅/取消订阅

![image-20230401230351985](${getCDNUrl("forever/follow-tutorial.png")})

`;

export default fileContent;