import React, {useState} from "react";
import {TAB_NAME_CHINESE_ENUM} from "../../constants";
import {Link, Tag, Tooltip} from "@arco-design/web-react";
import {IconVideoCamera} from "@arco-design/web-react/icon";
import classNames from "classnames";
import {reportClickItemTop} from "../../apis/item";

function ItemOne(props) {
    const matchedFilterWord = props.filterWordArr.find((item) => props.title.includes(item))
    const [showAnyway, setShowAnyway] = useState(false)

    if (matchedFilterWord && !showAnyway) {
        return (
            <li className={`text-text-200 dark:text-textDark-200 bg-primary-200 dark:bg-primaryDark-200 px-3 py-3 flex items-center justify-center border-b border-solid border-border-100 dark:border-borderDark-100`}>
                <div>{`因包含屏蔽词“${matchedFilterWord}”而被隐藏`}</div>
                <Link className="shrink-0" onClick={() => setShowAnyway(true)}>显示</Link>
            </li>
        )
    }

    return (
        <div className="select-none flex py-3 pr-3 text-sm font-normal text-text-100 dark:text-textDark-100 border-b border-border-100 dark:border-borderDark-100">
            <div className="shrink-0 space-y-1 w-8 text-center">
                            <span className={classNames("", {
                                "text-orange-500": props.index <= 3,
                                "text-text-300": props.index > 3,
                            })}>{`${props.index}`}</span>
                {props.is_video &&
                    <div
                        className="rounded text-xs leading-4 h-4 w-4 mx-auto bg-[#F3F4F4] dark:bg-[#262A31] text-[#86909C] dark:text-[#BEBFC2]">
                        <Tooltip content="这是个视频">
                            <IconVideoCamera/>
                        </Tooltip>
                    </div>
                }
            </div>

            <a onClick={() => {
                reportClickItemTop(props.tab_key, props.item_key)
            }}
               href={props.www_url}
               className="grow justify-self-start multirow-ellipsis-3 mr-1 hover:text-text-200 dark:hover:text-textDark-200 visited:text-text-visited dark:visited:text-textDark-visited"
            >
                <span>{props.title}</span>
            </a>
            <Tag className="shrink-0 rounded" color='gray' size="small">{`${TAB_NAME_CHINESE_ENUM[props.tab_key]}`}</Tag>
        </div>

    );
}

export default ItemOne;
