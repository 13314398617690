import {useSelector} from "react-redux";
import {getUserIsVip} from "../../redux/user";
import {getImageUrl} from "../../utils";
import React from "react";
import {reportCount} from "../../apis/system";

export default function ItabAds() {
    const isVip = useSelector(getUserIsVip)
    return (
        <>
            {!isVip && <div
                className="border border-border-100 dark:border-borderDark-100 bg-primary-200 dark:bg-primaryDark-200 rounded-md shadow-sm">
                <div className="p-[10px]">
                    <a onClick={() => {reportCount("ads_itab")}} href="https://itab.link/?from=rebang.today" target="_blank">
                        <img className="rounded dark:hidden" src={getImageUrl("forever/ads/itab.jpg")} alt=""/>
                        <img className="rounded hidden dark:block" src={getImageUrl("forever/ads/itab.jpg")}
                             alt=""/>
                    </a>
                </div>
                <div
                    className="py-1 text-text-200 dark:text-textDark-200 rounded-b-md text-xs text-center bg-primary-300 dark:bg-primaryDark-300">
                    <a onClick={() => {reportCount("ads_itab")}} className="hover:text-text-100 hover:dark:text-textDark-100" href="https://itab.link/?from=rebang.today" target="_blank">站长推荐</a>
                </div>
            </div>}
        </>
    )
}

