import {createSlice} from '@reduxjs/toolkit'
import {SETTING_DEFAULT} from "../constants/setting";
import {
    ACCESS_TOKEN_KEY,
    USER_ACCOUNT_KEY,
    USER_SETTING_KEY
} from "../constants/localStorage";
import {getSecondsTimestamp, timestampToDate2} from "../utils/time";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        accessToken: localStorage.getItem(ACCESS_TOKEN_KEY) || "",
        userSetting: JSON.parse(localStorage.getItem(USER_SETTING_KEY)) || {},
        userAccount: JSON.parse(localStorage.getItem(USER_ACCOUNT_KEY)) || {},
    },
    reducers: {
        setUserAccessToken: (state, action) => {
            state.accessToken = action.payload
            localStorage.setItem(ACCESS_TOKEN_KEY, action.payload)
        },
        initUserSetting: (state, action) => {
            state.userSetting = action.payload
            localStorage.setItem(USER_SETTING_KEY, JSON.stringify(action.payload))
        },
        editUserSetting: (state, action) => {
            state.userSetting[action.payload.key] = action.payload.value
            localStorage.setItem(USER_SETTING_KEY, JSON.stringify(state.userSetting))
        },
        initUserAccount: (state, action) => {
            state.userAccount = action.payload
            localStorage.setItem(USER_ACCOUNT_KEY, JSON.stringify(action.payload))
        },
        editUserAccount: (state, action) => {
            state.userAccount[action.payload.key] = action.payload.value
            localStorage.setItem(USER_ACCOUNT_KEY, JSON.stringify(state.userAccount))
        },
        removeUserAll: (state) => {
            state.accessToken = ""
            state.userSetting = {}
            state.userAccount = {}
            localStorage.removeItem(ACCESS_TOKEN_KEY)
            localStorage.removeItem(USER_SETTING_KEY)
            localStorage.removeItem(USER_ACCOUNT_KEY)
        }
    }
})

export const {
    setUserAccessToken,
    initUserSetting,
    editUserSetting,
    initUserAccount,
    editUserAccount,
    removeUserAll,
} = userSlice.actions

export const getUserSetting = (state) => {
    let ret = {...state.userReducer.userSetting}
    for (let key in SETTING_DEFAULT) {
        if (!ret.hasOwnProperty(key) && SETTING_DEFAULT.hasOwnProperty(key)) {
            ret[key] = SETTING_DEFAULT[key]
        }
    }
    return ret
}

export const getUserAccount = state => state.userReducer.userAccount

export const getUserAccessToken = (state) => state.userReducer.accessToken

export const getIsLoggedIn = state => getUserAccessToken(state) !== "" && getUserAccount(state).status === 1

export const getUserIsVip = state => getIsLoggedIn(state) && getUserAccount(state)?.is_vip === 1 && getUserAccount(state)?.vip_end_at > getSecondsTimestamp()

// getUserHasOnceVip 用户是否曾经是vip，是否至少开过一次vip
export const getUserHasOnceVip = state => getIsLoggedIn(state) && getUserAccount(state)?.is_vip === 1

export const getUserVipEndAtString = state => {
    if (getIsLoggedIn(state) && getUserHasOnceVip(state)) {
        return timestampToDate2(getUserAccount(state)?.vip_end_at)
    }
    return 0
}

// userIsAdmin 用户是否是管理员
export const userIsAdmin = state => getIsLoggedIn(state) && getUserAccount(state)?.role_id === 2

export default userSlice.reducer


