import {useEffect, useRef, useState} from "react";

export const useTitle = (title) => {
    useEffect(() => {
        if (!title) return;
        document.title = "今日热榜 - " + title;
    }, [title]);
};

export const useViewport = () => {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return width;
}

export const useViewportComplete = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleWindowResize = () => {
            setHeight(window.innerHeight)
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return {width, height};
}

export const useUpdateEffect = (effect, deps) => {
    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) {
            effect();
        } else {
            mounted.current = true;
        }
    }, deps);
}

export const useWindowScrollY = () => {
    const [scrollY, setScrollY] = useState(window.scrollY);
    useEffect(() => {
        const handleWindowScroll = () => setScrollY(window.scrollY);
        window.addEventListener("scroll", handleWindowScroll);
        return () => window.removeEventListener("scroll", handleWindowScroll);
    }, [])
    return scrollY;
}
