import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getUserIsVip, userIsAdmin} from "../../redux/user";
import Wwads from "./Wwads";
import Asense from "./Asense";
import CustomAds from "./CustomAds";

// type 1-竖版 2-横版
const Ads = ({type}) => {
    const AD_TYPE_WWADS = "1"
    const AD_TYPE_GOOGLE = "2"
    const AD_TYPE_CUSTOM = "3"

    const isVip = useSelector(getUserIsVip)
    const isAdmin = useSelector(userIsAdmin)
    const [adType, setAdType] = useState(AD_TYPE_WWADS) // 1-wwads 2-google adsense 3-自定义

    useEffect(() => {
        // if (adType === AD_TYPE_GOOGLE) {
        //     const interval = setInterval(function () {
        //         if (document.querySelector('.adsbygoogle').getAttribute('data-ad-status') === 'unfilled') {
        //             clearInterval(interval);
        //             setAdType(AD_TYPE_WWADS)
        //         } else if (document.querySelector('.adsbygoogle').getAttribute('data-ad-status') === 'filled') {
        //             clearInterval(interval);
        //             setAdType(AD_TYPE_GOOGLE)
        //         }
        //     }, 100);
        //     return () => {clearInterval(interval)};
        // }
    }, []);

    if (isAdmin) return (<Wwads type={type}/>)

    if (isVip) return (<></>)

    return (
        <>
            {adType === AD_TYPE_WWADS && <Wwads type={type}/>}
            {adType === AD_TYPE_GOOGLE && <Asense type={type}/>}
            {adType === AD_TYPE_CUSTOM && <CustomAds type={type}/>}
        </>
    )
};

export default Ads;
