import {Alert, Badge, Button, Empty, Link, Message, Popconfirm} from "@arco-design/web-react";
import {getCDNUrl} from "../../utils";
import React, {useEffect, useState} from "react";
import {IconDragDotVertical, IconPlusCircle} from "@arco-design/web-react/icon";
import {apiGetTabCategory} from "../../apis/tab";
import SortableGrid from "./SortableGrid";
import {apiEditUserSetting} from "../../apis/user";
import {LIST_SHOW_ENUM, SETTING_KEY} from "../../constants/setting";
import {initUserSetting} from "../../redux/user";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getFollowTabLimit} from "../../redux/system";
import {ALL_POSTS_IDS} from "../../pages/Article/posts";

function TabEditor({followTabInfo, close, listShowType}) {
    const dispatch = useDispatch()
    const [newFollowTabInfo, setNewFollowTabInfo] = useState(followTabInfo)
    const [hasEdit, setHasEdit] = useState(false)
    const [tabCategory, setTabCategory] = useState([])
    const navigate = useNavigate()
    const followTabLimit = useSelector(getFollowTabLimit)

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        // 以下代码用于兼容不同浏览器
        event.returnValue = '';
    };

    useEffect(() => {
        if (hasEdit) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [hasEdit]);

    useEffect(() => {
        apiGetTabCategory().then(res => {
            if (res.code === 200 && res.data && res.data.tab_category_group) {
                setTabCategory(res.data.tab_category_group)
            }
        })
    }, [])

    function onChangeFollowItem(items) {
        setNewFollowTabInfo(items)
        setHasEdit(true)
    }

    function onAddItem(tabInfo) {
        if (followTabLimit <= newFollowTabInfo.length) {
            Message.error(`当前会员等级最多订阅${followTabLimit}个节点`)
            return
        }
        setNewFollowTabInfo([...newFollowTabInfo, tabInfo])
        setHasEdit(true)
    }

    function save() {
        const msgClose = Message.loading("保存中...")
        const followTab = newFollowTabInfo.map(e => e.key)
        apiEditUserSetting({filed: SETTING_KEY.FOLLOW_TAB, value: JSON.stringify(followTab)}).then((res) => {
            msgClose()
            if (res.code === 200) {
                dispatch(initUserSetting(res.data))
                Message.success({content: "保存成功", duration: 500})
                // setHasEdit(false)
                close()
            } else {
                Message.error("保存失败，请稍后再试")
            }
        }).catch(() => {
            msgClose()
            Message.error("保存失败，请稍后再试")
        })
    }

    return (
        <>
            {/*覆盖手机端header开始*/}
            <div
                className="z-50 py-2.5 pl-1 pr-5 fixed flex items-center top-0 left-0 md:hidden w-full h-[52px] bg-primary-200 dark:bg-primaryDark-200">
                <div className="flex-1 flex">
                    <Popconfirm position='bl' disabled={!hasEdit} onOk={close} title="有更改内容未保存，确认离开？">
                        <Button type='text' shape='round' onClick={!hasEdit ? close : null}>取消</Button>
                    </Popconfirm>
                </div>
                <div
                    className="flex items-center font-bold justify-center w-1/2 text-lg select-none text-text-100 dark:text-textDark-100">
                    <span className="truncate">管理订阅</span>
                </div>
                <div className="flex-1 flex justify-end space-x-2">
                    <Button type='primary' shape='round' onClick={save}>保存</Button>
                </div>
            </div>
            <div
                className="z-40 absolute -top-[52px] left-0 md:hidden w-full h-[52px] bg-primary-200 dark:bg-primaryDark-200"/>
            {/*覆盖手机端header结束*/}
            <div
                className="bg-primary-200 dark:bg-primaryDark-200 border-t border-b md:border border-border-100 dark:border-borderDark-100 overflow-hidden mb-5 md:rounded-md shadow-sm">
                <div className="hidden md:flex py-3 px-5 justify-between border-b border-border-100 dark:border-borderDark-100">
                    <div className="flex text-lg select-none font-bold items-center text-text-100 dark:text-textDark-100">
                        <span>管理订阅</span>
                    </div>
                    <div className="flex space-x-2">
                        <Popconfirm position='bottom' disabled={!hasEdit} onOk={close} title="有更改内容未保存，确认离开？">
                            <Button type='text' shape="round" onClick={!hasEdit ? close : null}>取消</Button>
                        </Popconfirm>
                        <Button type='primary' shape='round' onClick={save}>保存</Button>
                    </div>
                </div>
                <Alert content={<span>单击按钮操作 <span>订阅</span>/<span>取消订阅</span>，按住<IconDragDotVertical/>拖动排序，<Link onClick={() => navigate(`/article/${ALL_POSTS_IDS.FOLLOW_TUTORIAL}`)}>查看更多用法</Link></span>} />
                <div className="py-5 px-4 space-y-5">
                    {/*我的订阅开始*/}
                    <div className="space-y-3 pb-8 border-b-2 border-dashed border-border-100 dark:border-borderDark-100">
                        <span className="text-2xl font-bold text-text-100 dark:text-textDark-100">我的订阅</span>
                        {newFollowTabInfo.length === 0 && <Empty/>}
                        {newFollowTabInfo.length > 0 &&
                            <SortableGrid items={newFollowTabInfo} onChangeFollowItem={onChangeFollowItem} listShowType={listShowType}/>}
                    </div>
                    {/*我的订阅结束*/}
                    {/*骨架图开始*/}
                    {tabCategory.length === 0 && <div className="space-y-3 animate-pulse">
                        <div className="bg-loading-100 dark:bg-loadingDark-100 w-24 h-8 rounded-lg"></div>
                        <div className="grid gap-3 grid-cols-3 md:grid-cols-4 xl:grid-cols-5">
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                            <div className="h-8 rounded-lg bg-loading-100 dark:bg-loadingDark-100"/>
                        </div>
                    </div>}
                    {/*骨架图结束*/}
                    <div className="mt-3 text-2xl font-bold text-text-100 dark:text-textDark-100">可添加的节点</div>
                    {tabCategory.map((ele, i) => {
                        const realShowTab = ele.tab_info.filter(e => newFollowTabInfo.findIndex(ee => ee.key === e.key) === -1)
                        return (
                            <div className="space-y-3" key={i}>
                                <span className="text-xl font-bold text-text-100 dark:text-textDark-100">{ele.category_name}</span>
                                {realShowTab.length === 0 && <Empty/>}
                                {realShowTab.length > 0 && <div className="grid gap-3 grid-cols-3 md:grid-cols-4 xl:grid-cols-5">
                                    {realShowTab.map((item, index) => {
                                        return <Badge
                                            key={ele.category_name + index + item.key}
                                            className="text-base leading-3"
                                            count={newFollowTabInfo.findIndex(e => e.key === item.key) === -1
                                                ? <IconPlusCircle className="text-green-500 dark:text-green-600"/>
                                                : null} // <IconMinusCircle className="text-gray-500 dark:text-gray-400"/>
                                            onClick={() => {
                                                if (newFollowTabInfo.findIndex(e => e.key === item.key) === -1) {
                                                    onAddItem(item)
                                                }
                                            }}
                                        >
                                            <div
                                                className="bg-primary-300 dark:bg-[#343435] text-text-200 dark:text-textDark-200 truncate flex-shrink-0 font-semibold flex items-center px-2 h-8 rounded-lg select-none cursor-pointer">
                                            <span className="flex items-center">
                                                {listShowType === LIST_SHOW_ENUM.COMPLETE && item.avatar !== "" &&
                                                    <img
                                                        className="mr-1 w-4 h-4 rounded-sm object-contain select-none pointer-events-none dark:hidden"
                                                        src={getCDNUrl(item.avatar)} alt=""
                                                        style={{WebkitTouchCallout: 'none'}}
                                                    />}
                                                {listShowType === LIST_SHOW_ENUM.COMPLETE && item.avatar_dark !== "" &&
                                                    <img
                                                        className="mr-1 w-4 h-4 rounded-sm object-contain select-none pointer-events-none hidden dark:block"
                                                        src={getCDNUrl(item.avatar_dark)} alt=""
                                                        style={{WebkitTouchCallout: 'none'}}
                                                    />}
                                                <div className="flex-shrink-0 text-sm">
                                                    {item.name}
                                                </div>
                                            </span>
                                            </div>
                                        </Badge>
                                    })}
                                </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>)
}

export default TabEditor;
