// import {ACCESS_TOKEN_KEY, LOGIN_TYPE_KEY} from "../constants/localStorage";
import {Message} from "@arco-design/web-react";
import {getBearerToken} from "./token";
import {store} from '../redux';
import {removeUserAll} from "../redux/user";
import {setMaintenance} from "../redux/system";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export function fetchGet(url, params = {}, timeout = 5000) {
    const queryParams = new URLSearchParams(params).toString();
    url = queryParams.length > 0 ? `${baseURL}${url}?${queryParams}` : `${baseURL}${url}`;
    return Promise.race([
        fetchExec(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": getBearerToken()
            },
        }),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timeout')), timeout)
        )
    ])
}

export function fetchPost(url, params = {}, timeout = 5000) {
    // options.mode = 'cors'
    // options.credentials = 'omit'
    return Promise.race([
        fetchExec(`${baseURL}${url}`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": getBearerToken()
            }
        }),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timeout')), timeout)
        )
    ]);
}

async function fetchExec(url, options) {
    try {
        const response = await fetch(url, options);
        const data = await response.json();
        switch (response.status) {
            case 429:
                Message.error({id: "global_429", content: '操作频繁 休息一下吧', duration: 3000})
                break;
            case 500:
                Message.error({id: "global_500", content: '网络错误', duration: 3000})
                break;
            case 401:
                if (data.code === 1004) {
                    store.dispatch(removeUserAll())
                    Message.error({id: "global_401_1004", content: '登录过期 请重新登录', duration: 3000})
                }
                break;
            case 403:
                if (data.code === 1005) {
                    Message.error({id: "global_403_1005", content: '您的账号已被封禁 如被误封请联系管理员', duration: 3000})
                }
                if (data.code === 1020) {
                    Message.error({id: "global_403_1020", content: '您的IP已被封禁 如被误封请联系管理员', duration: 3000})
                }
                break;
            case 503:
                if (data.code === 1023) {
                    store.dispatch(setMaintenance(data.reason))
                }
                break
            default:
                break;
        }
        return data;
    } catch (error) {
        // 服务器没有响应才会跳转到这里
        // if (!window.navigator.onLine) {
        // 断网处理
        // return;
        // }
        // 什么都没有，返回一个错误
        return Promise.reject(error);
    }
}

// 发起Beacon请求，用于上报数据，无法获取返回结果，异步无阻塞
export function sendBeacon(url, data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof value !== 'string') {
            // formData只能append string 或 Blob
            value = JSON.stringify(value);
        }
        formData.append(key, value);
    });
    navigator.sendBeacon(`${baseURL}${url}`, formData)
}

