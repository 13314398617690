import {widthGTAMd} from "../utils";
import {useViewport} from "./index";
import {getRealSetting, SETTING_KEY} from "../constants/setting";


export const useSettingUrlTarget = (userSetting) => {
    return widthGTAMd(useViewport())
        ? getRealSetting(SETTING_KEY.URL_TARGET_PC, userSetting[SETTING_KEY.URL_TARGET_PC])
        : getRealSetting(SETTING_KEY.URL_TARGET_PHONE, userSetting[SETTING_KEY.URL_TARGET_PHONE])
}

export const useSettingListShow = (userSetting) => {
    return widthGTAMd(useViewport())
        ? getRealSetting(SETTING_KEY.LIST_SHOW_PC, userSetting[SETTING_KEY.LIST_SHOW_PC])
        : getRealSetting(SETTING_KEY.LIST_SHOW_PHONE, userSetting[SETTING_KEY.LIST_SHOW_PHONE])
}

export const useSettingTabShow = (userSetting) => {
    return widthGTAMd(useViewport())
        ? getRealSetting(SETTING_KEY.TAB_SHOW_PC, userSetting[SETTING_KEY.TAB_SHOW_PC])
        : getRealSetting(SETTING_KEY.TAB_SHOW_PHONE, userSetting[SETTING_KEY.TAB_SHOW_PHONE])
}