import React from "react";
import {useNavigate} from "react-router-dom";
import {getCDNUrl} from "../../utils";
import {reportCount} from "../../apis/system";
import {ALL_POSTS_IDS} from "../../pages/Article/posts";

function CustomAds({type}) {
    // type 1-竖版 2-横版
    const navigate = useNavigate()

    return (
        <> {type === 1
            ? <div className="border border-border-100 dark:border-borderDark-100 bg-primary-200 dark:bg-primaryDark-200 rounded-md shadow-sm">
                <div className="p-[10px] pb-0 mt-3">
                    <a href="http://down3.ai1foo.com/" onClick={() => {reportCount("ads_aiads_pc")}} target="_blank">
                        <div className="flex items-center flex-col">
                            <img style={{width: "130px", height: "100px"}} src={getCDNUrl("forever/ads/aiads2.png")} alt="chat.aiduihua.com"/>
                            <span className="px-2 mt-4 text-text-200 dark:text-textDark-200 text-center text-sm font-bold">GPT4.0+上千款AI模型免费用，并且支持在线写文案、公文写作、AI绘画</span>
                        </div>
                    </a>
                </div>
                <div
                    className="mt-2 py-0.5 text-text-200 dark:text-textDark-200 rounded-b-md bg-primary-300 dark:bg-primaryDark-300 text-xs flex justify-center align-center">
                    <a style={{fontSize: "11px"}} className="cursor-pointer hover:text-text-100 hover:dark:text-textDark-100" onClick={() => {
                        navigate(`/article/${ALL_POSTS_IDS.ADVERTISE}`)
                    }} target="_blank">赞助商广告</a>
                </div>
            </div>
            : <div className="asideSize:hidden shadow-sm md:rounded-md bg-primary-200 dark:bg-primaryDark-200 text-text-200 dark:text-textDark-200 border-y md:border border-border-100 dark:border-borderDark-100">
                <div className="p-[10px]">
                    <a href="http://down3.ai1foo.com/" onClick={() => {reportCount("ads_aiads_mobile")}} target="_blank">
                        <div className="flex items-center">
                            <img style={{width: "130px", height: "100px"}} src={getCDNUrl("forever/ads/aiads2.png")} alt="chat.aiduihua.com" className="w-full"/>
                            <span className="px-2 ml-3 text-text-200 dark:text-textDark-200 text-sm font-bold">GPT4.0+上千款AI模型免费用，并且支持在线写文案、公文写作、AI绘画</span>
                        </div>
                    </a>
                </div>
                <div className="py-0.5 text-text-200 dark:text-textDark-200 rounded-b-md text-xs text-center bg-primary-300 dark:bg-primaryDark-300">
                    <a style={{fontSize: "11px"}}  className="cursor-pointer hover:text-text-100 hover:dark:text-textDark-100" onClick={() => {
                        navigate(`/article/${ALL_POSTS_IDS.ADVERTISE}`)
                    }} target="_blank">赞助商广告</a>
                </div>
            </div>}
        </>
    )
}

export default CustomAds;
