import {getCDNUrl, getWebUrl} from "../../../utils";

const fileContent = `各位热榜用户：

大家好，首先感谢您使用今日热榜，即便没有捐赠，我们也会优先确保您的使用体验。

## 为什么捐赠？

我们的网站需要您的支持！

您的捐款可以帮助我们**维持和改进网站的运营**，让我们能够为您提供**更好的服务和更多的内容**。

## 捐赠回馈

为了表达我们的感激之情，我们会为捐赠者回馈\`热榜会员\`（[点击查看详细介绍](${getWebUrl("article/vip")})）：
1. 去除任何形式的广告
2. 订阅节点数量从20提升至50
3. 订阅热榜（从您的订阅节点中排列组合的热榜）
4. 订阅飙升榜（您的订阅节点中最近1小时飙升榜，默认在电脑端订阅页面右侧展示）
5. 自定义屏蔽词数量提升至20
6. 全站热榜支持节点过滤

## 会员定价

- 6 RMB/月
- ~~36RMB/半年~~ 30 RMB/半年 
- ~~72RMB/年~~ 50 RMB/年

> 金额仅供参考，捐款任意金额，我们都会折算成天数，给您只多不少的会员时长

## 捐款二维码

![](${getCDNUrl("forever/donate/alipay_wechatpay.png")})

再次感谢！`;

export default fileContent;