import {fetchGet} from "../utils/fetch";

export function apiGetTabs() {
    return fetchGet("/v1/tabs")
}

export function apiGetMenuTabs(params) {
    return fetchGet("/v1/menu_tabs", params)
}

export function apiGetTabCategory(params) {
    return fetchGet("/v1/tab/category", params)
}
