import {createSlice} from '@reduxjs/toolkit'
import {TAB_ACTIVE_KEY, MENU_TAB_KEY, MENU_ACTIVE_KEY} from "../constants/localStorage";

export const tabSlice = createSlice({
    name: 'tab',
    initialState: {
        allMenuTabs: JSON.parse(localStorage.getItem(MENU_TAB_KEY)) || [],
        activeMenuKey: localStorage.getItem(MENU_ACTIVE_KEY) || "",
        activeTabKey: localStorage.getItem(TAB_ACTIVE_KEY) || "",
    },
    reducers: {
        initMenuTab: (state, action) => {
            state.allMenuTabs = action.payload
            localStorage.setItem(MENU_TAB_KEY, JSON.stringify(action.payload));
        },
        setActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload
            localStorage.setItem(TAB_ACTIVE_KEY, action.payload);
        },
        setActiveMenuKey: (state, action) => {
            if (action.payload !== state.activeMenuKey) {
                state.activeTabKey = ""
            }
            state.activeMenuKey = action.payload
            localStorage.setItem(MENU_ACTIVE_KEY, action.payload);
        }
    }
})

export const {initMenuTab, setActiveTabKey, setActiveMenuKey} = tabSlice.actions

export const getAllMenuTab = state => state.tabReducer.allMenuTabs

export const getActiveTabKey = state =>  state.tabReducer.activeTabKey

export const getActiveMenuKey = state =>  state.tabReducer.activeMenuKey

export const getNowAllTabs = (state) => {
    return state.tabReducer.allMenuTabs
        ?.find((item) => item.menu_key === state.tabReducer.activeMenuKey)?.tab_info || []
}


export default tabSlice.reducer


