import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useTitle} from "../../hooks";
import {Breadcrumb, Message} from "@arco-design/web-react";
import React from "react";
import {useNavigate} from "react-router-dom";
import Aside from "./Aside";
import {useDispatch, useSelector} from "react-redux";
import VipActiveRender from "./VipActiveRender";
import {getIsLoggedIn, initUserAccount, initUserSetting} from "../../redux/user";
import {apiActiveVip, apiUserAccountSetting} from "../../apis/user";

function VipActive() {
    const isLoggedIn = useSelector(getIsLoggedIn)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useTitle("激活会员")

    function onActiveVip(code) {
        if (!isLoggedIn) {
            Message.error('请登录后再激活会员')
            return
        }
        apiActiveVip({code}).then(res => {
            if (res.code === 200) {
                Message.success('激活成功，会员增加' + res.data.days + '天')
                apiUserAccountSetting({}).then((res) => {
                    if (res.code === 200) {
                        if (res.data.user_account) {
                            dispatch(initUserAccount(res.data.user_account))
                        }
                        if (res.data.user_setting) {
                            dispatch(initUserSetting(res.data.user_setting))
                        }
                    }
                })
            } else if (res.code === 1019) { // 激活码
                Message.error("此激活码被用完了");
            } else {
                Message.error("激活失败，请联系管理员");
            }
        }).catch((e) => {
            Message.error("激活失败，请联系管理员");
        })
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="grow mx-auto">
                <div className="py-2 px-2 md:pt-0 md:px-0">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a className="cursor-pointer" onClick={() => navigate("/")}>首页</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>激活会员</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="flex">
                    <VipActiveRender onActiveVip={onActiveVip} isLoggedIn={isLoggedIn}/>
                    <Aside/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default VipActive;
