import {getWebUrl} from "../../../utils";

const fileContent = `# 在今日热榜投放广告

## 什么人在用今日热榜

- 创意工作者（例如：开发者、设计师、产品、运营、商务、创作者）
- 高知人士（根据百度统计数据，本科以上用户占据70%）

## 广告形式
### 全站广告
- 广告默认显示在侧边栏
- 当页面太小不显示侧边栏时，会显示在页面下方
- 此广告是独家的，在你投放时，不会有其他广告来争夺注意力
- 广告内容（以下任选其一）
  - 120x90图片一张、140字以内的文字说明、32字以内的标题、链接
  - 500x500的图片、链接
### 某个菜单广告（暂时不提供）
- 在某个菜单下，广告显示在侧边栏
- 广告内容（以下任选其一）
  - 120x90图片一张、140字以内的文字说明、32字以内的标题、链接
  - 500x500的图片、链接
## 广告投放流程
1. 在[公众号](${getWebUrl("article/wechat-mp")})中找到站长的联系方式
2. 微信沟通投放开始时间及付款方式
3. 选择一种你觉得方便的付款方式进行付款
4. 将广告物料发送给我们
5. 开始投放！

------

> 今日热榜所获得的广告收入，用于支付我们在开发和维护过程中所付出的时间，及网站平台所使用的云服务的费用。
> 你的支持将帮助网站持续发展。

> 我们同时也接入了 [万维广告](https://wwads.cn/?aff_id=311) 的优雅的系统，欢迎尝试通过 [万维广告](https://wwads.cn/?aff_id=311) 投放。
`;

export default fileContent;