import {getCDNUrl, getWebUrl} from "../../../utils";

const fileContent = `# 安装到桌面（手机端）

> 写在前面：此功能因为系统支持良莠不齐，如果您安装后出现白屏黑屏或其他任何意外情况，请继续使用浏览器的方式打开。请记住官方网址：[https://rebang.today](https://rebang.today)（巧记：热榜.今日）

> 本篇文章为**手机端**的安装教程，**电脑端**请[点击跳转](${getWebUrl("article/download-pc")})查看

## 下面是具体步骤

1. 使用**系统自带的浏览器**打开官方网址：[https://rebang.today](https://rebang.today)
    1. iOS：Safari
    2. Android：出厂自带浏览器
2. 点击分享按钮
3. 在弹出的菜单中选择**添加到主屏幕**（或添加到桌面等类似词汇）
4. 点击确认添加后即可在桌面上找到图标了

> 请注意：系统不同，操作方式大同小异。如果您遇到了问题，可以在[反馈页面](https://support.qq.com/products/434778)中提交反馈，我们会尽快解决

## 下面是一些示例截图

### iOS
![ios_step1](${getCDNUrl("forever/article/download/ios_step1.jpg")})

![ios_step2](${getCDNUrl("forever/article/download/ios_step2.jpg")})

![ios_step3](${getCDNUrl("forever/article/download/ios_step3.jpg")})

![ios_step4](${getCDNUrl("forever/article/download/ios_step4.jpg")})

### 安卓（三星）

![samsung_step1](${getCDNUrl("forever/article/download/samsung_step1.jpg")})

![samsung_step2](${getCDNUrl("forever/article/download/samsung_step2.jpg")})

![samsung_step3](${getCDNUrl("forever/article/download/samsung_step3.jpg")})

![samsung_step4](${getCDNUrl("forever/article/download/samsung_step4.jpg")})

![samsung_step5](${getCDNUrl("forever/article/download/samsung_step5.jpg")})

> 因手头无其他安卓设备。后边如果有人提供截图，我会补充上来


`;

export default fileContent;