import React from "react";
import {Button, Result, Tooltip} from "@arco-design/web-react";
import {IconInfoCircle, IconSync} from "@arco-design/web-react/icon";
import Loading from "./Loading";

function ItemAsideRender(props) {
    const renderContent = () => {
        if (props.error) {
            return (
                <div className="flex flex-col items-center p-3 text-sm text-center font-normal text-text-100 dark:text-textDark-100">
                    <Result
                        style={{width: "fit-content", paddingTop: 10, paddingBottom: 0}}
                        status='500'
                    />
                    <div className="text-text-200 dark:text-textDark-200 text-sm">信号飘到了外太空 请刷新重试</div>
                    <Button style={{width: 60}} className="mt-3" type='primary' onClick={props.onChangePage}>刷新</Button>
                </div>
            )
        }
        if (props.loading) {
            return <Loading/>
        }
        if (props.children?.length === 0) {
            return (
                <div className="p-3 text-sm text-center font-normal text-text-100 dark:text-textDark-100">
                    暂无数据
                </div>
            )
        }
        return props.children
    };

    return (
        <div
            className="border border-border-100 dark:border-borderDark-100 bg-primary-200 dark:bg-primaryDark-200 rounded-md shadow-sm">
            <div className="w-full rounded-md bg-primary-200 dark:bg-primaryDark-200 flex flex-col">
                <div
                    className="flex items-center justify-between p-3 text-sm font-semibold text-text-100 dark:text-textDark-100 border-b border-border-100 dark:border-borderDark-100">
                    <div className="select-none space-x-1 flex items-center">
                        {props.type === 1 && <div>全站飙升榜</div>}
                        {props.type === 2 && <div className="flex items-center space-x-1">
                            <svg xmlns="http://www.w3.org/2000/svg" style={{fill: "gold"}} viewBox="0 -960 960 960" height="17" width="17">
                                <path d="m772-635-43-100-104-46 104-45 43-95 43 95 104 45-104 46-43 100Zm0 595-43-96-104-45 104-45 43-101 43 101 104 45-104 45-43 96ZM333-194l-92-197-201-90 201-90 92-196 93 196 200 90-200 90-93 197Z"/>
                            </svg>
                            <span>订阅飙升榜</span>
                        </div>}
                        <Tooltip content={props.type === 1 ? "全站最近一小时热度飙升榜" : "您订阅的节点最近一小时热度飙升榜"}><IconInfoCircle className="text-text-300 dark:text-textDark-300"/></Tooltip>
                    </div>
                    <div className="font-normal text-xs cursor-pointer text-text-200 dark:text-textDark-200 select-none" onClick={props.onChangePage}><IconSync/> 换一换</div>
                </div>
                {renderContent()}
            </div>
        </div>

    );
}

export default ItemAsideRender;
